var module = angular.module('meternet.dashboard.controllers.campingGaugeWidget', []);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/camping-gauge-widget.html',
        title : messages['dashboard.widgets.campingGauge.title'],
        description : messages['dashboard.widgets.campingGauge.desc'],
        controller : 'gaugeWidgetCtrl',
        screenshot: true,
        config : {
            type : "camping",
            series : [ {
                paramId : null,
                average : 1,
                derivative : 0,
                unit : "",
                majorTicks : 5,
                minorTicks : 3,
                max : 100,
                min : 0,
                precision : 2,
                scale : 0,
                zones : [ {
                    "color" : "#5cb85c",
                    from : 0,
                    to : 50
                }, {
                    "color" : "#f0ad4e",
                    from : 50,
                    to : 75
                }, {
                    "color" : "#d9534f",
                    from : 75,
                    to : 100
                } ]
            } ]
        },
        edit : {
            controller : 'gaugeWidgetEditCtrl',
            templateUrl: 'dashboard/camping-gauge-edit.html',
            resolve : {
                maxSeries : [ 'licenseService', function(licenseService) {
                    var license = licenseService.getLicense();
                    return license.properties.MAX_CAMPING_SERIES || 5;
                } ]
            }
        }
    };
    dashboardProvider.widget('camping', widget);
});