/**
 * Created by Karol Błaszczyk on 2015-02-02.
 */

var module = angular.module('meternet.utils.numFormat', []);
   
function NumFormat($timeout) {

        var Calc = {};

        Calc.format = function (value, frac, zeros) {
            if (typeof(value) === "string") {
                value = value.replace(',', '.');
            }
            if (frac){
                value = parseFloat(value);
                if (isFinite(value)) {
                    value = value.toFixed(frac || 0).replace(',', '.');
                    if (zeros != null) {
                        for (; zeros < frac; ++zeros) {
                            if (value[value.length - 1] === "0") {
                                value = value.substr(0, value.length - 1);
                            } else {
                                break;
                            }
                        }
                        if (value.indexOf(".") === value.length - 1) {
                            value = value.substr(0, value.length - 1);
                        }
                        return value;
                    } else {
                        return value;
                    }
                } else {
                    return "";
                }
            }
            return value;
        };

        Calc.parse = function (value) {
            if (value == null) {
                return null;
            } else {
                if (typeof(value) === "string") {
                    value = value.replace(',', '.');
                }
                value = parseFloat(value);
                if (isNaN(value)) {
                    return null;
                } else {
                    return value;
                }
            }
        };

        return {
            restrict: 'A',
            scope: false,
            require: 'ngModel',
            link: function (scope, element, attr, ctrl) {
                var places = parseInt(attr.numFormat);
                var parse;
                var format;

                    parse = function (value) {
                        return Calc.parse(value);
                    };

                    format = function (value) {
                        return Calc.format(value, places);
                    };

                ctrl.$parsers = [];
                ctrl.$formatters = [];
                ctrl.$$hasNativeValidators = false;
                ctrl.$parsers.push(parse);
                ctrl.$formatters.push(format);

                element.blur(function () {
                    $timeout(function () {

                        ctrl.$setViewValue(format(ctrl.$modelValue));
                        if (attr.min){
                            ctrl.$setValidity('minValue', ctrl.$modelValue >= attr.min);
                        }
                        if (attr.max){
                            ctrl.$setValidity('maxValue', ctrl.$modelValue <= attr.max);
                        }

                        ctrl.$render();
                    }, 0);
                });
            }
        };
    }
module.directive("numFormat",["$timeout", NumFormat]);

