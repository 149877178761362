<div adf-id="{{definition.wid}}" x-ng-show="!definition.config.hide" style="padding: 2px 0;">
    <div class="widget panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">{{definition.title}}
                <span class="pull-right">
                    <a href="" title="{{::'widget.edit.reload' | i18n}}" x-ng-if="widget.reload" x-ng-click="reload()">
                        <i class="fa fa-refresh"></i>
                    </a>
                    <a href="" title="{{::'widget.edit.move' | i18n}}" class="adf-move" x-ng-if="editMode &amp;&amp; !definition.locked">
                        <i class="fa fa-arrows"></i>
                    </a>
                    <a href="" title="{{::'widget.edit.collapse' | i18n}}" x-ng-show="collapsible &amp;&amp; !isCollapsed" x-ng-click="isCollapsed = !isCollapsed">
                        <i class="fa fa-minus"></i>
                    </a>
                    <a href="" title="{{::'widget.edit.expand' | i18n}}" x-ng-show="collapsible &amp;&amp; isCollapsed" x-ng-click="isCollapsed = !isCollapsed">
                        <i class="fa fa-plus"></i>
                    </a>
                    <a href="" title="{{::'widget.edit.edit' | i18n}}" x-ng-click="edit()" x-ng-if="editMode">
                        <i class="fa fa-cog"></i>
                    </a>
                    <a href="" title="{{::'widget.edit.remove' | i18n}}" x-ng-click="remove()" x-ng-if="editMode &amp;&amp; !definition.locked">
                        <i class="fa fa-trash" style="margin-left: 15px;"></i>
                    </a>
                    <a href="" title="{{::'widget.screenshot' | i18n}}" x-ng-if="widget.screenshot && !editMode" x-take-snapshot="" x-ng-click="savePicture()">
                        <i class="fa fa-camera"></i>
                    </a>
                    <div class="btn-group">
                        <button type="button" class="btn btn-link dropdown-toggle" style="padding: 0px;" x-ng-if="(widget.csv || widget.xlsx) && !editMode" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-download" style="font-size: 13px;"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right">
                            <li>
                                <a href="" title="{{::'widget.csv' | i18n}}" x-ng-if="widget.csv && !editMode" x-generate-csv="" x-ng-click="generateCsv()">
                                    <i class="fa fa-download"></i> {{::'report.chart.csv' | i18n}}
                                </a>
                            </li>
                            <li>
                                <a href="" title="{{::'widget.csv' | i18n}}" x-ng-if="widget.xlsx && !editMode" x-generate-csv="" x-ng-click="generateXlsx()">
                                    <i class="fa fa-download"></i> {{::'report.chart.xlsx' | i18n}}
                                </a>
                            </li>
                        </ul>
                    </div>

                </span>
            </h3>
        </div>
        <div x-ng-if="!definition.withoutCountingHeight" x-adf-widget-content="" x-model="definition" x-content="widget" class="widget-content" x-expand-to-parent-bottom=""></div>
        <div x-ng-if="definition.withoutCountingHeight" x-adf-widget-content="" x-model="definition" x-content="widget" class="widget-content"></div>
    </div>
</div>