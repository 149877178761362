<form class="form-horizontal">
    <div x-ng-repeat="notifier in notifiers">
        <div class="row">
            <span class="caption-label">{{'config.notifier.type.' + notifier.config.type | i18n}}</span>
            <!--{{notifier.config.type}}-->
            <div x-config-form-notifier="notifier" x-config="notifier.config"></div>
        </div>
        <hr x-ng-if="$index < notifiers.length-1">
    </div>

    <div x-ng-if="ui.notifiers.length != notifiers.length">
        <label class="control-label">{{'config.choose' | i18n}}</label>
        <div class="row">
            <div class="col-sm-3" x-ng-repeat="notifier in ui.notifiers">
                <div class="thumbnail" x-ng-if="isMissing(notifier)" x-ng-click="ui.add(notifier)">
                    <img alt="" src="" x-ng-src="{{notifier.iconUrl}}"/>
                    <div class="caption">
                        <span class="caption-label">{{'config.notifier.type.' + notifier.type | i18n}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>