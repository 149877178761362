var module = angular.module('meternet.charts.camping.services', []);

module.service('campingService', function($q, $http, contextPath){

    return {
        get : function() {
            var deferred = $q.defer();
            if (fetch) {
                var url = contextPath + '/camping/';
                $http({
                    method : 'GET',
                    url : url
                }).success(function(data, status, headers) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
            } else {
                deferred.resolve(config);
            }
            return deferred.promise;
        },
        saveStart : function(data) {
            var deferred = $q.defer();
            if (fetch) {
                var url = contextPath + '/campingStart/';
                $http({
                    method : 'POST',
                    url : url,
                    params : {'data' : data}
                })
                .success(function(data, status, headers) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
            } else {
                deferred.resolve(config);
            }
            return deferred.promise;
        },
        saveStop : function(data) {
            var deferred = $q.defer();
            if (fetch) {
                var url = contextPath + '/campingStop/';
                $http({
                    method : 'POST',
                    url : url,
                    params : {'data' : data}
                })
                .success(function(data, status, headers) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
            } else {
                deferred.resolve(config);
            }
            return deferred.promise;
        }

    };

});

