<div x-expand-to-parent-bottom="" style="overflow-y: auto;">
    <table class="table table-bordered table-hover">
        <thead>
            <tr>
                <th>{{'camping.table.name'|i18n}}</th>
                <th>{{'camping.table.timestampStart'|i18n}}</th>
                <th>{{'camping.table.valueStart'|i18n}}</th>
                <th>{{'camping.table.timestamp'|i18n}}</th>
                <th>{{'camping.table.value'|i18n}}</th>
                <th>{{'camping.table.price'|i18n}}</th>
                <th>{{'camping.table.valueDiff'|i18n}}</th>
                <th>{{'camping.table.cost'|i18n}}</th>
                <th>{{'camping.table.button'|i18n}}</th>
            </tr>
        </thead>
        <tbody>
            <tr x-ng-repeat="serie in options.series">
                <td>{{serie.name}}</td>
                <td>{{serie.timestampStart| date : "yyyy-MM-dd HH:mm:ss"}}</td>
                <td>{{serie.valueStart_str}}</td>
                <td>{{serie.timestamp| date : "yyyy-MM-dd HH:mm:ss"}}</td>
                <td>{{serie.value_str}}</td>
                <td>{{serie.price}}</td>
                <td>{{serie.valueDiff_str}}</td>
                <td>{{serie.cost}}</td>
                <td>
                    <button class="btn btn-success" x-ng-if="!serie.valueStart_str" x-ng-click="start(serie)">start</button>
                    <button class="btn btn-danger" x-ng-if="serie.valueStart_str" x-ng-click="stop(serie)">stop</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>