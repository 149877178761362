/* global saveSvgAsPng */
var module = angular.module('meternet.dashboard.directives', [
    'adf',
    'meternet.services',
    'meternet.dashboard.controllers'
]);

module.value('adfTemplatePath', 'dashboard/adf/');

module.directive('dashboardExtension', function (dashboardService) {
    return {
        scope: false,
        priority: 1,
        restrict: 'A',
        controller: function($scope, $element, $location) {
            $scope.ui = {
                fullscreen: false
            };

            $scope.removeDashboard = function () {
                dashboardService.remove($scope.model.id).then(function () {
                    $scope.$emit('changeMenuLayout');
                });
            };
            $scope.gotoConfig = function(link){
                $location.hash(link);
            }

            /*$scope.$watch(function() {
                var columns = $filter('flatten')($filter('pluck')($scope.model.rows, 'columns'), true);
                var widgets = $filter('flatten')($filter('pluck')(columns, 'widgets'), true);
                return widgets.length;
            }, function (value) {
                $scope.widgetCount = value;
                $scope.$broadcast(DashboardEvents.RESIZE);
            });*/
        }
    };

});

module.directive('dashboardWidgetCount', function($timeout, $filter, DashboardEvents) {
    return {
        scope : false,
        priority : 1,
        restrict : 'A',
        link : function(scope, elem) {
            scope.$watch(function() {
                var columns = $filter('flatten')($filter('pluck')(scope.model.rows, 'columns'), true);
                var widgets = $filter('flatten')($filter('pluck')(columns, 'widgets'), true);
                return widgets.length;
            }, function(value) {
                scope.widgetCount = value;
                scope.$broadcast(DashboardEvents.RESIZE);
            });
        }
    };
});

module.directive('expandToBottom', function($window, $timeout, DashboardEvents) {
    return {
        scope : false,
        restrict : 'A',
        link : function(scope, elem) {
            var $w = angular.element($window);
            var margin = parseInt(angular.element('.main-view').css('margin-bottom'));

            var resize = function () {
                var height = $w.innerHeight() - elem.offset().top - margin;
                if (height < 200) {
                    height = 200;
                }
                elem.css("height", Math.floor(height) + "px");
                scope.$broadcast(DashboardEvents.RESIZE);
            };

            $w.on('resize', resize);
            $w.resize();

            scope.$on('$destroy', function() {
                $w.off('resize', resize);
            });
        }
    };
});

module.directive('expandToParentBottom', function($timeout, DashboardEvents) {
    return {
        scope : false,
        restrict : 'A',
        link : function(scope, elem, attrs) {
            var margins = parseInt(elem.css('margin-top')) + parseInt(elem.css('margin-bottom')) + parseInt(elem.css('border-top-width')) + parseInt(elem.css('border-bottom-width'));

            function resize() {
                var parent = elem.parent();
                var height = parent.innerHeight() - (elem.offset().top - parent.offset().top) - margins;
                elem.css("height", Math.floor(height) + "px");
            }

            scope.$on(DashboardEvents.RESIZE, function() {
                resize();
                $timeout(resize);
            });

            //resize();
            $timeout(resize);
        }
    };
});

module.directive('openInNewWindow', function($window, $location) {
    return {
        restrict : 'A',
        link : function($scope, $element) {

            $scope.openInNewWindow = function() {
                var win = $window.open($location.url(), '_blank', 'toolbar=0,location=0,menubar=0');
                win.resizeTo(screen.width, screen.height);
                win.moveTo(500, 100);
                $(win.window).load(function() {
                    var navbar = win.document.getElementById("navbar");
                    var parent = navbar.parentElement;
                    parent.removeChild(navbar);
                    });
            };
        }
    };
});

module.directive('removeDashboard', function($window, $location, dashboardService) {
    return {
        restrict : 'A',
        link : function($scope, $element) {
            $scope.removeDashboard = function(model){
            dashboardService.remove(model).then(
            	function(dashboardMenuLink) {
                    $scope.$emit('changeMenuLayout');
            	});
            }
        }
    };
});


function TakeSnapshot($rootScope) {
    return {
        scope : false,
        restrict : "A",
        link : function(scope, elem) {
            scope.savePicture = function() {
                var date = moment().format("YYYY-MM-DD HH-mm-ss");
                var svg = elem.closest(".widget").find("svg")[0];
                if (typeof(svg) !== 'undefined'){
                    saveSvgAsPng(svg, scope.widget.title + " " + date);
                } else {
                    svg = (elem.closest(".widget").find(".widget-content")[0]);
                    saveSvgAsPng(svg, scope.widget.title + " " + date);
                }
               
            };
        }
    };
}
module.directive('takeSnapshot', TakeSnapshot);

module.directive('generateCsv', function() {
   return {
       scope:false,
       link: function($scope, $element) {
           $scope.generateCsv = function(){
               $scope.$parent.$parent.$broadcast('generateCsv');
            };
           $scope.generateXlsx = function(){
               $scope.$parent.$parent.$broadcast('generateXlsx');
           };
       }
   };
});

module.directive('widgetEditFormLock', function() {
    return {
        restrict : 'A',

        replace : true,
        require : 'form',
        scope : false,
        link : function(scope, el, attrs, formCtrl) {

            scope.definitionCopy = angular.copy(scope.definition);
            scope.cancel = function() {
                angular.copy(scope.definitionCopy, scope.definition);
                scope.closeDialog();
            };
            scope.close = function() {
                scope.$broadcast('check-validity');
                scope.invalid = formCtrl.$invalid;
                if (formCtrl.$invalid) {
                    return;
                }
                scope.closeDialog();
            };

        }
    };
});
