/*global tools */
var module = angular.module('meternet.chart.directives.line', ['meternet.dashboard.constants']);

module.directive('lineChart',
    function ($window, $timeout, DashboardEvents, unitFilter, dateFilter, i18nFilter, csvService, configService,
              $filter, dashboardService) {

        function Axis() {
            this._options = {
                lineColor: "#d3d3d3",
                labelColor: "#000000"
            };
        }

        Axis.prototype.scale = function (scale) {
            if (arguments.length === 1) {
                this._scale = scale.copy();
                delete this._ticks;
                return this;
            } else {
                return this._scale.copy();
            }
        };

        Axis.prototype.options = function (options) {
            if (arguments.length === 1) {
                tools.copy(options, this._options);
                delete this._ticks;
                return this;
            } else {
                return tools.copy(this._options);
            }
        };

        Axis.prototype.recalc = function () {
            var divisions = [0.1, 0.2, 0.5, 1, 2, 5];
            var subdivisions = [10, 4, 5, 10, 4, 5];
            var pf = Math.pow(10, this._options.scale - this._options.precision);

            var tickSpacing = this._options.tickSpacing || 30;
            var subtickSpacing = this._options.subtickSpacing || 5;
            var ticks = [];
            var r = this._scale.range();
            var d = this._scale.domain();
            var dr = Math.abs(r[1] - r[0]);
            var dd = Math.abs(d[1] - d[0]);
            var unit = Math.pow(10, Math.round(Math.log(dd / Math.floor(dr / tickSpacing)) / Math.LN10));
            while (unit < pf) {
                unit = pf;
            }

            var st = 10;
            var div = unit;
            var scale0 = this._scale(0);
            var j, di, ts;
            for (j = 0; j < divisions.length; ++j) {
                di = unit * divisions[j];
                if (di >= pf) {
                    ts = Math.abs(this._scale(di) - scale0);
                    if (ts >= tickSpacing) {
                        div = di;
                        st = subdivisions[j];
                        ts = Math.abs(this._scale(div / st) - scale0);
                        if (ts < subtickSpacing) {
                            st /= 2;
                        }
                        break;
                    }
                }
            }

            var min = div * Math.round(d[0] / div) - div;
            var max = div * Math.round(d[1] / div) + div;

            var u, tick, major, i;
            var sdiv = div / st;
            for (u = min, i = 0; u <= max; u += sdiv, ++i) {
                if (i === st) {
                    i = 0;
                }
                major = i === 0;
                tick = major ? d3.round(u, this._options.precision) : u;
                if (tick >= d[0] && tick <= d[1]) {
                    ticks.push({
                        value: tick,
                        major: major
                    });
                }
            }

            this._ticks = ticks;
            // this._precision = prec;
        };

        Axis.prototype.renderAxis = function (elem) {
            if (!this._ticks) {
                this.recalc();
            }

            var options = this._options;
            var scale = this._scale;
            var ticks = this._ticks;

            var tickSizeOuter = options.tickSizeOuter || [8, 5];
            var tickSizeInner = options.tickSizeInner || [0, 0];

            var r = scale.range();

            var labelColor = options.labelColor;
            var axisColor = options.axisColor;

            var t = elem.selectAll("g.tick").data(ticks, function (d) {
                return (d.major ? "major" : "minor") + d.value;
            });

            var nt = t.enter().append("g").attr("class", "tick");
            nt.classed("tick-minor", function (d) {
                return !d.major;
            }).classed("tick-major", function (d) {
                return d.major;
            }).append("line").attr({
                "x1": function (d) {
                    return d.major ? -tickSizeOuter[0] : -tickSizeOuter[1];
                },
                "x2": function (d) {
                    return d.major ? tickSizeInner[0] : tickSizeInner[1];
                }
            });
            nt.filter(function (d, i) {
                return d.major;
            }).append("text").attr({
                "x": -tickSizeOuter[0] - 3,
                "dy": ".32em"
            }).style({
                "text-anchor": "end"
            }).text(function (d) {
                var value = unitFilter(d.value, options.precision, "", options.scale).replace('-', '−');
                // if (d.value >= 0) value = '&ensp;' + value;
                return value;
            });

            t.select("line").attr({
                "y1": function (d) {
                    return Math.round(scale(d.value));
                },
                "y2": function (d) {
                    return Math.round(scale(d.value));
                }
            }).style({
                "stroke": axisColor
            });

            t.select("text").attr({
                "y": function (d) {
                    return Math.round(scale(d.value));
                }
            }).style({
                "fill": labelColor
            });

            t.exit().remove();

            var path = elem.select("path.domain");
            if (path.empty()) {
                path = elem.append("path").attr("class", "domain");
            }
            path.attr({
                "d": "M" + (-tickSizeOuter[0]) + "," + r[1] + "H" + tickSizeInner[0] + "M0," + r[1] + "V" + r[0] + "M" + (-tickSizeOuter[0]) + "," + r[0] + "H" + tickSizeInner[0]
            }).style({
                "stroke": axisColor
            });
        };

        Axis.prototype.renderGrid = function (elem, x1, x2) {
            if (!this._ticks) {
                this.recalc();
            }

            var options = this._options;
            var scale = this._scale;
            var ticks;
            if (this._options.grid === 0) {
                ticks = [];
            } else if (this._options.grid === 1) {
                ticks = [];
                for (var i = 0; i < this._ticks.length; ++i) {
                    if (this._ticks[i].major) {
                        ticks.push(this._ticks[i]);
                    }
                }
            } else {
                ticks = this._ticks;
            }

            var r = scale.range();

            var t = elem.selectAll("g.grid").data(ticks, function (d) {
                return (d.major ? "major" : "minor") + d.value;
            });

            var nt = t.enter().append("g").attr("class", "grid");
            nt.classed("grid-minor", function (d) {
                return !d.major;
            }).classed("grid-major", function (d) {
                return d.major;
            }).append("line");

            t.select("line").attr({
                "x1": x1,
                "x2": x2,
                "y1": function (d) {
                    return Math.round(scale(d.value));
                },
                "y2": function (d) {
                    return Math.round(scale(d.value));
                }
            }).style({
                "stroke": function (d, i) {
                    return d.major ? options.mainGridColor : options.subGridColor;
                }
            });

            t.exit().remove();
        };

        var tooltipTemplate = _.template(
            '<% _.each(points, function(p) { %>' +
            '   <p style="color: <%= p.color %>">' +
            '       <span class="name"><%- p.name %></span><br/>' +
            '       <span class="fa fa-clock-o"></span>&nbsp;<span class="time"><%- p.timestampString %></span><br/>' +
            '       <span class="fa fa-dot-circle-o"></span>&nbsp;<span class="value"><%- p.valueString %></span>' +
            '   </p>' +
            '<% }); %>'
        );

        function LineChart(svg, opt, callbacks) {

            this.loadingData = true;

            var options = {
                margin: {
                    top: 20,
                    right: 5,
                    bottom: 80,
                    left: 0
                },
                timeTo: null
            };

            var width;
            var height;

            var gridSeries;
            var series = [];
            var holes = [];
            var x = d3.time.scale();

            var chart = this;
            var chartId = _.uniqueId('line-chart-');
            var mainDiv = d3.select(svg.node().parentNode);
            var defs = svg.append('defs').attr('class', 'chart-defs');

            var areaClip = defs.append("clipPath").attr("id", chartId + "-area-clip");
            areaClip.append("rect").attr("x", 0).attr("y", 0);

            var xaxis = d3.svg.axis().orient("bottom").tickPadding(5);
            xaxis.tickFormat(d3.time.format.multi([["%H:%M:%S", function (d) {
                return d.getSeconds();
            }], ["%H:%M", function (d) {
                return d.getHours() || d.getMinutes();
            }], ["%Y-%m-%d", function (d) {
                return true;
            }]]));

            var timestampComparator = function (a, b) {
                return a.timestamp - b.timestamp;
            };

            var timestampBisector = d3.bisector(timestampComparator).left;

            function reinit(opt) {
                var i, s, os;
                var o1 = tools.copy(options);
                var o2 = tools.copy(opt, options);
                if (!angular.equals(o1.series, o2.series)) {
                    series = [];
                    var hasOwnAxis;
                    for (i = 0; i < o2.series.length; ++i) {
                        os = o2.series[i];
                        hasOwnAxis = os.axisIndex == null;
                        series[i] = {
                            index: i,
                            visible: os.visible || true,
                            series: os,
                            axis: hasOwnAxis ? os.axis : o2.series[os.axisIndex].axis,
                            ownAxis: hasOwnAxis,
                            values: [],
                            min: NaN,
                            minOn: os.minOn,
                            max: NaN,
                            maxOn: os.maxOn,
                            avg: NaN,
                            avgOn: os.avgOn,
                            markName: os.markName ? os.markName : [],
                            mark: os.mark,
                            markColor: os.markColor ? os.markColor : [],
                            markStyle: os.markStyle ? os.markStyle : [],
                            y: hasOwnAxis ? d3.scale.linear() : null,
                            yaxis: hasOwnAxis ? new Axis() : null
                        };
                    }
                    gridSeries = null;
                    if (isFinite(opt.gridSerieIndex) && series[opt.gridSerieIndex]) {
                        gridSeries = series[opt.gridSerieIndex];
                    }
                    _.each(series, function (s) {
                        if (!s.ownAxis) {
                            s.y = series[s.series.axisIndex].y;
                        } else if (gridSeries == null && s.axis.grid > 0) {
                            // gridSeries = s;
                        }
                        s.line = d3.svg.line().x(function (d) {
                            return x(d.timestamp);
                        }).y(function (d) {
                            return s.y(d.value);
                        }).defined(function (d) {
                            return d.value != null && isFinite(d.value);
                        });
                    });

                }

                var tt1 = o1.timeTo instanceof Date ? o1.timeTo.getTime() : null;
                var tt2 = o2.timeTo instanceof Date ? o2.timeTo.getTime() : null;
                var tf1 = o1.timeFrom instanceof Date ? o1.timeFrom.getTime() : null;
                var tf2 = o2.timeFrom instanceof Date ? o2.timeFrom.getTime() : null;
                if (tt1 !== tt2 || tf1 !== tf2) {
                    var now = new Date(o2.timeTo) || new Date();
                    x.domain([o2.timeFrom, now]);
                    if (chart.zoom) {
                        chart.zoom.x(x).scale(1).translate([0, 0]);
                    }
                }
            }

            this.options = function (o) {
                if (!arguments.length) {
                    return tools.copy(options);
                } else {
                    reinit(o);
                    return this;
                }
            };

            this.update = function (data) {
                var i, j, d, s, val, min, max, sum, count;

                for (i = 0; i < series.length; ++i) {
                    d = data[i];
                    if (typeof (d) === 'undefined') {
                        continue;
                    }
                    min = Infinity;
                    max = -Infinity;
                    sum = 0;
                    count = 0;
                    for (j = 0; j < d.length; ++j) {
                        val = d[j].value;
                        if (val != null && isFinite(val)) {
                            ++count;
                            sum += val;
                            min = Math.min(min, val);
                            max = Math.max(max, val);
                        }
                    }
                    s = series[i];
                    s.values = d;
                    if (count) {
                        s.min = min;
                        s.max = max;
                        s.avg = sum / count;
                    } else {
                        s.min = NaN;
                        s.max = NaN;
                        s.avg = NaN;
                    }
                    s.y.domain([NaN, NaN]);
                }

                for (i = 0; i < series.length; ++i) {
                    s = series[i];
                    if (s.axis.rangeAuto) {
                        val = Math.pow(10, s.axis.scale - s.axis.precision);
                        min = s.min;
                        max = s.max;
                        if (isFinite(min) && isFinite(max)) {
                            d = max > min ? (max - min) / 10 : Math.abs(min) / 10;
                            if (d < val) {
                                d = val;
                            }
                            min = d3.round(min - d, s.axis.precision);
                            max = d3.round(max + d, s.axis.precision);
                            d = s.y.domain();
                            if (isFinite(d[0])) {
                                min = Math.min(d[0], min);
                            }
                            if (isFinite(d[1])) {
                                max = Math.max(d[1], max);
                            }
                            s.y.domain([min, max]).nice();
                        }
                    } else if (s.ownAxis) {
                        //d = Math.pow(10, s.axis.scale);
                        min = s.axis.rangeMin;// * d;
                        max = s.axis.rangeMax;// * d;
                        s.y.domain([min, max]);
                    }
                }

                for (i = 0; i < series.length; ++i) {
                    s = series[i];
                    if (s.ownAxis) {
                        d = s.y.domain();
                        if (!isFinite(d[0]) || !isFinite(d[1])) {
                            s.y.domain([0, 1]);
                        }
                    }
                }

                this.render();
            };

            this.render = function () {
                var chart = this;
                var i, j, s;

                width = options.width - (options.margin.right + options.margin.left);
                height = options.height - (options.margin.top + options.margin.bottom);

                if (width <= 0 || height <= 0) {
                    svg.selectAll("g").remove();
                    return;
                }

                svg.attr({
                    width: options.width,
                    height: options.height
                });

                var canvas = svg.select("g.chart-canvas");
                if (canvas.empty()) {
                    canvas = svg.append("g").attr("class", "chart-canvas");
                }
                canvas.attr("transform", "translate(" + options.margin.left + "," + options.margin.top + ")");

                var gxaxis = canvas.select("g.x.axis");
                if (gxaxis.empty()) {
                    gxaxis = canvas.append("g").attr("class", "x axis");
                }

                var tooltip = mainDiv.select("div.chart-tooltip");
                if (tooltip.empty()) {
                    tooltip = mainDiv.append("div");
                    tooltip.attr("class", "chart-tooltip interactive").style({
                        "display": "none",
                        "position": "absolute"
                    });
                }

                var hideTooltip = function (leaveLine) {
                    tooltip.style("display", "none");
                    area.selectAll("circle.tooltip-circle").transition().ease("linear").duration(100).style({
                        "opacity": 0
                    }).remove();
                    if (!leaveLine) {
                        area.select("line.tooltip-line-x").remove();
                    }
                };

                var showTooltip = function () {
                    tooltip.style("display", null);
                    if (area.select("line.tooltip-line-x").empty()) {
                        area.append("line").attr("class", "tooltip-line-x interactive");
                    }
                };

                var area = canvas.select("g.chart-area");
                if (area.empty()) {
                    area = canvas.append("g").attr("class", "chart-area draggable").attr("clip-path",
                        "url(#" + chartId + "-area-clip)");

                    area.append("rect").attr({
                        "class": "chart-area-fill",
                        "x": 0,
                        "y": 0
                    }).on(
                        "mousemove.tooltip",
                        function () {
                            var points = [];
                            var m = d3.mouse(mainDiv.node());
                            var rm = d3.mouse(this);
                            if (!chart.dragged) {
                                area.selectAll(".series path").each(
                                    function (d) {
                                        if (d.visible && d.values && d.values.length > 0) {
                                            var px = {
                                                timestamp: x.invert(rm[0])
                                            };
                                            var pi = timestampBisector(d.values, px);
                                            var p = [];
                                            if (pi > 0) {
                                                p.push(d.values[pi - 1]);
                                            }
                                            p.push(d.values[pi]);
                                            if (pi < d.values.length - 1) {
                                                p.push(d.values[pi + 1]);
                                            }

                                            p.sort(function (a, b) {
                                                return Math.abs(a.timestamp - px.timestamp) - Math
                                                        .abs(b.timestamp - px.timestamp);
                                            });

                                            p = p[0];
                                            var dist = Math.abs(x(p.timestamp) - rm[0]);
                                            if (dist < 40) {
                                                points.push({
                                                    "name": d.series.name,
                                                    "index": d.index,
                                                    "timestamp": p.timestamp,
                                                    "value": p.value,
                                                    "timestampString": dateFilter(p.timestamp,
                                                        'yyyy-MM-dd HH:mm:ss'),
                                                    "valueString": unitFilter(p.value, d.axis.precision,
                                                        d.series.unit, d.axis.scale),
                                                    "color": d.series.lineColor
                                                });
                                            }
                                        }
                                    });
                            }

                            if (points.length > 0) {
                                showTooltip();

                                area.selectAll("circle.tooltip-circle").transition().ease("linear").duration(
                                    100).style({
                                        "opacity": 0
                                    });

                                for (var i = 0; i < points.length; ++i) {
                                    var p = points[i];
                                    var circle = area.select("circle.tooltip-circle-" + p.index);
                                    if (circle.empty()) {
                                        area.append("circle").attr({
                                            "class": "tooltip-circle tooltip-circle-" + p.index,
                                            "r": 12
                                        }).style({
                                            "stroke": "none",
                                            "fill": p.color,
                                            "opacity": 0
                                        });
                                    }
                                    if (p.value != null && isFinite(p.value)) {
                                        circle.attr({
                                            "cx": x(p.timestamp),
                                            "cy": series[p.index].y(p.value)
                                        }).style({
                                            "display": null
                                        }).transition().ease("linear").duration(100).style({
                                            "opacity": 0.4
                                        });
                                    } else {
                                        circle.style({
                                            "display": "none"
                                        });
                                    }
                                }

                                tooltip.style({
                                    "left": "0px",
                                    "top": "0px"
                                }).html(tooltipTemplate({
                                    points: points,
                                    i18n: i18nFilter
                                }));

                                var $t = $(tooltip.node());
                                var $p = $(mainDiv.node());
                                var tooltipOffset = 12;
                                var posx = m[0] + tooltipOffset;
                                if (posx + $t.outerWidth() > $p.innerWidth()) {
                                    posx = m[0] - tooltipOffset - $t.outerWidth();
                                }
                                var posy = m[1] + tooltipOffset;
                                if (posy + $t.outerHeight() > $p.innerHeight()) {
                                    posy = $p.innerHeight() - $t.outerHeight();
                                }
                                tooltip.style({
                                    "left": Math.round(posx) + "px",
                                    "top": Math.round(posy) + "px"
                                });

                                area.select("line.tooltip-line-x").attr({
                                    "x1": rm[0],
                                    "y1": 0,
                                    "x2": rm[0],
                                    "y2": height
                                });
                            } else {
                                hideTooltip();
                            }
                        }).on("mouseover.tooltip", function () {
                            if (chart.dragged) {
                                hideTooltip();
                            } else {
                                showTooltip();
                            }
                        }).on("mouseout.tooltip", function () {
                            hideTooltip();
                        });
                }

                for (i = 0; i < series.length; ++i) {
                    s = series[i];
                    if (s.ownAxis) {
                        s.y.range([height, 0]);
                    }

                    var marker = defs.select("marker#" + chartId + "-series-marker-" + i);
                    if (marker.empty()) {
                        marker = defs.append("marker").attr("id", chartId + "-series-marker-" + i);
                        marker.append("circle").attr({
                            cx: 11,
                            cy: 11
                        });
                    }
                    marker.attr({
                        "refX": 11,
                        "refY": 11,
                        "markerWidth": 21,
                        "markerHeight": 21,
                        "markerUnits": "userSpaceOnUse",
                        "orient": "auto"
                    }).style({
                        "stroke": "none",
                        "fill": s.series.markerColor
                    }).select("circle").attr({
                        "r": s.series.markerSize
                    });
                }

                var axisSpace = 0;
                var axisSpaceFirst = null;
                var axisCount = 0;
                for (i = 0; i < series.length; ++i) {
                    s = series[i];
                    if (s.ownAxis) {
                        s.yaxis.scale(s.y).options(s.axis);

                        var gaxis = canvas.select("g.y.axis-" + i);
                        if (gaxis.empty()) {
                            gaxis = canvas.append("g").attr({
                                "class": "y axis axis-" + i
                            });
                        }

                        s.yaxis.renderAxis(gaxis);

                        var w = gaxis.node().getBBox().width;

                        if (w > axisSpace) {
                            axisSpace = w;
                        }
                        if (axisSpaceFirst == null) {
                            axisSpaceFirst = w;
                        }

                        var label = canvas.select("text.axis-label-" + i);
                        if (label.empty()) {
                            label = canvas.append("text").attr({
                                "class": "axis-label axis-label-" + i,
                                "y": -10
                            }).style({
                                "text-anchor": "middle"
                            });
                        }
                        label.text("[" + (unitFilter(1, -1, s.series.unit, s.axis.scale).trim()) + "]");

                        ++axisCount;
                    } else {
                        canvas.select("g.y.axis-" + i).remove();
                        canvas.select("text.axis-label-" + i).remove();
                    }
                }
                axisSpace += 10;

                var paxis = axisSpaceFirst || 0;
                for (i = 0; i < series.length; ++i) {
                    s = series[i];
                    if (s.ownAxis) {
                        canvas.select("g.y.axis-" + i).attr("transform", "translate(" + paxis + ",0)");
                        canvas.select("text.axis-label-" + i).attr("transform", "translate(" + paxis + ",0)");
                        paxis += axisSpace;
                    }
                }
                paxis = axisCount ? axisSpaceFirst + (axisCount - 1) * axisSpace : 0;
                width -= paxis;

                x.range([0, width]);

                var zoom = this.zoom;
                if (!zoom) {
                    zoom = d3.behavior.zoom().size([width, height]).x(x).scaleExtent([1, Infinity]).on(
                        'zoom', function () {
                            hideTooltip();
                            chart.render();
                        }).on("zoomstart", function () {
                            area.classed({
                                "dragged": true
                            });
                            chart.dragged = true;
                            chart.autoscroll = false;
                            hideTooltip();
                        }).on("zoomend", function () {
                            area.classed({
                                "dragged": false
                            });
                            chart.dragged = false;
                            hideTooltip();
                            callbacks.setTimeSpan(x.invert(0), x.invert(width));
                        });

                    this.zoom = zoom;
                    zoom(area);
                }

                function getLastTimestamp() {
                    var s, i, v, now = new Date(0);
                    for (i = 0; i < series.length; ++i) {
                        s = series[i];
                        if (s.visible && s.values && s.values.length) {
                            v = s.values[s.values.length - 1].timestamp;
                            if (now < v) {
                                now = v;
                            }
                        }
                    }
                    return now.getTime() > 0 ? now : new Date();
                }
                if (this.autoscroll) {
                    s = zoom.scale();
                    var historyLength = (options.timeUnit || 60) * (options.historyTime || 24) * 60 * 1000;
                    var now = getLastTimestamp();
                    var from = new Date(now.getTime() - historyLength);
                    x.domain([from, now]);
                    zoom.x(x);
                    zoom.scale(s);
                    zoom.translate([-width * s + width, 0]);
                    hideTooltip();
                }

                xaxis.scale(x).tickSize(-height, 0);

                gxaxis.attr("transform", "translate(" + paxis + "," + height + ")");
                gxaxis.call(xaxis);
                gxaxis.selectAll(".tick>line").style("stroke", options.mainGridColor);

                var gxmin = gxaxis.select("g.min");
                if (gxmin.empty()) {
                    gxmin = gxaxis.append("g").attr("class", "extreme min");
                    gxmin.append("rect");
                    gxmin.append("text");
                }
                gxmin.select("rect").attr({
                    "x": -20,
                    "y": 4,
                    "width": 130,
                    "height": 10
                });
                gxmin.select("text").attr({
                    "x": 0,
                    "y": 5,
                    "dy": ".71em"
                }).text(dateFilter(x.domain()[0], 'yyyy-MM-dd HH:mm:ss'));

                var gxmax = gxaxis.select("g.max");
                if (gxmax.empty()) {
                    gxmax = gxaxis.append("g").attr("class", "extreme max");
                    gxmax.append("rect");
                    gxmax.append("text");
                }
                gxmax.select("rect").attr({
                    "x": width - 110,
                    "y": 4,
                    "width": 130,
                    "height": 10
                });
                gxmax.select("text").attr({
                    "x": width,
                    "y": 5,
                    "dy": ".71em"
                }).text(dateFilter(x.domain()[1], 'yyyy-MM-dd HH:mm:ss'));

                areaClip.select("rect").attr({
                    "width": width,
                    "height": height
                });

                area.attr("transform", "translate(" + paxis + ",0)");

                area.select("rect.chart-area-fill").attr("width", width).attr("height", height);

                var grid = area.select("g.chart-grid");
                if (grid.empty()) {
                    grid = area.append("g").attr("class", "chart-grid");
                }
                if (gridSeries != null) {
                    if (gridSeries.yaxis) {
                        gridSeries.yaxis.renderGrid(grid, 0, width);
                    }
                } else {
                    grid.selectAll("*").remove();
                }

                function simplify(s, holes) {
                    var i, s1, s2, p1, p2, px1, px2, px, pf, hx1, min, max, pmin, pmax, dx = 1;

                    function appendPoint() {
                        if (!pf) {
                            if (!s.points.length || isFinite(s.points[s.points.length - 1].value)) {
                                s.points.push(p1);
                            }
                        }

                        if (pmin) {
                            if (pmin === pmax) {
                                s.points.push(pmin);
                            } else if (pmin.timestamp.getTime() < pmax.timestamp.getTime()) {
                                s.points.push(pmin);
                                s.points.push(pmax);
                            } else {
                                s.points.push(pmax);
                                s.points.push(pmin);
                            }
                        }
//                                FIXME: TK: debugging
//                                var unsorted = -1;
//                                for (var j =0; j < s.points.length-1; ++j){
//                                    if (s.points[j].timestamp.getTime() > s.points[j+1].timestamp.getTime()){
//                                        unsorted = j;
//                                    }
//                                }
//                                if (unsorted > -1){
//                                   throw new Error(unsorted +" "+s.points.length);
//                                }
//                                

                    }

                    function appendHole() {
                        var x1, x2, h;
                        if (hx1 != null) {
                            x1 = Math.floor(hx1);
                            x2 = Math.ceil(px2);

                            h = _.filter(holes, function (hole) {
                                return hole.x1 <= x2 && hole.x2 >= x1;
                            });
                            if (h.length === 0) {
                                holes.push({
                                    x1: x1,
                                    x2: x2
                                });
                            } else if (h.length === 1) {
                                h = h[0];
                                h.x1 = Math.min(h.x1, x1);
                                h.x2 = Math.max(h.x2, x2);
                            } else {
                                for (i = 0; i < h.length; ++i) {
                                    holes.splice(holes.indexOf(h[i]), 1);
                                }
                                holes.push({
                                    x1: _.min(h, function (hole) {
                                        return hole.x1;
                                    }).x1,
                                    x2: _.max(h, function (hole) {
                                        return hole.x2;
                                    }).x2
                                });
                            }
                            hx1 = null;
                        }
                    }

                    if (s.values.length > 2) {
                        s.points = [];

                        pmin = x.invert(0 - 10).getTime();
                        pmax = x.invert(width + 10).getTime();

                        for (s1 = 0; s1 < s.values.length; ++s1) {
                            if (s.values[s1].timestamp.getTime() >= pmin) {
                                break;
                            }
                        }

                        if (s1 === s.values.length) {
                            return;
                        }

                        if (s1) {
                            --s1;
                        }

                        for (s2 = s.values.length; s2 > 0; --s2) {
                            if (s.values[s2 - 1].timestamp.getTime() <= pmax) {
                                break;
                            }
                        }

                        if (s2 === 0) {
                            return;
                        }

                        if (s2 < s.values.length) {
                            ++s2;
                        }

                        p1 = s.values[s1];
                        px1 = x(p1.timestamp);
                        pf = isFinite(p1.value);
                        if (pf) {
                            min = p1.value;
                            max = p1.value;
                            pmin = p1;
                            pmax = p1;
                            hx1 = null;
                        } else {
                            min = Infinity;
                            max = -Infinity;
                            pmin = pmax = null;
                            hx1 = px1;
                        }

                        px = px1;
                        for (i = s1 + 1; i < s2; ++i) {
                            p2 = s.values[i];
                            px2 = x(p2.timestamp);

                            if (isFinite(p2.value)) {
                                appendHole();
                            } else if (hx1 == null) {
                                hx1 = px;
                            }

                            px = px2;

                            if (px2 - px1 > dx) {
                                appendPoint();
                                p1 = p2;
                                px1 = px2;
                                pf = isFinite(p2.value);
                                if (pf) {
                                    min = p1.value;
                                    max = p1.value;
                                    pmin = p1;
                                    pmax = p1;
                                } else {
                                    min = Infinity;
                                    max = -Infinity;
                                    pmin = pmax = null;
                                }
                            } else {
                                if (isFinite(p2.value)) {
                                    if (min > p2.value) {
                                        min = p2.value;
                                        pmin = p2;
                                    }
                                    if (max < p2.value) {
                                        max = p2.value;
                                        pmax = p2;
                                    }
                                }
                            }
                        }
                        appendHole();
                        appendPoint();
                    } else {
                        s.points = s.values;
                    }
                }

                holes.length = 0;
                for (i = 0; i < series.length; ++i) {
                    s = series[i];
                    if (s.visible && s.values && s.values.length) {
                        simplify(s, holes);
                    } else {
                        s.points = [];
                    }
                    //FIXME TK: remove sorting
                    s.points.sort(timestampComparator);
                }
                var area2 = area.select("g.chart-data");
                if (area2.empty()) {
                    area2 = area.append("g").attr("class", "chart-data");
                }

                var gholes = area2.select("g.holes");
                if (gholes.empty()) {
                    gholes = area2.append("g").attr("class", "holes");
                }


                $timeout(function () {
                    gholes = gholes.selectAll("rect").data(holes);
                    gholes.enter().append("rect");

                    gholes.attr({
                        "x": function (d, i) {
                            return Math.round(d.x1);
                        },
                        "width": function (d, i) {
                            return Math.max(0, Math.round(d.x2 - d.x1));
                        },
                        "y": 1,
                        "height": height
                    });

                    gholes.exit().remove();
                    var gseries = area2.selectAll("g.series").data(series, function (d, i) {
                        return Math.random(); // JC needed for IE
                    });

                    var ngseries = gseries.enter().append("g").attr("class", "series");
                    ngseries.append("path").attr("class", "line");

                    gseries.select("path").attr("d", function (d) {
                        var p = d.line(d.points);
                        return p;
                    }).style({
                        "stroke": function (d) {
                            return d.series.lineColor;
                        },
                        "stroke-width": function (d) {
                            return d.series.lineWidth;
                        },
                        "marker-mid": function (d) {
                            return "url(#" + chartId + "-series-marker-" + d.index + ")";
                        },
                        "marker-start": function (d) {
                            return "url(#" + chartId + "-series-marker-" + d.index + ")";
                        },
                        "marker-end": function (d) {
                            return "url(#" + chartId + "-series-marker-" + d.index + ")";
                        }
                    });

                    gseries.exit().remove();
                }, 0);

                var icon;
                if (!this.autoscroll && options.enableScroll !== false) {
                    icon = area.select("text.icon-autoscroll");
                    if (icon.empty()) {
                        icon = area.append("text").attr("class", "icon icon-autoscroll fa interactive");
                        icon.text("\uf0a9");
                        icon.on("click.autoscroll", function () {
                            chart.autoscroll = true;
                            callbacks.setTimeSpan(options.timeFrom, undefined);
                            chart.render();
                        });
                    }
                    icon.attr({
                        "x": width - 40,
                        "y" : 40
                    });
                } else {
                    area.select("text.icon-autoscroll").remove();
                }

                if (zoom.scale() !== 1) {
                    icon = area.select("text.icon-rescale");
                    if (icon.empty()) {
                        icon = area.append("text").attr("class", "icon icon-rescale fa interactive");
                        icon.text("\uf0b2");
                        icon.on("click.rescale", function () {
                            var d = x.domain();
                            var tx = new Date((d[0].getTime() + d[1].getTime()) / 2);
                            zoom.scale(1);
                            zoom.translate([0, 0]);
                            d = x.domain();
                            tx = x(tx) - x(new Date((d[0].getTime() + d[1].getTime()) / 2));
                            zoom.translate([-tx, 0]);
                            hideTooltip();
                            callbacks.setTimeSpan(x.invert(0), x.invert(width));
                            chart.render();
                        });
                    }
                    icon.attr({
                        "x": (width - 40), 
                        "y" : 80
                    });
                } else {
                    area.select("text.icon-rescale").remove();
                }

                var empty = (series.length === 0) || this.loadingData;
                if (!empty) {
                    for (i = 0; i < series.length; ++i) {
                        if (!series[i].series.paramId && series[i].values && series[i].values.length) {
                            empty = false;
                            break;
                        }
                    }
                }
                if (empty) {
                    icon = area.select("g.loader");
                    if (icon.empty()) {
                        icon = area.append("g").attr("class", "loader interactive");
                        icon.attr("transform", "translate(" + 110 + "," + 30 + ")");
                        icon.append("rect").attr({
                            "rx": 7,
                            "ry": 7,
                            "x": -100,
                            "y": -20,
                            "width": 200,
                            "height": 40
                        });
                        icon.append("text").attr({
                            "x": 0,
                            "y": 0,
                            "dy": ".32em"
                        }).text(i18nFilter("ui.loading.data"));
                    }
                } else {
                    area.select("g.loader").remove();
                }

                var legend = svg.select("g.legend");
                if (legend.empty()) {
                    legend = svg.append('g').attr('class', 'legend');
                }
                var addLine = function(type, data) {
                    if (!isNaN(data[type]) || Array.isArray(data[type])){

                        if (Array.isArray(data[type])){
                            _.forEach(data[type], function(m,i){
                                var selection = svg.select("line."+type+i+data.series.paramId);
                                if (selection.empty()) {
                                    selection = svg.append("svg:line").attr("class", type+i+data.series.paramId);
                                }
                                selection.attr("x1", 0)
                                    .attr("y1", data.y(m)+20)
                                    .attr("x2", width)
                                    .attr("y2", data.y(m)+20)
                                    .style("stroke", data['markColor'][i] || data.series.lineColor)
                                    .style("stroke-dasharray", (data['markStyle'][i]?data['markStyle'][i]:"3 3"))
                                    .attr("transform", "translate(" + paxis + ",0)");

                                var myText = svg.select("text."+type+i+data.series.paramId);
                                if (myText.empty()) {
                                    myText = svg.append("text").attr("class", type+i+data.series.paramId);
                                }
                                myText.attr("y", data.y(m)+17)//magic number here
                                   .attr("x", 10)
                                   .attr('text-anchor', 'right')
                                   .style("fill", data['markColor'][i] || data.series.lineColor)
                                   .attr("transform", "translate(" + paxis + ",0)")
                                   .text(data['markName'][i]);
                            });
                        }else{

                            var selection = svg.select("line."+type+data.series.paramId);
                            if (selection.empty()) {
                                selection = svg.append("svg:line").attr("class", type+data.series.paramId);
                            }
                            if (data[type+'On']){
                                selection.attr("x1", 0)
                                    .attr("y1", data.y(data[type])+20)
                                    .attr("x2", width)
                                    .attr("y2", data.y(data[type])+20)
                                    .style("stroke", data.series.lineColor)
                                    .style("stroke-dasharray", (data[type+'On']))
                                    .attr("transform", "translate(" + paxis + ",0)");
                            } else {
                                selection.remove();
                            }
                        }

                    }
                };

                for (i = 0; i < series.length; ++i) {
                    s = series[i];
                    s.legendMin = unitFilter(s.min, s.axis.precision, s.series.unit, s.axis.scale);
                    s.legendMax = unitFilter(s.max, s.axis.precision, s.series.unit, s.axis.scale);
                    s.legendAvg = unitFilter(s.avg, s.axis.precision, s.series.unit, s.axis.scale);
                    s.legendLength = Math.max(s.legendMin.length, s.legendMax.length, s.legendAvg.length);
                    addLine('avg', s);
                    addLine('min', s);
                    addLine('max', s);
                    addLine('mark', s);
                }



                var items = legend.selectAll('g.legend-item').data(series);

                var nitems = items.enter().append('g').attr("class", "legend-item");

                nitems.append("circle").attr({
                    "r": 6
                });
                nitems.append("text").attr({
                    "class": "label",
                    "transform": "translate(13, 4)"
                });

                var m = nitems.append('g').attr({
                    "class": "measure measure-min",
                    "transform": "translate(13, 20)"
                });
                m.append("text").text("min: ");
                m.append("text").attr({
                    "class": "measure-value",
                    "text-anchor": "end"
                });

                m = nitems.append('g').attr({
                    "class": "measure measure-max",
                    "transform": "translate(13, 32)"
                });
                m.append("text").text("max: ");
                m.append("text").attr({
                    "class": "measure-value",
                    "text-anchor": "end"
                });

                m = nitems.append('g').attr({
                    "class": "measure measure-avg",
                    "transform": "translate(13, 44)"
                });
                m.append("text").text("avg: ");
                m.append("text").attr({
                    "class": "measure-value",
                    "text-anchor": "end"
                });

                nitems.on('click', function (d, i) {
                    d.visible = !d.visible;
                    chart.render();
                });

                items.classed("disabled", function (d, i) {
                    return !d.visible;
                });
                items.select("circle").style({
                    "fill": function (d) {
                        return d.series.lineColor;
                    }
                });
                items.select("text.label").text(function (d) {
                    return d.series.name;
                });

                var toggleCountedLines = function (type, data) {
                    d3.event.stopPropagation();
                    data[type+'On'] = !data[type+'On'];
                    toggleClass.call(this, type, data);
                };

                var toggleClass = function(type, data) {
                    var result;
                    if (d3.select(this).attr("class")){
                        if (data[type+'On']) {
                            if (d3.select(this).attr("class").indexOf('active') > -1) {
                                result = d3.select(this).attr("class");
                            } else {
                                result = d3.select(this).attr("class") + ' active';
                            }
                        } else {
                            result = d3.select(this).attr("class").indexOf('active') > -1 ? d3.select(this).attr("class").slice(0,d3.select(this).attr("class").indexOf('active') - 1) : d3.select(this).attr("class");
                        }
                        if (result) {
                            d3.select(this).attr("class", result);
                        }
                    }


                };

                items.select('g.measure-min').on("click", function(d, i) {
                    toggleCountedLines.call(this, 'min', d);
                    addLine('min', d)
                });
                items.select('g.measure-max').on("click", function(d, i) {
                    toggleCountedLines.call(this,'max', d);
                    addLine('max', d)
                });

                items.select('g.measure-avg').on("click", function(d, i) {
                    toggleCountedLines.call(this, 'avg', d);
                    addLine('avg', d);
                });


                items.select("g.measure-min .measure-value").text(function (d, i) {
                    return d.legendMin;
                }).attr({
                    "x": function (d, i) {
                        return 5 * d.legendLength + 30;
                    }
                });

                items.select("g.measure-max .measure-value").text(function (d, i) {
                    return d.legendMax;
                }).attr({
                    "x": function (d, i) {
                        return 5 * d.legendLength + 30;
                    },
                    "data-class": function(d, i) {
                        return d.maxOn ? 'active' : null;
                    }
                });

                items.select("g.measure-avg .measure-value").text(function (d, i) {
                    return d.legendAvg;
                }).attr({
                    "x": function (d, i) {
                        return 5 * d.legendLength + 30;
                    }
                });

                items.exit().remove();

                var lengths = [];
                lengths.push(options.margin.left + 6);
                for (i = 1; i < items[0].length; i++) {
                    lengths[i] = lengths[i - 1] + items[0][i - 1].getBBox().width + 20;
                }

                items.attr({
                    "transform": function (d, i) {
                        return "translate(" + lengths[i] + "," + (options.height - 50) + ")";
                    }
                });
            };

            this.options(opt);

            this.autoscroll = options.enableScroll === true;
        }

        return {
            restrict: 'A',
            scope: {
                options: '='
            },
            link: function (scope, elem, attrs) {
                scope.data = [];
                function redraw() {
                    if (!scope.redrawPromise) {
                        scope.redrawPromise = $timeout(function () {
                            scope.chart.options({
                                width: elem.parent().width(),
                                height: elem.parent().height()
                            });
                            scope.chart.update(scope.data);
                            delete scope.redrawPromise;
                        });
                    }
                }

                elem.addClass("line-chart");

                for (var i = 0; i < scope.options.series.length; ++i) {
                    scope.options.series[i].visible = true;
                }
                var callbacks = {
                    setTimeSpan: function (timeFrom, timeTo) {
                        scope.$emit(DashboardEvents.SET_DATA_TIMESPAN, timeFrom, timeTo);
                    }
                };

                // FIXME (kb) przeniesc tlo do css
                scope.chart = new LineChart(d3.select(elem[0]).append('svg').attr("class", "line-chart"),
                    scope.options, callbacks);

                scope.$on(DashboardEvents.REDRAW, function (event, data) {
                    if (data) {
                        scope.data = data;
                    }
                    redraw();
                });
                scope.$on(DashboardEvents.LOADING_DATA, function (event, isLoadingData) {
                    scope.chart.loadingData = isLoadingData;
                });

                scope.$on(DashboardEvents.RESIZE, function (event) {
                    // elem.css("display", "none");
                    redraw();
                });

                scope.$on('generateCsv', function (event) {
                    dashboardService.generateCsv(scope.data, scope.options.series);
                });

                scope.$on('generateXlsx', function (event) {
                    dashboardService.generateXlsx(scope.data, scope.options.series);
                });

                scope.$watch("options", function () {
                    scope.chart.options(scope.options);
                    // scope.chart.update(scope.data);
                });

                redraw();
            }
        };
    });
