<div x-mbsat2-gauge="" x-data="mbsat" x-options="options" x-widget-config="config" x-ng-if="options.series.length>0" class="mbsat-container"></div>
<div x-ng-show="options.series.length==0">
    <div class="row" style="height: 100%;">
        <div class="col-sm-6 col-sm-offset-3" style="padding-top: 20px;">
            <h1 style="text-align:center; padding-bottom:20px">{{'widget.noDeviceWithTag' |i18n}}: {{config.name}}</h1>
            <button class="btn btn-info btn-lg" x-ng-class="{'disabled' : !$parent.$parent.editMode}"
                    style="width : 100%;" x-ng-click="$parent.$parent.editMode ? $parent.$parent.edit() : null"
                    title="{{$parent.$parent.editMode ? ('widget.configure' | i18n) : ('widget.enable.editMode' | i18n)}}">
                {{'widget.configure'| i18n}} <i class="fa fa-cog"></i></button>
        </div>
    </div>
</div>
