<div class="row">
	<div class="panel panel-default" x-ng-if="!config.hideReportId">
		<div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
		<div class="panel-body">
			<form class="form form-horizontal">
				<div class="form-group">
					<label class="form-group-label" x-label-help="dashboard.widgets.config.report"></label>
					<select class="form-control" x-ng-model="ui.report" name="widgetsConfigReport" x-ng-options="report as report.label for report in reports"
							x-ng-change="ui.updateReport()" x-required="">
					</select>
				</div>
			</form>
		</div>
	</div>

	<div class="panel panel-default">
		<div class="panel-heading">{{'report.table.visibleColumns' | i18n}}</div>
		<div class="row">
			<div class="col-xs-6">
				<ul class="list-group">

					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.title')" data-ng-click="ui.setColumnVisibility('energyReport.title', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'config.param.label' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.device')" data-ng-click="ui.setColumnVisibility('energyReport.device', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'config.device.deviceName' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.descr1')" data-ng-click="ui.setColumnVisibility('energyReport.descr1', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'config.device.descr1' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.descr2')" data-ng-click="ui.setColumnVisibility('energyReport.descr2', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'config.device.descr2' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.descr3')" data-ng-click="ui.setColumnVisibility('energyReport.descr3', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'config.device.descr3' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.param')" data-ng-click="ui.setColumnVisibility('energyReport.param', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'report.table.param' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.paramDesc')" data-ng-click="ui.setColumnVisibility('energyReport.paramDesc', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'report.table.paramDesc' | i18n}}
							</label>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-xs-6">
				<ul class="list-group">
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.date')" data-ng-click="ui.setColumnVisibility('energyReport.date', 0)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'energyReport.dateColumn' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.value')" data-ng-click="ui.setColumnVisibility('energyReport.value', 1)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'energyReport.valueColumn' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.increase')" data-ng-click="ui.setColumnVisibility('energyReport.increase', 2)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'energyReport.increaseColumn' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.power')" data-ng-click="ui.setColumnVisibility('energyReport.power', 3)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'energyReport.powerColumn' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item" style="padding: 5px 20px;">
						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="ui.isChecked('energyReport.cost')" data-ng-click="ui.setColumnVisibility('energyReport.cost', 4)" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{'energyReport.costColumn' | i18n}}
							</label>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>


	<div class="panel panel-default">
		<div class="panel-heading">{{'dashboard.widgets.config.widget.settings' | i18n}}</div>
		<div class="panel-body">
			<form class="form form-horizontal">
				<div class="form-group">
					<label class="form-group-label" x-label-help="dashboard.widgets.config.columns"></label>
					<input type="number" class="form-control" name="widgetsConfigColumns" placeholder="{{'dashboard.widgets.config.columns' | i18n }}"
						   data-ng-model="config.columns" x-min="1" x-required="" />
				</div>
				<div class="form-group">
					<label class="form-group-label" x-label-help="dashboard.widgets.config.mode"></label>
					<select class="form-control" x-ng-model="config.mode" name="widgetsConfigReport" x-ng-options="mode.value as mode.label for mode in ui.modes"
							x-ng-change="ui.updateReport()" x-required="">
					</select>
				</div>
			</form>

			<ul class="list-group">
				<li class="list-group-item" x-ng-repeat="serie in config.series" style="border-width:0px; padding:10px 0px" x-ng-if="!serie.hide">
					<hr class="divider" style="margin-top: 10px;"></hr>
					<form class="form form-inline">

						<div class="checkbox">
							<label>
								<input type="checkbox" data-ng-checked="serie.display" data-ng-click="serie.display=!serie.display" style="margin-left:3px; margin-top:5px;">
								&nbsp;{{serie.device}} {{serie.param}} - {{serie.label||serie.name}}
							</label>
						</div>
						<div style="padding-top: 10px;" x-ng-if="config.mode>=2">
							<label class="form-group-label" x-label-help="dashboard.widgets.gauge.colorConfig" style="cursor: pointer">
										<span uib-popover="{{'dashboard.widgets.config.zone.copy' | i18n }}" popover-trigger="mouseenter" popover-placement="top">
											<i class="fa fa-files-o text-primary"  x-ng-click="ui.copyZones(serie.zones)" aria-hidden="true"></i>
										</span>
							</label>
							<div class="row" x-ng-repeat="zone in serie.zones" ng-init="serieIndex = $parent.$index">
								<div class="form-group col-sm-4">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.min"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.min' | i18n }}</label>
									<div class="input-group">
										<input class="form-control" name="widgetsConfigZoneMin-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.min' | i18n }}"
											   x-ng-model="zone.from"
											   x-required=""
											   data-scale-formatter=""
											   data-format-precision="serie.precision"
											   data-format-scale="serie.scale"
											   x-ng-change="ui.minmaxChange(serieIndex)"
											   data-validate="{'dashboard.widgets.config.error.minMax': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="serie.scale" data-unit-addon-unit = "serie.quantity | quantityUnitFilter"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-4">
									<label class="form-group-label">{{'dashboard.widgets.config.zone.max' | i18n }}</label>
									<div class="input-group">
										<input class="form-control" name="widgetsConfigZoneMax-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.max' | i18n }}"
											   x-ng-model="zone.to"
											   data-scale-formatter=""
											   data-format-scale="serie.scale"
											   data-format-precision="serie.precision"
											   x-required=""
											   x-ng-change="ui.minmaxChange(serieIndex)"
											   data-validate="{'dashboard.widgets.config.error.maxMin': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="serie.scale" data-unit-addon-unit="serie.quantity | quantityUnitFilter"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-2">
									<label class="form-group-label">{{'dashboard.widgets.config.zone.color' | i18n }}</label>
									<spectrum-colorpicker format="hex" x-ng-model="zone.color"
														  options="ui.colorPickerOpts || config.unit || {showInput : true} "/>
								</div>

								<div class="form-group col-sm-1">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-danger" x-ng-click="ui.removeZone(serieIndex, $index);"
											title="{{'ui.delete' | i18n}}">
										<i class="fa fa-trash-o fa-lg"></i>
									</button>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-sm-1 col-sm-offset-10">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-success" x-ng-click="ui.addZone($index);">
										<i class="fa fa-plus fa-lg"></i>
									</button>
								</div>
							</div>
						</div>

					</form>
				</li>
			</ul>
		</div>
	</div>
</div>

