var module = angular.module('meternet.dashboard.controllers.energyReport', [
	'adf.provider',
	'i18n'
]);

module.config(function(dashboardProvider, contextPath, messages) {
	var widget = {
		templateUrl: 'dashboard/energy-report-widget.html',
		title : messages['menu.report.energy'],
		description : messages['dashboard.widgets.energyReport.desc'],
		controller : 'energyReportWidgetCtrl',
		csv: true,
		xlsx: true,
		config: {
			type: "energy-report",
			reportId: null,
			series : [],
			columns: 4,
			mode: 1,
			testowanie: true
		},
		edit : {
			controller : 'energyReportWidgetEditCtrl',
			templateUrl: 'dashboard/energy-report-widget-edit.html'
		}
	};
	dashboardProvider.widget('energy-report', widget);
});

function EnergyReportEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts, i18nFilter, unitFilter) {

    $scope.ui = {
        booleans:[{"value":false,"label":"config.param.enabled.false"},{"value":true,"label":"config.param.enabled.true"}],
        colorPickerOpts : ColorPickerOpts,
        scales : [
            { value : -9,  label : "n" },
            { value : -6, label : "m" },
            { value : -6, label : "µ" },
            { value : 0, label : "-" },
            { value : 3, label : "k" },
            { value : 6, label : "M" },
            { value : 9, label : "G" }
        ],
        modes : [
            { value : 0,  label : i18nFilter('energyreport.mode.none') },
            { value : 1, label : i18nFilter('energyreport.mode.max') },
            { value : 2, label : i18nFilter('energyreport.mode.serie') },
            { value : 3, label : i18nFilter('energyreport.mode.progress') }
        ],
        setColumnVisibility: function(column, idx) {
            if (!$scope.config.visibleColumns) {
                $scope.config.visibleColumns = ['energyReport.title', 'energyReport.device', 'energyReport.descr1', 'energyReport.descr2', 'energyReport.descr3', 'energyReport.param', 'energyReport.paramDesc', 'energyReport.date', 'energyReport.value', 'energyReport.increase', 'energyReport.cost'];
            }
            //index of element which we want to remove. Idx means index which we want to put new element
            var index = $scope.config.visibleColumns.indexOf(column);
            if (index < 0) {
                $scope.config.visibleColumns.splice(idx, 0, column);
            } else {
                $scope.config.visibleColumns.splice(index, 1);
            }
        },
        isChecked: function(column) {

            if (!$scope.config.visibleColumns) {
//                $scope.config.visibleColumns = [];
                $scope.config.visibleColumns = ['energyReport.title', 'energyReport.device', 'energyReport.descr1', 'energyReport.descr2', 'energyReport.descr3', 'energyReport.param', 'energyReport.paramDesc', 'energyReport.date', 'energyReport.value', 'energyReport.increase', 'energyReport.cost'];
            }
            if ($scope.config.visibleColumns.indexOf(column) < 0) {
                return false;
            }
            return true;
        },
        precisions : [ 0, 1, 2, 3, 4, 5, 6 ],
        updateReport : function() {
            $scope.config.reportId = $scope.ui.report ? $scope.ui.report.id : null;
            prepareSeries();
        },
        refreshSerie : function(serie) {
            var ener_param = _.find($scope.ui.report.params, function (param) {
                return param.id === serie.paramId;
            });
            if(ener_param){
                serie.name = ener_param.label || ener_param.name;
                serie.precision = ener_param.precision;
                serie.quantity = ener_param.quantity;
                serie.scale = ener_param.scale;
            }
        },
        addZone : function(serieIndex) {
            var serie = $scope.config.series[serieIndex];
            if(!serie.zones){
                serie.zones = [];
            }
            var zonesLength = serie.zones.length;

            var min = zonesLength > 0 ? serie.zones[zonesLength - 1].to : 0;
            serie.zones.push({
                "color" : "#ffffff",
                "from" : min,
                "to" : min + 10
            });
        },
        removeZone : function(serieIndex, index) {
            $scope.config.series[serieIndex].zones.splice(index, 1);
        },
        copyZones : function(zones){
            _.forEach($scope.config.series, function(serie){
                if(serie.markToEdit){
                    serie.zones = tools.copy(zones);
                }
            });
        },
        getParamUnit: function(param){
            return unitFilter(1, 0, $filter('quantityUnitFilter')(param.quantity),param.scale).substring(2);
        }
    };

    $scope.config = config;
    configService.get().then(function(meternetConfig){



       var allParams = []
        for (var i = 0; i < meternetConfig.engine.measurementInputs.length; ++i) {
            var input = meternetConfig.engine.measurementInputs[i];
            for (var d = 0; d < input.devices.length; ++d) {
                var device = input.devices[d];
                for (var p = 0; p < device.params.length; ++p) {
                    allParams.push(device.params[p]);
                }
            }
        }
        for (var i = 0; i < meternetConfig.engine.moduleInputs.length; ++i) {
            var input = meternetConfig.engine.moduleInputs[i];
            for (var d = 0; d < input.devices.length; ++d) {
                var device = input.devices[d];
                for (var p = 0; p < device.params.length; ++p) {
                    allParams.push(device.params[p]);
                }
            }
        }
        $scope.reports = _.flatten(_.pluck(_.filter(meternetConfig.engine.moduleInputs, function (i) { return i.type==='energy-report'}), 'devices'));
        _.forEach($scope.reports, function(report){
            _.forEach(report.params, function(p){
                var param = _.findWhere(allParams, {id: p.paramId});
                if(param){
                    p.unit = param.unit;
                    p.quantity = param.quantity;
                    p.scale = param.scale;
                    p.precision = param.precision;
                }
            })
            if (!report.label){
                report.label = report.name;
            }
        });
        $scope.devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true));
        $scope.ui.report = _.find($scope.reports, function(report){
            return report.id === $scope.config.reportId;
        });
        $scope.ui.updateReport();
    });



    function prepareSeries () {
        if ($scope.ui.report) {
            $scope.config.series.forEach(function(serie) {
                serie.markToDelete=true;
            });

            $scope.ui.report.params.forEach(function(reportParameter) {
                var data = {
                    deviceId: _.find($scope.devices, function (device) {
                        return _.find(device.params, function (param) {
                                return param.id === reportParameter.id;
                            }) != null;
                    }).id,
                    paramId: reportParameter.id,
                    name: reportParameter.label || reportParameter.name,
                    enabled: true,
                    markToDelete: false,
                    display: reportParameter.display,
                    cost: reportParameter.cost,
                    costUnit: reportParameter.costUnit,
                    hide: !!reportParameter.master
                };
                var widgetParam = _.find($scope.config.series, function(s) {
                    return s.paramId === data.paramId;
                });
                if (!widgetParam) {
                    if(!$scope.config.series){
                        $scope.config.series = [];
                    }
                    $scope.config.series.push(data);
                }else{
                    widgetParam.name = reportParameter.label || reportParameter.name;
                    widgetParam.precision = reportParameter.precision;
                    widgetParam.quantity = reportParameter.quantity;
                    widgetParam.scale = reportParameter.scale;
                    widgetParam.markToDelete=false;
                    widgetParam.display = reportParameter.display;
                    widgetParam.cost = reportParameter.cost;
                    widgetParam.costUnit = reportParameter.costUnit;
                    widgetParam.hide = !!reportParameter.master;
                }
            });
        }
        $scope.config.series = _.filter($scope.config.series, function(serie){ return !serie.markToDelete });
    }

}

function EnergyReportCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents,
        seriesService, $q) {
    $scope.ui = {};
    if (!config.reportId){
        return;
    }

    configService.get().then(function(meternetConfig){
        var devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices'));
        var report = _.find(devices, function(device){
           return device.id === config.reportId;
        });
        $scope.report = angular.extend({}, report, config);
    });
}

module.controller('energyReportWidgetEditCtrl', EnergyReportEditCtrl);
module.controller('energyReportWidgetCtrl', EnergyReportCtrl);
