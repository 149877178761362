<div style="display: inline-block; width :100%">
    <table style="width: 100%;">
        <tr>
            <td>
                <ui-select class="" ng-model="_chooser.device" theme="bootstrap" ng-disabled="ctrl.disabled"
                           style="width: 130px; display: inline-block;">
                    <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}"
                                     popover-trigger="mouseenter" popover-placement="right"
                                     uib-popover="{{$select.selected.label || $select.selected.name}}">
                        {{$select.selected.label || $select.selected.name}}
                    </ui-select-match>
                    <!--<ui-select-choices repeat="device in _chooser.devices | propsFilter: {name: $select.search, label: $select.search}" style="width: 130px; min-width: 130px;">-->
                    <!--<div ng-bind-html="(device.label||device.name) | highlight: $select.search"></div>-->
                    <!--</ui-select-choices>-->
                    <ui-select-choices
                            repeat="device in _chooser.devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}"
                            style="width: 300px; min-width: 300px;">
                        <div ng-bind-html="((device.label||device.name)) | highlight: $select.search"></div>
                        <div class="ui-select-choices-descr" x-ng-if="device.desc"><b>1:</b> {{device.desc}}</div>
                        <div class="ui-select-choices-descr" x-ng-if="device.desc2"><b>2:</b> {{device.desc2}}</div>
                        <div class="ui-select-choices-descr" x-ng-if="device.desc3"><b>3:</b> {{device.desc3}}</div>
                    </ui-select-choices>
                </ui-select>

                <ui-select x-ng-if="!onlyEnergy" class="" ng-model="_chooser.param" theme="bootstrap" ng-disabled="ctrl.disabled"
                           style="width: 130px; display: inline-block;">
                    <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}"
                                     popover-trigger="mouseenter" popover-placement="right"
                                     uib-popover="{{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]">
                        {{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]
                    </ui-select-match>
                    <ui-select-choices
                            repeat="param in _chooser.device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}"
                            style="width: 130px; min-width: 130px;">
                        <div ng-bind-html="((param.label||param.name) + ' [' + param.unit + ']'  )| highlight: $select.search"></div>
                        <div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
                    </ui-select-choices>
                </ui-select>

                <ui-select x-ng-if="onlyEnergy" class="" ng-model="_chooser.param" theme="bootstrap" ng-disabled="ctrl.disabled"
                           style="width: 130px; display: inline-block;">
                    <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}"
                                     style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
                        {{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]
                    </ui-select-match>
                    <ui-select-choices
                            repeat="param in _chooser.device.params | propsFilterOnlyEnergy: {name: $select.search, label: $select.search, desc: $select.search}"
                            style="width: 130px; min-width: 130px;">
                        <div ng-bind-html="((param.label||param.name) + ' [' + param.unit + ']'  )| highlight: $select.search"></div>
                        <div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
                    </ui-select-choices>
                </ui-select>

                <div repeat="param in _chooser.device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, quantity: 'energy'}">
                    {{param}}
                </div>
            </td>
            <td>
                <button x-ng-if="!hideHelp" type="button" uib-popover="{{'config.device.param.choice.help' | i18n}}"
                        popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary pull-right">?
                </button>
            </td>
        </tr>
    </table>

</div>
