var module = angular.module('meternet.dashboard.controllers.indicatorGaugeWidget', ['adf.provider']);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/indicator-gauge-widget.html',
        title : messages['dashboard.widgets.indicatorGauge.title'],
        description : messages['dashboard.widgets.indicatorGauge.desc'],
        controller : 'indicatorWidgetCtrl',
        screenshot: true,
        config : {
            type : "indicator",
            layout : true,
            series : [ {
                paramId : null,
                onName : "ON",
                offName : "OFF",
            } ]
        },
        edit : {
            controller : 'indicatorWidgetEditCtrl',
            templateUrl: 'dashboard/indicator-gauge-edit.html',
            resolve : {
                maxSeries : [ 'licenseService', function(licenseService) {
                    var license = licenseService.getLicense();
                    return license.properties.MAX_SERIES || 5;
                } ]
            }
        }
    };
    dashboardProvider.widget('indicator', widget);
});


function IndicatorEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts, maxSeries) {

    $scope.writableParams=[]

//    configService.get().then(function(meternetConfig){
//        for (var i = 0; i < meternetConfig.engine.measurementInputs.length; ++i) {
//            var input = meternetConfig.engine.measurementInputs[i];
//            for (var d = 0; d < input.devices.length; ++d) {
//                var device = input.devices[d];
//                for (var p = 0; p < device.params.length; ++p) {
//                    var param = device.params[p];
//                    if (param.writable) {
//                        $scope.writableParams.push({
//                            id: param.id,
//                            name: (device.label?device.label:device.name) + ': ' + (param.label?param.label:param.name)
//                        });
//                    }
//                }
//            }
//        }
//    });


    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
        $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "prepaid"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "alarm"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "control"}));

        $scope.devices = _.sortBy($scope.devices, function(d){
            return d.label||d.name;
        });

        var checkParam = function(param) {
            return this.paramId === param.id;
        };

        var checkDevice = function(device) {
            return this.deviceId === device.id;
        };

        for (var i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            series._device = _.find($scope.devices, checkDevice, series);
            if (series._device) {
                series._param = _.find(series._device.params, checkParam, series);
            }
        }

    });

//    $scope.updateAxisIndices = function(serie){
//        var name = _.findWhere($scope.writableParams, {id:serie.paramId}).name;
//        serie.name=name;
//    }

    $scope.ui={
        addSeries : function() {
            if (this.canAddSeries()) {
                var series = {
                     paramId : null,
                     onName : "ON",
                     offName : "OFF",
                };
                $scope.config.series.push(series);
            }
        },
        removeSerie : function(index) {
            $scope.config.series.splice(index, 1);
        },
        canAddSeries : function() {
            return $scope.config.series.length < maxSeries;
        },
        updateSeriesParam : function(series) {
            if (series.deviceId !== series._device.id) {
                series._param = series._device.params[0];
            }
            if (series._device && series._param) {
                series.deviceId = series._device.id;
                series.paramId = series._param.id;
                series.scale = series._param.scale || 0;
                series.precision = series._param.precision || 0;

                var deviceName = (series._device.label||series._device.name);
                var paramName = (series._param.label||series._param.name);
                series.name = deviceName + '.' + paramName;

                if (!series.derivative) {
                    series.unit=$filter('quantityUnitFilter')(series._param.quantity);
                } else {
                    series.unit = seriesService.getTimeDerivativeUnit($filter('quantityUnitFilter')(series._param.quantity), series.derivative);
                }
            } else {
                series.deviceId = null;
                series.paramId = null;
                series.name = null;
                series.unit = null;
            }
        }
    }
}

function IndicatorCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents,
        seriesService, $q) {
        $scope.ui = {};
}

module.controller('indicatorWidgetEditCtrl', IndicatorEditCtrl);
module.controller('indicatorWidgetCtrl', IndicatorCtrl);