var module = angular.module('meternet.dashboard.controllers.energyChart', [
	'adf.provider',
	'i18n'
]);

module.config(function(dashboardProvider, contextPath, messages) {
	var widget = {
		templateUrl: 'dashboard/energy-chart-widget.html',
		title : messages['dashboard.widgets.energyChart'],
		description : messages['dashboard.widgets.energyChartdesc'],
		controller : 'energyChartWidgetCtrl',
		csv: false,
		xlsx: false,
		config: {
			type: "energy-chart",
			reportId: null,
			series : [],
			columns: 4,
			mode: 1,
			visibleColumns: ['energyReport.increase']
		},
		edit : {
			controller : 'energyChartWidgetEditCtrl',
			templateUrl: 'dashboard/energy-chart-widget-edit.html'
		}
	};
	dashboardProvider.widget('energy-chart', widget);
});

function EnergyChartEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts, i18nFilter, unitFilter) {

    $scope.ui = {
        booleans:[{"value":false,"label":"config.param.enabled.false"},{"value":true,"label":"config.param.enabled.true"}],
        colorPickerOpts : ColorPickerOpts,
        scales : [
            { value : -9,  label : "n" },
            { value : -6, label : "m" },
            { value : -6, label : "µ" },
            { value : 0, label : "-" },
            { value : 3, label : "k" },
            { value : 6, label : "M" },
            { value : 9, label : "G" }
        ],
        modes : [
            { value : 0,  label : i18nFilter('energychart.mode.horizontal') },
            { value : 1, label : i18nFilter('energychart.mode.vertical') }
        ],
        serieModes : [
            { value : 'energyReport.increase',  label : i18nFilter('energyReport.increase') },
            { value : 'energyReport.power', label : i18nFilter('energyReport.power') },
            { value : 'energyReport.cost', label : i18nFilter('energyReport.cost') }
        ],
        visibilites : [
            { value:true, label:i18nFilter('visibleOn')},
            { value:false, label:i18nFilter('visibleOff')}
        ],
        precisions : [ 0, 1, 2, 3, 4, 5, 6 ],
        updateReport : function() {
            if($scope.config.mode===0){
                var serieToDisplay = false;
                _.forEach($scope.config.series, function(s){
                    if(s.display && serieToDisplay){
                        s.display = false;
                    }
                    if(s.display){
                        serieToDisplay = true;
                    }
                })
                if(!serieToDisplay){
                    $scope.config.series[0].display=true;
                }
            }
            $scope.config.reportId = $scope.ui.report ? $scope.ui.report.id : null;
            $scope.config.visibleColumns = [$scope.config.visibleColumn || 'energyReport.increase']
			$scope.config.visibleColumns.push('energyReport.date')
            prepareSeries();
        },
        refreshSerie : function(serie) {
            var ener_param = _.find($scope.ui.report.params, function (param) {
                return param.id === serie.paramId;
            });
            if(ener_param){
                serie.name = ener_param.label || ener_param.name;
                serie.precision = ener_param.precision;
                serie.quantity = ener_param.quantity;
                serie.scale = ener_param.scale;
            }
        },
        serieToggle : function(serie){
            _.forEach($scope.config.series, function(s){
                s.display=false;
            })
            serie.display=true;
        },
        getParamUnit: function(param){
            return unitFilter(1, 0, $filter('quantityUnitFilter')(param.quantity),param.scale).substring(2);
        }
    };

    $scope.config = config;
    configService.get().then(function(meternetConfig){

    $scope.config.visibleColumn = $scope.config.visibleColumn || $scope.config.visibleColumns[0];

       var allParams = []
        for (var i = 0; i < meternetConfig.engine.measurementInputs.length; ++i) {
            var input = meternetConfig.engine.measurementInputs[i];
            for (var d = 0; d < input.devices.length; ++d) {
                var device = input.devices[d];
                for (var p = 0; p < device.params.length; ++p) {
                    allParams.push(device.params[p]);
                }
            }
        }
        for (var i = 0; i < meternetConfig.engine.moduleInputs.length; ++i) {
            var input = meternetConfig.engine.moduleInputs[i];
            for (var d = 0; d < input.devices.length; ++d) {
                var device = input.devices[d];
                for (var p = 0; p < device.params.length; ++p) {
                    allParams.push(device.params[p]);
                }
            }
        }
        $scope.reports = _.flatten(_.pluck(_.filter(meternetConfig.engine.moduleInputs, function (i) { return i.type==='energy-report'}), 'devices'));
        _.forEach($scope.reports, function(report){
            _.forEach(report.params, function(p){
                var param = _.findWhere(allParams, {id: p.paramId});
                if(param){
                    p.unit = param.unit;
                    p.quantity = param.quantity;
                    p.scale = param.scale;
                    p.precision = param.precision;
                }
            })
            if (!report.label){
                report.label = report.name;
            }
        });
        $scope.devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true));
        $scope.ui.report = _.find($scope.reports, function(report){
            return report.id === $scope.config.reportId;
        });
        $scope.ui.updateReport();
    });



    function prepareSeries () {
        if ($scope.ui.report) {
            $scope.config.series.forEach(function(serie) {
                serie.markToDelete=true;
            });

            $scope.ui.report.params.forEach(function(reportParameter) {
                var data = {
                    deviceId: _.find($scope.devices, function (device) {
                        return _.find(device.params, function (param) {
                                return param.id === reportParameter.id;
                            }) != null;
                    }).id,
                    paramId: reportParameter.id,
                    name: reportParameter.label || reportParameter.name,
                    enabled: true,
                    markToDelete: false,
                    display: reportParameter.display,
                    cost: reportParameter.cost,
                    costUnit: reportParameter.costUnit
                };
                var widgetParam = _.find($scope.config.series, function(s) {
                    return s.paramId === data.paramId;
                });
                if (!widgetParam) {
                    if(!$scope.config.series){
                        $scope.config.series = [];
                    }
                    $scope.config.series.push(data);
                }else{
                    widgetParam.name = reportParameter.label || reportParameter.name;
                    widgetParam.precision = reportParameter.precision;
                    widgetParam.quantity = reportParameter.quantity;
                    widgetParam.scale = reportParameter.scale;
                    widgetParam.markToDelete=false;
                    widgetParam.cost = reportParameter.cost;
                    widgetParam.costUnit = reportParameter.costUnit;
                }
            });
        }
        $scope.config.series = _.filter($scope.config.series, function(serie){ return !serie.markToDelete });
    }

}

function EnergyChartCtrl($scope, $filter, $q, $timeout, contextPath, dataService, config, configService, DashboardEvents, seriesService, unitFilter, dateFilter) {

    $scope.dates=[];
    $scope.ui = {
        chartData : [],
        setModalData : function(){
            var rawData = $scope.table;
            if(rawData.data.length>0){
                var col = rawData.data[0].length-1;
                var serie = $scope.report.series;

                $scope.ui.chartUnit = rawData.data[0][col].unit;
                $scope.ui.chartTitle = $filter('i18n')(rawData.subcols[col].label).toUpperCase() ;
                _.forEach(rawData.data, function(d,i){
                    var value = {};
                    value.value = d[col].value;
                    value.error = typeof d[col].value == 'number' ? 0 : 1;
                    value.timestamp = serie[i].device + " " + serie[i].param + " (" + serie[i].name + ")"
                    $scope.ui.chartData.push(value);
                });
            }
        },
        setModalParam : function() {
            var seriesIndex = 0;
            if($scope.table.data[seriesIndex]){
                var table = $scope.table;
                var col = $scope.table.data[seriesIndex].length-1;
                var serie = $scope.report.series;
                var rawData = table.data[seriesIndex];

                var length = rawData.length;
                $scope.ui.chartData = [];
                $scope.ui.chartUnit = rawData[col].unit;
                $scope.ui.chartTitle = serie.device + " " + serie.param + " (" + serie.name +") - " + $filter('i18n')(table.subcols[col].label).toUpperCase() ;

                for(var i=col%table.colNum; i<length; i+=table.colNum){
                    $scope.ui.chartData.push({
						scheduled: rawData[i].scheduled,
                        timestamp:moment(new Date(rawData[i].timestamp)).format("YYYY-MM-DD HH:mm"),
                        value:rawData[i].value,
                        error:0,
                        valueStart: rawData[i].value1,
                        valueEnd: rawData[i].value2,
                        dateStart: rawData[i].timestamp,
                        dateEnd: rawData[i].timestamp2,
                        showBuble: table.subcols[col].label==='energyReport.increase'
                    })
                }
            }

        }
    }

    if (!config.reportId){
        return;
    }

    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        $scope.devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
    });

    configService.get().then(function(meternetConfig){
        var devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices'));
        var report = _.find(devices, function(device){
           return device.id === config.reportId;
        });
        $scope.report = angular.extend({}, report, config);

        updateData();
    });


    function updateData(date) {
    }

    function updateTable(resp) {
        var i, j;

        var table = {
            cols: [],
            subcols: [],
            data: []
        };
        _.forEach(resp.data, function(d){
            d.values = _.chain(d.values).reverse().value();
        })

        for (i = 1; i < $scope.config.columns+1; ++i) {
            var colwidth = 70 / $scope.config.columns;
            var stime1 = (resp.data.length > i) ? new Date(resp.data[i].scheduledTimestamp) : null;
            var stime2 = (resp.data.length > i - 1) ? new Date(resp.data[i-1].scheduledTimestamp) : null;
            var sublabels = ["energyReport.increase", "energyReport.power", "energyReport.cost"];
            var colNum = 0

            for(var k=0; k<sublabels.length; k++){
                if($scope.config.visibleColumns.indexOf(sublabels[k])>=0){
                    colNum ++;
                }
            }
            table.colNum = colNum;
            for (var j = 0; j < sublabels.length; j++) {
                if($scope.config.visibleColumns.indexOf(sublabels[j])>-1){
                    table.subcols.push({
                        label: sublabels[j],
                        style: {
                            "width": (colwidth / table.colNum) + "%",
                            "text-align": "center"
                        }
                    });
                }
            }
        }
        table.cols.reverse();

        var checkParamId = function (v) {
            return v.paramId === this;
        };
        for (i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            var params = _.flatten(_.pluck($scope.devices, 'params'), true);

            var ener_param = _.find(params, function (param) {
                return param.id === series.paramId;
            });

            if(ener_param){
                var param = _.find(params, function (param) {
                    return param.id === ener_param.paramId;
                });

                var device = _.find($scope.devices, function (device) {
                    return _.find(device.params, function(param){
                        return param.id == ener_param.paramId;
                    });
                });
                series.name = series.name ? series.name : device.label ? device.label : device.name;
                series.device = device.label ? device.label : device.name;
                series.param = param.label ? param.label : param.name;
                series.precision = param.precision;
                series.scale = param.scale;
                series.quantity = param.quantity;
                series.cost = ener_param.cost;
                series.costUnit = ener_param.costUnit;

                var d = [];
                var getIncrease = $scope.config.visibleColumns.indexOf('energyReport.increase') >= 0;
                var getPower = $scope.config.visibleColumns.indexOf('energyReport.power') >= 0;
                var getCost = $scope.config.visibleColumns.indexOf('energyReport.cost') >= 0;

                for (j = 0; j < $scope.config.columns; j++) {
                    var s1;
                    var s2;

                    if (resp.data.length > j) {
                        s1 = _.find(resp.data[j].values, checkParamId, series.paramId) || {};
                    }
                    if (resp.data.length > j + 1) {
                        s2 = _.find(resp.data[j + 1].values, checkParamId, series.paramId) || {};
                    }
                    if (getCost) {
                        d.push({
							scheduled: resp.data[j].previousScheduledTimestamp,
                            label: s1.value != null && s2.value != null ? unitFilter((s1.value - s2.value)*series.cost, 2, series.costUnit, 0) :"-",
                            value: s1.value != null && s2.value != null ? (s1.value - s2.value)*series.cost :0,
                            timestamp: s1.timestamp,
                            timestamp2: s2.timestamp,
                            unit: {
                                quantity: series.costUnit,
                                precision: 2,
                                scale: 0
                            }
                        });
                    }
                    if (getPower) {
                        var powerUnit = seriesService.getTimeDerivativeUnit($filter('quantityUnitFilter')(series.quantity), 3600000);
                        d.push({
							scheduled: resp.data[j].previousScheduledTimestamp,
                            label: s1.value != null && s2.value != null ? unitFilter(3600000*(s1.value - s2.value)/(s1.timestamp - s2.timestamp), series.precision, powerUnit, series.scale) :"-",
                            value: s1.value != null && s2.value != null ? 3600000*(s1.value - s2.value)/(s1.timestamp - s2.timestamp):0,
                            timestamp: s1.timestamp,
                            timestamp2: s2.timestamp,
                            unit: {
                                quantity: powerUnit,
                                precision: series.precision,
                                scale: series.scale
                            }
                        });
                    }
                    if (getIncrease) {
                        d.push({
							scheduled: resp.data[j].previousScheduledTimestamp,
                            label: s1.value != null && s2.value != null ? unitFilter(s1.value - s2.value, series.precision, $filter('quantityUnitFilter')(series.quantity), series.scale) : "-",
                            value: s1.value != null && s2.value != null ? (s1.value - s2.value) :0,
                            value1: s1.value,
                            value2: s2.value,
                            timestamp: s1.timestamp,
                            timestamp2: s2.timestamp,
                            unit: {
                                quantity: $filter('quantityUnitFilter')(series.quantity),
                                precision: series.precision,
                                scale: series.scale
                            },
                        });
                    }
                }
                d.reverse();
                if(series.display){
                    table.data.push(d);
                }
            }else{
                series.display = false;
            }
            $scope.table = table;
            if($scope.config.mode){
                $scope.ui.setModalData();
            }else{
                $scope.ui.setModalParam();
            }
        }
    }

    $scope.subscribe = true;

    var updateDataFactory = function(index) {
        var updateData = function(resp, concat) {
            if(resp.constructor.name !== 'Array'){
                var i, d;
                if (resp.data) {
                    resp.data.reverse();
                } else {
                    resp.data = [];
                }

                $scope.date = new Date(resp.data[0].scheduledTimestamp);
                var checkDate = function(date) {
                    //TK this - date passed as underscore context
                    return date.getTime() === this.getTime();
                };
                for (i = 0; i < resp.data.length; i++) {
                    d = new Date(resp.data[i].scheduledTimestamp);
                    if (_.find($scope.dates, checkDate, d) === undefined) {
                        $scope.dates.push(d);
                    }
                }
                $scope.dates.sort();
                $scope.data = resp;
                updateTable(resp);
            }else{
                var newScheduledTimestamp = new Date(new Date($scope.data.data[0].scheduledTimestamp).getTime() + (new Date($scope.data.data[0].scheduledTimestamp).getTime() - new Date($scope.data.data[1].scheduledTimestamp)))

                if(new Date($scope.data.data[0].scheduledTimestamp) !== newScheduledTimestamp && (Math.abs(new Date().getTime() - newScheduledTimestamp) > 30000)){
                    var newData = {
                        scheduledTimestamp: newScheduledTimestamp,
                        previousScheduledTimestamp: $scope.data.data[0].scheduledTimestamp,
                        values: []
                    };
                    $scope.data.data.unshift(newData);
                    $scope.data.data.pop();
                }
                $scope.data.data[0].values[index]=resp[0];
                var count = 0
                _.forEach($scope.data.data[0].values, function(v){
                    if(v){
                        count++;
                    }
                })
                if(count===3){
                    updateTable($scope.data);
                }
            }
        }
        return _.throttle(updateData, 500);
    }


    $scope.unsubscribeFns = {};
    $scope.$on('$destroy', function() {
        _.each($scope.unsubscribeFns, function(fn) {
           fn();
        });
    });

    $scope.promises = [];
    var initData = function() {
        $scope.$broadcast(DashboardEvents.LOADING_DATA, true);
        _.each($scope.promises, function(p) {
            p.cancel();
        });
        $scope.promises = [];

        _.each(config.series, function(series, index) {
            var request = {};
            request.deviceId = series.deviceId;
            request.paramId = series.paramId;
            request.timeTo = new Date();
            request.timeFrom = new Date();
            var promise = dataService.getEnergyReportData($scope.config.reportId, new Date(), $scope.config.columns + 1)
            promise.deviceId = series.deviceId;
            promise.paramId = series.paramId;
            var updateDataFn = updateDataFactory(index);
            promise.then(function(measurements) {
                updateDataFn(measurements, false);
            }, angular.noop, function(measurements) {
                updateDataFn(measurements, false);
            });
            $scope.promises.push(promise);
        });

        $scope.$on('$destroy', function() {
            _.each($scope.promises, function(p) {
                p.cancel();
            });
        });
        $q.all($scope.promises).then(function(measurements) {
            if (!$scope.subscribe) {
                _.each($scope.unsubscribeFns, function(fn) {
                    fn();
                });
                $scope.unsubscribeFns = {};
            } else {
                _.forEach($scope.promises, function(p, i) {
                    if ($scope.subscribe && typeof ($scope.unsubscribeFns[p.paramId]) === 'undefined') {
                        var unsubscribeFn = dataService.subscribeForParametersMeasurements(p.paramId, function(moment) {
                           var data = [];
                           data.push(moment.current);
                           updateDataFactory(i)(data, true);
                        });
                        $scope.unsubscribeFns[p.paramId] = unsubscribeFn;
                    }

                });
            }
            $timeout(function() {
                $scope.$broadcast(DashboardEvents.LOADING_DATA, false);
            }, 0);
        });
    }
    initData();
}

module.controller('energyChartWidgetEditCtrl', EnergyChartEditCtrl);
module.controller('energyChartWidgetCtrl', EnergyChartCtrl);
