/* globals moment */
var module = angular.module("meternet.report.energy.EnergyReportTableDirective", [
              'meternet.report.directives.EnergyReportChartWidgetDirective']);

module.directive("energyReportTable", function($filter, contextPath, configService, dataService, unitFilter, dateFilter, seriesService, $http) {
    return {
        scope : {
            config : '=energyReportTable'
        },
        templateUrl: 'report/energy/energy-report-table.html',
        link : function(scope, elem, attr, ctrl) {
            scope.dates=[];
            scope.ui = {
                chartData : [],
                setModalData : function(rawData, serie, col){
                    scope.ui.small=false;
                    scope.ui.stateModalParam.showPrevNext = false;
                    scope.ui.chartData = [];
                    scope.ui.chartUnit = rawData.data[0][col].unit;
                    scope.ui.chartTitle = $filter('i18n')(rawData.subcols[col].label).toUpperCase() ;
                    _.forEach(rawData.data, function(d,i){
                        var value = {};
                        value.value = d[col].value;
                        value.error = typeof d[col].value == 'number' ? 0 : 1;
                        value.timestamp = serie[i].device + " " + serie[i].param + " (" + serie[i].name + ")"
                        scope.ui.chartData.push(value);
                    });
                },
                stateModalParam:{
                    seriesIndex:null,
                    serie: null,
                    col:null,
                    table:null
                },
                setModalParam : function(seriesIndex, serie, col, table) {
                    if(seriesIndex>=0){
                        var rawData = table.data[seriesIndex]
                        scope.ui.stateModalParam = {
                            seriesIndex:seriesIndex,
                            serie: serie,
                            col:col,
                            table:table,
                            reload: false,
                            showPrevNext:true
                        }
                        var length = rawData.length;
                        scope.ui.small=true;
                        scope.ui.chartData = [];
                        scope.ui.chartUnit = rawData[col].unit;
                        scope.ui.chartTitle = serie.device + " " + serie.param + " (" + serie.name +") - " + $filter('i18n')(table.subcols[col].label).toUpperCase() ;
                        for(var i=col%table.colNum; i<length; i+=table.colNum){
                            scope.ui.chartData.push({
                                timestamp:moment(new Date(rawData[i].timestamp)).format("YYYY-MM-DD HH:mm"),
                                value:rawData[i].value,
                                error:0,
                                valueStart: rawData[i].value1,
                                valueEnd: rawData[i].value2,
                                dateStart: rawData[i].timestamp1,
                                dateEnd: rawData[i].timestamp2,
                                showBuble: table.subcols[col].label==='energyReport.increase'
                            })
                        }
                    }
                },
                setModalParamFromState: function(){
                    scope.ui.setModalParam(scope.ui.stateModalParam.seriesIndex, scope.ui.stateModalParam.serie, scope.ui.stateModalParam.col, scope.table)
                },
                getStepDate : function(inc) {
                    var date = null;
                    if (inc < 0) {
                        date = _.max(_.filter(scope.dates, function(d) {
                            return d.getTime() < scope.date.getTime();
                        }), function(d) {
                            return d.getTime();
                        });
                    } else {
                        date = _.min(_.filter(scope.dates, function(d) {
                            return d.getTime() > scope.date.getTime();
                        }), function(d) {
                            return d.getTime();
                        });
                    }
                    if (date === Infinity || date === -Infinity) {
                        date = null;
                    }
                    return date;
                },
                setStepDate : function(date) {
                    if (date) {
                        updateData(date);
                    }
                },
                canStep : function(inc) {
                    return this.getStepDate(inc) != null;
                },
                step : function(inc) {
//                    var date = this.getStepDate(inc);
                    var diff = scope.dates[0]-scope.dates[1];
                    var date = scope.date.getTime()-inc*diff
                    if (date) {
                        updateData(date);
                        scope.ui.stateModalParam.reload = true;
                    }
                },
                canDownloadCsv : function() {
                    return scope.data != null;
                },
                canDownloadXlsx : function() {
                    return scope.data != null;
                },
                updatingData : false
            };

            function formatRange(t1, t2) {
                function pad(value, n) {
                    value = "" + value;
                    while (value.length < n) {
                        value = "0" + value;
                    }
                    return value;
                }

                if (!t1 || !t2) {
                    return "-";
                }

                var y1 = t1.getFullYear();
                var y2 = t2.getFullYear();
                var m1 = pad(t1.getMonth() + 1, 2);
                var m2 = pad(t2.getMonth() + 1, 2);
                var d1 = pad(t1.getDate(), 2);
                var d2 = pad(t2.getDate(), 2);
                var hh1 = t1.getHours();
                var hh2 = t2.getHours();
                var mm1 = pad(t1.getMinutes(), 2);
                var mm2 = pad(t2.getMinutes(), 2);
                var dayPart = function(day, month, year){
                    return day + "." + month + "." + year;
                };

                var hoursPart = function(minutes, hours){
                    return  hours + ":" + minutes;
                };
                if (y1 !== y2) {
                    if (hh1 !== hh2 || mm1 !== mm2) {
                       return hoursPart(mm1, hh1)+" "+dayPart(d1, m1, y1) + "-"+hoursPart(mm2, hh2)+" "+dayPart(d2, m2, y2);
                    } else {
                        return dayPart(d1, m1, y1) + "-"+dayPart(d2, m2, y2);
                    }
                } else if (m1 !== m2) {
                    if (hh1 !== hh2 || mm1 !== mm2) {
                        return hoursPart(mm1, hh1)+" "+dayPart(d1, m1, y1) + "-"+hoursPart(mm2, hh2)+" "+dayPart(d2, m2, y2);
                    } else {
                        return d1 + "." + m1 + "-" +dayPart(d2, m2, y2);
                    }
                } else if (d1 !== d2) {
                    if (hh1 !== hh2 || mm1 !== mm2) {
                        return hoursPart(mm1, hh1)+" "+dayPart(d1, m1, y1) + "-"+hoursPart(mm2, hh2)+" "+dayPart(d2, m2, y2);
                    } else {
                        return d1 + "-" +dayPart(d2, m2, y2);
                    }
                } else {
                    if (hh1 !== hh2 || mm1 !== mm2) {
                        return hh1 + ":" + mm1 + "-" + hh2 + ":" + mm2 + " " +dayPart(d2, m2, y2);
                    } else {
                        return dayPart(d1, m1, y1);
                    }
                }
            }

            function updateTable(resp) {
                var i, j;

                var table = {
                    cols: [],
                    subcols: [],
                    data: []
                };
                _.forEach(resp.data, function(d){
                    d.values = _.chain(d.values).reverse().value();
                })
                for (i = 0; i < scope.config.columns; ++i) {
                    var colwidth = 70 / scope.config.columns;
                    var stime1 = (resp.data.length > i + 1) ? new Date(resp.data[i + 1].scheduledTimestamp) : null;
                    var stime2 = (resp.data.length > i) ? new Date(resp.data[i].scheduledTimestamp) : null;

                    table.cols.push({
                        label: formatRange(stime1, stime2),
                        loading: true,
                        style: {
                            "width": colwidth + "%",
                            "text-align": "center"
                        }
                    });

                    var sublabels = ["energyReport.date", "energyReport.value", "energyReport.increase", "energyReport.power", "energyReport.cost"];
                    var colNum = 0
                    for(var k=0; k<sublabels.length; k++){
                        if(scope.config.visibleColumns.indexOf(sublabels[k])>=0){
                            colNum ++;
                        }
                    }
                    table.colNum = colNum;
//                    table.colNum = scope.config.visibleColumns.length;
                    for (var j = 0; j < sublabels.length; j++) {
                        if(scope.config.visibleColumns.indexOf(sublabels[j])>-1){
                            table.subcols.push({
                                label: sublabels[j],
                                style: {
                                    "width": (colwidth / table.colNum) + "%",
                                    "text-align": "center"
                                }
                            });
                        }
                    }
                }
                table.cols.reverse();

                var checkParamId = function (v) {
                    return v.paramId === this;
                };

                var increseColumnStats = [];
                var powerColumnStats = [];
                var costColumnStats = [];
                for (i = 0; i < scope.config.series.length; ++i) {
                    var series = scope.config.series[i];
                    if (series.display) {

                        var params = _.flatten(_.pluck(scope.devices, 'params'), true);

                        var ener_param = _.find(params, function (param) {
                            return param.id === series.paramId;
                        });
                        if(ener_param){
                            var param = _.find(params, function (param) {
                                return param.id === ener_param.paramId;
                            });

                            var device = _.find(scope.devices, function (device) {
                                return _.find(device.params, function(param){
                                    return param.id == ener_param.paramId;
                                });
                            });
                            series.name = series.name ? series.name : device.label ? device.label : device.name;
                            series.device = device.label ? device.label : device.name;
                            series.descr1 = device.desc;
                            series.descr2 = device.desc2;
                            series.descr3 = device.desc3;
                            series.param = param.label ? param.label : param.name;
                            series.paramDesc = param.desc;
                            series.precision = param.precision;
                            series.scale = param.scale;
                            series.quantity = param.quantity;
                            series.unit = param.unit;
                            series.display = ener_param.display;
                            series.cost = ener_param.cost;
                            series.costUnit = ener_param.costUnit;

                            var d = [];
                            var getDate = scope.config.visibleColumns.indexOf('energyReport.date') >= 0;
                            var getValue = scope.config.visibleColumns.indexOf('energyReport.value') >= 0;
                            var getIncrease = scope.config.visibleColumns.indexOf('energyReport.increase') >= 0;
                            var getPower = scope.config.visibleColumns.indexOf('energyReport.power') >= 0;
                            var getCost = scope.config.visibleColumns.indexOf('energyReport.cost') >= 0;


                            var quantity = series.unit || $filter('quantityUnitFilter')(series.quantity);

                            for (j = 0; j < scope.config.columns; ++j) {
                                var s1;
                                var s2;
                                if(!increseColumnStats[j]){
                                    increseColumnStats[j] = {
                                        min : 0,
                                        sum : 0,
                                        max : 0
                                    }
                                }
                                if(!powerColumnStats[j]){
                                    powerColumnStats[j] = {
                                        min : 0,
                                        sum : 0,
                                        max : 0
                                    }
                                }
                                if(!costColumnStats[j]){
                                    costColumnStats[j] = {
                                        min : 0,
                                        sum : 0,
                                        max : 0
                                    }
                                }

                                if (resp.data.length > j) {
                                    s1 = _.find(resp.data[j].values, checkParamId, series.paramId) || {};
                                }
                                if (resp.data.length > j + 1) {
                                    s2 = _.find(resp.data[j + 1].values, checkParamId, series.paramId) || {};
                                }
                                if (getCost) {
                                    var e = {
                                        label: s1.value != null && s2.value != null ? unitFilter((s1.value - s2.value)*series.cost, 2, series.costUnit, 0) :"-",
                                        value: s1.value != null && s2.value != null ? (s1.value - s2.value)*series.cost :0,
                                        timestamp: s2.timestamp,
                                        unit: {
                                            quantity: series.costUnit,
                                            precision: 2,
                                            scale: 0
                                        },
                                        style: {
                                            "text-align": "right",
                                            "white-space": "nowrap",
                                            "cursor": "pointer"
                                        },
                                        cost:true,
                                        zoneCost : null,
                                        min : 99999999999999999999,
                                        max : -99999999999999999999,
                                        costColumnStats : costColumnStats[j]
                                    };
                                    e.zoneCost = _.find(series.zonesCost, function(z){
                                        return z.from<=e.value && z.to>e.value;
                                    });
                                    _.forEach(series.zonesCost, function(z){
                                        if(e.min || e.min===0){
                                            if(e.min>=z.from){
                                                e.min = z.from;
                                            }
                                        }else{
                                            e.min = z.from;
                                        }
                                        if(e.max || e.max===0){
                                            if(e.max<z.to){
                                                e.max = z.to;
                                            }
                                        }else{
                                            e.max = z.to;
                                        }
                                    });
                                    if(!e.zoneCost){
                                        _.forEach(series.zonesCost, function(z){
                                            if(e.value<e.min && e.min===z.from){
                                                e.zoneCost = z;
                                            }
                                            if(e.value>e.max && e.max===z.to){
                                                e.zoneCost = z;
                                            }
                                        });
                                    };
                                    d.push(e);
                                    costColumnStats[j].sum += s1.value != null && s2.value != null ? (s1.value - s2.value) :0;
                                    costColumnStats[j].max += costColumnStats[j].max < (s1.value - s2.value)?(s1.value - s2.value) :0;
                                }
                                if (getPower) {
                                    var powerUnit = seriesService.getTimeDerivativeUnit(quantity, 3600000);
                                    var e = {
                                        label: s1.value != null && s2.value != null ? unitFilter(3600000*(s1.value - s2.value)/(s1.timestamp - s2.timestamp), series.precision, powerUnit, series.scale) :"-",
                                        value: s1.value != null && s2.value != null ? 3600000*(s1.value - s2.value)/(s1.timestamp - s2.timestamp):0,
                                        value1: s1.value,
                                        value1: s2.value,
                                        timestamp: s2.timestamp,
                                        timestamp1: s1.timestamp,
                                        timestamp1: s2.timestamp,
                                        unit: {
                                            quantity: powerUnit,
                                            precision: series.precision,
                                            scale: series.scale
                                        },
                                        style: {
                                            "text-align": "right",
                                            "white-space": "nowrap",
                                            "cursor": "pointer"
                                        },
                                        power:true,
                                        zonePower : null,
                                        min : 99999999999999999999,
                                        max : -99999999999999999999,
                                        powerColumnStats : powerColumnStats[j]
                                    };
                                    e.zonePower = _.find(series.zonesPower, function(z){
                                        return z.from<=e.value && z.to>e.value;
                                    });
                                    _.forEach(series.zonesPower, function(z){
                                        if(e.min || e.min===0){
                                            if(e.min>=z.from){
                                                e.min = z.from;
                                            }
                                        }else{
                                            e.min = z.from;
                                        }
                                        if(e.max || e.max===0){
                                            if(e.max<z.to){
                                                e.max = z.to;
                                            }
                                        }else{
                                            e.max = z.to;
                                        }
                                    });
                                    if(!e.zonePower){
                                        _.forEach(series.zonesPower, function(z){
                                            if(e.value<e.min && e.min===z.from){
                                                e.zonePower = z;
                                            }
                                            if(e.value>e.max && e.max===z.to){
                                                e.zonePower = z;
                                            }
                                        });
                                    };
                                    d.push(e);

                                    powerColumnStats[j].min += powerColumnStats[j].min > (s1.value - s2.value)?(s1.value - s2.value) :9999999999999999999999;
                                    powerColumnStats[j].sum += s1.value != null && s2.value != null ? (s1.value - s2.value) :0;
                                    powerColumnStats[j].max += powerColumnStats[j].max < (s1.value - s2.value)?(s1.value - s2.value) :0;
                                }

                                if (getIncrease) {
                                    var e = {
                                        label: s1.value != null && s2.value != null ? unitFilter(s1.value - s2.value, series.precision, quantity, series.scale) : "-",
                                        value: s1.value != null && s2.value != null ? (s1.value - s2.value) :0,
                                        value1: s1.value,
                                        value2: s2.value,
                                        timestamp: s2.timestamp,
                                        timestamp1: s1.timestamp,
                                        timestamp2: s2.timestamp,
                                        unit: {
                                            quantity: quantity,
                                            precision: series.precision,
                                            scale: series.scale
                                        },
                                        style: {
                                            "text-align": "right",
                                            "white-space": "nowrap",
                                            "cursor": "pointer"
                                        },
                                        increase:true,
                                        zone : null,
                                        min : 99999999999999999999,
                                        max : -99999999999999999999,
                                        increseColumnStats : increseColumnStats[j]
                                    };
                                    e.zone = _.find(series.zones, function(z){
                                        return z.from<=e.value && z.to>e.value;
                                    });
                                    _.forEach(series.zones, function(z){
                                        if(e.min || e.min===0){
                                            if(e.min>=z.from){
                                                e.min = z.from;
                                            }
                                        }else{
                                            e.min = z.from;
                                        }
                                        if(e.max || e.max===0){
                                            if(e.max<z.to){
                                                e.max = z.to;
                                            }
                                        }else{
                                            e.max = z.to;
                                        }
                                    });
                                    if(!e.zone){
                                        _.forEach(series.zones, function(z){
                                            if(e.value<e.min && e.min===z.from){
                                                e.zone = z;
                                            }
                                            if(e.value>e.max && e.max===z.to){
                                                e.zone = z;
                                            }
                                        });
                                    };
                                    d.push(e);

                                    increseColumnStats[j].sum += s1.value != null && s2.value != null ? (s1.value - s2.value) :0;
                                    increseColumnStats[j].max += increseColumnStats[j].max < (s1.value - s2.value)?(s1.value - s2.value) :0;
                                }
                                if (getValue) {
                                    var quantity = series.unit || $filter('quantityUnitFilter')(series.quantity);
                                    d.push({
                                        label: s1.value != null ? unitFilter(s1.value, series.precision, quantity, series.scale) : "-",
                                        value: s1.value != null ? s1.value :0,
                                        timestamp: s2.timestamp,
                                        unit: {
                                            quantity: quantity,
                                            precision: series.precision,
                                            scale: series.scale
                                        },
                                        style: {
                                            "text-align": "right",
                                            "white-space": "nowrap",
                                            "cursor": "pointer"
                                        }
                                    });
                                }
                                if (getDate) {
                                    d.push({
                                        label: s1.timestamp != null ? dateFilter(s1.timestamp, "yyyy-MM-dd HH:mm") : "-",
                                        value: NaN,
                                        date: true,
                                        style: {
                                            "text-align": "center",
                                            "white-space": "nowrap"
                                        }
                                    });
                                }
                            }
                            d.reverse();
                            table.data.push(d);
                        }else{
                            series.display = false;
                        }
                    }
                    scope.table = table;
                }
            }

            function updateData(date) {

                scope.ui.updatingData = true;
                if (date == null) {
                    date = new Date();
                }
                scope.ui.date = date;

                dataService.getEnergyReportData(scope.config.reportId, date, scope.config.columns + 1).then(function(resp) {
                    var i, d;
                    if (resp.data) {
                        resp.data.reverse();
                    } else {
                        resp.data = [];
                    }

                    scope.date = new Date(resp.data[0].scheduledTimestamp);
                    var checkDate = function(date) {
                        //TK this - date passed as underscore context
                        return date.getTime() === this.getTime();
                    };
                    for (i = 0; i < resp.data.length; ++i) {
                        d = new Date(resp.data[i].scheduledTimestamp);
                        if (_.find(scope.dates, checkDate, d) === undefined) {
                            scope.dates.push(d);
                        }
                    }
                    scope.dates.sort();
                    scope.data = resp;

                    updateTable(resp);
                    scope.ui.updatingData = false;
                    if(scope.ui.stateModalParam.reload){
                        scope.ui.setModalParamFromState()
                    }
                });
            }

            configService.get().then(function(meternetConfig) {
                var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
                scope.devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
            });

            updateData();

            scope.$watch("config", function(nval, oval) {
                if (nval !== oval) {
                    updateData(scope.date);
                }
            }, true);

            scope.$on('generateCsv', function() {
                var dataToSend = {
                    "visibleColumns":scope.config.visibleColumns,
                    "series": scope.config.series,
                    "energyReportId": scope.config.reportId,
                    "count": scope.config.columns,
                    "endTime": scope.date.toISOString(),
                    "timeZone" : encodeURIComponent(moment().format('ZZ'))
                };

                $http.post('/energyReport.csv', dataToSend).
                    then(function(data, status, headers, config) {
                        var anchor = angular.element('<a/>');
                        anchor.attr({
                            href: 'data:text/csv;charset=Windows-1250,' + encodeURI(data.data),
                            target: '_blank',
                            download: 'energy-report.csv',
                            id: 'testowanie'
                        });
                        anchor.appendTo('body');
                        anchor[0].click();
                    },function(data, status, headers, config) {
                    });
            });
            scope.$on('generateXlsx', function() {
                var dataToSend = {
                    "visibleColumns":scope.config.visibleColumns,
                    "series": scope.config.series,
                    "energyReportId": scope.config.reportId,
                    "count": scope.config.columns,
                    "endTime": scope.date.toISOString(),
                    "timeZone" : encodeURIComponent(moment().format('ZZ'))
                };

                $http.post('/energyReport.xlsx', dataToSend, {responseType:'arraybuffer'}).
                    then(function(response, status, headers, config) {
                        var data = response.data; //from server
                        var blob = new Blob([data], { type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8"});
                        var link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'energy-report.xlsx';
                        link.click();
                    },function(data, status, headers, config) {
                    });
            });

            $('#myModal').on('hidden.bs.modal', function () {
              scope.ui.chartData = [];
            })
        }
    };
});
