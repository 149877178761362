<form>
    <div class="form-group">
        <label class="form-group-label">{{'dashboard.edit.title' | i18n}}</label>
        <input class="form-control" x-ng-model="config.name"/>
    </div>
</form>

<div class="panel panel-default">
    <div class="panel-heading">
        Ustawienia okresów rozliczeniowych
    </div>
    <div class="panel-body">
        <form class="form-inline">

            <div class="form-group">
                <label class="form-group-label">okres</label>
                <select class="form-control" style="width: 100px;"
                        x-ng-model="ui.period"
                        x-ng-options="p as p.label for p in ui.periods"
                        x-ng-change="ui.updateCronExpression()"></select>
            </div>

            <div class="form-group" x-ng-show="ui.period.value === 2">
                <label class="form-group-label">dzień</label>
                <select class="form-control" style="width: 100px;"
                        x-ng-model="ui.weekday"
                        x-ng-options="w as w.label for w in ui.weekdays"
                        x-ng-change="ui.updateCronExpression()"></select>
            </div>

            <div class="form-group" x-ng-show="ui.period.value === 3">
                <label class="form-group-label">dzień</label>
                <select class="form-control" style="width: 100px;"
                        x-ng-model="ui.day"
                        x-ng-options="d as d.label for d in ui.days"
                        x-ng-change="ui.updateCronExpression()"></select>
            </div>

            <div class="form-group">
                <label class="form-group-label">godzina</label>
                <select class="form-control" style="width: 80px;"
                        x-ng-model="ui.hour"
                        x-ng-options="h as h.label for h in ui.hours"
                        x-ng-change="ui.updateCronExpression()"></select>
            </div>

            <div class="form-group" x-ng-show="ui.period.value === 0">
                <label class="form-group-label">co ile</label>
                <select class="form-control" style="width: 80px;"
                        x-ng-model="ui.hstep"
                        x-ng-options="s as s.label for s in ui.hsteps"
                        x-ng-change="ui.updateCronExpression()"></select>
            </div>

            <div class="form-group">
                <label class="form-group-label">kolumny</label>
                <select class="form-control" style="width: 80px;"
                        x-ng-model="config.columns"
                        x-ng-options="s for s in ui.columns">
                </select>
            </div>

            <div class="form-group">
                <label class="form-group-label">od kiedy</label>

                <!--<div class="dropdown">-->
                    <!--<a class="dropdown-toggle" id="date-from-dropdown" role="button" data-toggle="dropdown"-->
                       <!--data-target="#" href="#">-->
                        <!--<div class="input-group" style="width: 150px;">-->
                            <!--<input type="text" class="form-control"-->
                                   <!--value="{{config.dateFrom | date: 'y-MM-dd HH:mm'}}"/>-->
                            <!--<span class="input-group-addon">-->
                                <!--<i class="fa fa-calendar"></i>-->
							<!--</span>-->
                        <!--</div>-->
                    <!--</a>-->
                    <!--<ul class="dropdown-menu" role="menu">-->
                        <!--<datetimepicker class="width-max" x-ng-model="config.dateFrom"-->
                                        <!--x-datetimepicker-config="{ dropdownSelector: '#date-from-dropdown' }"/>-->
                    <!--</ul>-->
                <!--</div>-->

                <div class='input-group date'>
                    <input type='text' class="form-control" x-ng-model="config.dateFrom" x-datetimepicker=""/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>

            </div>

        </form>
    </div>
</div>

<div class="panel panel-default">
    <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>

    <ul class="list-group">
        <li class="list-group-item" x-ng-repeat="series in config.series" x-ng-if="series.hide">
{{series}}testowanie
            <form class="form form-inline">

                <div class="form-group">
                    <label class="form-group-label">{{'dashboard.widgets.config.series.device' | i18n}}</label>
                    <select class="form-control" style="width: 130px" x-ng-model="series._device"
                            x-ng-options="device as device.name for device in devices"
                            x-ng-change="ui.updateSeriesParam(series)">
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-group-label">{{'dashboard.widgets.config.series.param' | i18n}}</label>
                    <select class="form-control " style="width: 130px" x-ng-model="series._param"
                            x-ng-options="param as (param.name + ' [' + (param.quantity | quantityUnitFilter) + ']') for param in series._device.paramConfigs"
                            x-ng-change="ui.updateSeriesParam(series)">
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-group-label">{{'dashboard.widgets.config.series.name' | i18n}}</label>
                    <input class="form-control" style="width: 130px" x-ng-model="series.name"/>
                </div>
                <div class="form-group">
                    <label class="form-group-label">{{'dashboard.widgets.config.series.axis.scale' | i18n}}</label>
                    <select class="form-control" style="width: 50px;" x-ng-model="series.scale"
                            x-ng-options="s.value as s.label for s in ui.scales">
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-group-label">{{'dashboard.widgets.config.series.axis.precision' | i18n}}</label>
                    <select class="form-control" style="width: 50px;" x-ng-model="series.precision"
                            x-ng-options="p for p in ui.precisions">
                    </select>
                </div>
                <div class="form-group pull-right">
                    <label class="form-group-label">&nbsp;</label>
                    <button class="btn btn-danger pull-right" x-ng-click="ui.removeSeries(series)">
                        <i class="fa fa-trash-o fa-lg"></i>
                    </button>
                </div>

            </form>
        </li>
        <li class="list-group-item" x-ng-if="ui.canAddSeries()">
            <div class="pull-right">
                <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                    {{'dashboard.widgets.config.series.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                </button>
            </div>
            <div class="clearfix"></div>
        </li>
    </ul>
</div>
