<div class="navbar navbar-fixed-top" role="navigation" id="navbar" xmlns:th="http://www.w3.org/1999/xhtml">
    <div class="container-fill">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navigationMenu">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand brand no-cursor" th:href="@{/}" href="#">
                <img class="img-responsive" src="/resources/css/img/logomini.png" x-ng-if="ui.mainLicense=='FIF'"></img>
                <img class="img-responsive" src="/resources/css/img/logomini.png" x-ng-if="ui.mainLicense=='ETI'"></img>
                <img class="img-responsive" src="/resources/css/img/logomini.png" x-ng-if="ui.mainLicense=='EFEN'"></img>
                <img class="img-responsive" src="/resources/css/img/logomini.png" x-ng-if="ui.mainLicense=='BSH'"></img>
                <img class="img-responsive" src="/resources/css/img/logomini_finn.png" x-ng-if="ui.mainLicense=='FINN'"></img>
            </a>
        </div>
        <div id="navigationMenu" class="navbar-collapse">
            <ul class="nav navbar-nav nav-tabs" role="tablist">
                <li data-ng-repeat="menuLink in menuLinks" data-ng-click="setActive(menuLink)" data-ng-class="{'menuActive' : isActive(menuLink), 'dropdown': menuLink.children}">
                    <a data-ui-sref="{{menuLink.target}}" x-ng-if="!menuLink.children && !(config.notification|| dashboardLink.notification)" x-ng-disabled="menuLink.disabled">
                        <i class="fa fa-fw" x-ng-class="menuLink.icon"></i>&nbsp;{{menuLink.title | i18n }}
                        <span x-ng-if="menuLink.notification" title="{{menuLink.notification.info | i18n}}">
                            <i class="fa fa-fw faa-flash animated" x-ng-class="menuLink.notification.icon"></i>
                        </span>
                    </a>
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#" x-ng-if="menuLink.children && !(config.notification || dashboardLink.notification)">
                        <i class="fa fa-fw" x-ng-class="menuLink.icon"></i>&nbsp;{{menuLink.title | i18n }}
                    </a>
                    <a x-ng-if="config.notification || dashboardLink.notification" data-toggle="modal" data-target="#configModal" disabled>
                        <i class="fa fa-fw" x-ng-class="menuLink.icon"></i>&nbsp;{{menuLink.title | i18n }}
                        <span x-ng-if="menuLink.notification" title="{{menuLink.notification.info | i18n}}">
                            <i class="fa fa-fw faa-flash animated" x-ng-class="menuLink.notification.icon"></i>
                        </span>
                    </a>
                    <ul x-ng-if="menuLink.children" class="dropdown-menu" role="menu" style="max-height: 500px; overflow-y: auto;">
                        <li data-ng-repeat="child in menuLink.children">
                            <a x-ng-if="!child.resource" data-ui-sref="{{child.target}}" x-ng-click="child.click()" style="cursor: pointer">
                                <i class="fa fa-fw" x-ng-class="child.icon"></i>
                                <span x-ng-if="child.title">{{child.title | i18n}}</span>
                                <span x-ng-if="child.name">{{child.name}}</span>
                            </a>
                            <a x-ng-if="child.resource" x-ng-attr-href="{{child.target}}" href="#" target="_blank">
                                <i class="fa fa-fw" x-ng-class="child.icon"></i>
                                <span>{{child.title | i18n}}</span>
                            </a>
                        </li>
                        <li class="divider" x-ng-if="menuLink.title==='menu.dashboard' && user.admin"></li>
                        <li x-ng-if="menuLink.title==='menu.dashboard' && user.admin"
                            x-ng-class="{'disabled': !ui.canAddDashboard}"
                            x-uib-tooltip="{{'menu.maximumDashboardsReached' | i18n}}"
                            x-tooltip-placement="top"
                            x-tooltip-animation="true"
                            x-tooltip-append-to-body="false"
                            x-tooltip-enable="!ui.canAddDashboard"
                            style="cursor:pointer">
                            <a x-ng-click="ui.addNewDashboard()">
                                <i class="fa fa-fw fa-plus"></i>&nbsp;{{'dashboard.add' | i18n}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

            <ul class="nav navbar-nav navbar-right navbar-tabs">

                <button class="btn btn-default dropdown-toggle my-menu lang-choice" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i class="fa fa-cogs fa-lg" aria-hidden="true"></i>
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu menu-right" aria-labelledby="dropdownMenu1">
                    <li class="dropdown-header">{{'select.lang'|i18n}}</li>
                    <!--<li data-lang-selector="pl-PL,en-US,ru-RU,de-DE" class="language-change"></li>-->
                    <li data-lang-selector="pl-PL,en-US,ru-RU" class="language-change"></li>
                    <li role="separator" class="divider"></li>
                    <li class="dropdown-header">{{'select.notifications'|i18n}}</li>
                    <li class="language-change">
                        <a ng-click="notificationsConf.setOnoff()">
                            <i class="fa fa-check-square-o" aria-hidden="true" x-ng-show="notificationsConf.onoff"></i>
                            <i class="fa fa-square-o" aria-hidden="true" x-ng-hide="notificationsConf.onoff"></i>
                            {{'select.notifications_onoff'|i18n}}
                        </a>
                        <a ng-click="notificationsConf.setSound()">
                            <i class="fa fa-check-square-o" aria-hidden="true" x-ng-show="notificationsConf.sound"></i>
                            <i class="fa fa-square-o" aria-hidden="true" x-ng-hide="notificationsConf.sound"></i>
                            {{'select.notifications_sound'|i18n}}
                        </a>
                    </li>
                </ul>

                <li class="no-background transparent" event-indicator="" x-config="notificationsConf"></li>

				<li class="no-background transparent" style="padding-top: 4px;" >
                    <a href="#" class="connection-status no-cursor" style="padding-left:0px">
                        <span class="fa-stack text-primary"x-ng-show="ui.connectionStatus" tooltip-placement="bottom" uib-tooltip="Online">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-plug fa-stack-1x fa-inverse"></i>
                        </span>
                        <span class="fa-stack text-primary"x-ng-show="!ui.connectionStatus" tooltip-placement="bottom" uib-tooltip="Offline">
                            <i class="fa fa-plug fa-stack-1x"></i>
                            <i class="fa fa-ban fa-stack-2x fa-rotate-90 text-danger"></i>
                        </span>
                    </a>
                </li>

                <li x-logout="" class="logout no-background-logout"></li>
                <li x-ng-if="ui.mainLicense=='FIF'">
                    <img class="img-responsive" src="/resources/css/img/ff2.png" style="height: 35px"></img>
                </li>
                <li x-ng-if="ui.mainLicense=='FINN'">
                    <img class="img-responsive" src="/resources/css/img/finn2.png" style="height: 35px"></img>
                </li>
                <li x-ng-if="ui.mainLicense=='ETI'">
                    <img class="img-responsive" src="/resources/css/img/eti2.png" style="height: 35px"></img>
                </li>
                <li x-ng-if="ui.mainLicense=='EFEN'">
                    <img class="img-responsive" src="/resources/css/img/efen2.png" style="height: 35px"></img>
                </li>
                <li x-ng-if="ui.mainLicense=='BSH'">
                    <img class="img-responsive" src="/resources/css/img/bsh2.png" style="height: 35px"></img>
                </li>
            </ul>

        </div>
    </div>
    <!--/.nav-collapse -->
</div>

<!-- Modal -->
<div class="modal fade" id="configModal" tabindex="-1" role="dialog" aria-labelledby="configModalLabel">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="configModalLabel">{{'config.waring.title' | i18n}}</h4>
            </div>
            <div class="modal-body">
                {{'config.waring.msg' | i18n}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">{{'btn.OK' | i18n}}</button>
            </div>
        </div>
    </div>
</div>

<a href="" data-toggle="modal" data-target="#cacheModal" id="cacheModalShower"></a>
<!-- Modal -->
<div class="modal fade" id="cacheModal" tabindex="-1" role="dialog" aria-labelledby="cacheModalLabel">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="cacheModalLabel">{{'cache.cleared' | i18n }}</h4>
            </div>
            <div class="modal-body">
                {{'cache.cleared' | i18n }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">{{'btn.OK' | i18n}}</button>
            </div>
        </div>
    </div>
</div>
