<table class="table table-bordered table-hover" style="margin-bottom:50px">
    <thead style="cursor:default">
    <tr style="background-color: rgb(219, 233, 246); --darkreader-inline-bgcolor:#2d2f2f; color: #337ab7; text-decoration: none; border-width: 0px;">
        <!--<th style="width: 1%">Lp.</th>-->
        <th rowspan="2" style="white-space: nowrap; width: 7%; vertical-align: middle; border-width: 0px;" x-ng-if="config.visibleColumns.indexOf('energyReport.title')>-1">{{'config.param.label' | i18n}}</th>
        <th rowspan="2" style="white-space: nowrap; width: 7%; vertical-align: middle; border-width: 0px;" x-ng-if="config.visibleColumns.indexOf('energyReport.device')>-1">{{'config.device.deviceName' | i18n}}</th>
        <th rowspan="2" style="white-space: nowrap; width: 7%; vertical-align: middle; border-width: 0px;" x-ng-if="config.visibleColumns.indexOf('energyReport.descr1')>-1">{{'config.device.descr1' | i18n}}</th>
        <th rowspan="2" style="white-space: nowrap; width: 7%; vertical-align: middle; border-width: 0px;" x-ng-if="config.visibleColumns.indexOf('energyReport.descr2')>-1">{{'config.device.descr2' | i18n}}</th>
        <th rowspan="2" style="white-space: nowrap; width: 7%; vertical-align: middle; border-width: 0px;" x-ng-if="config.visibleColumns.indexOf('energyReport.descr3')>-1">{{'config.device.descr3' | i18n}}</th>
        <th rowspan="2" style="white-space: nowrap; width: 7%; vertical-align: middle; border-width: 0px;" x-ng-if="config.visibleColumns.indexOf('energyReport.param')>-1">{{'report.table.param' | i18n}}</th>
        <th rowspan="2" style="white-space: nowrap; width: 7%; vertical-align: middle; border-width: 0px;" x-ng-if="config.visibleColumns.indexOf('energyReport.paramDesc')>-1">{{'report.table.paramDesc' | i18n}}</th>
        <th colspan="{{table.colNum}}" x-ng-repeat="c in table.cols" x-ng-style="c.style"
            data-ng-class="($index%2==0) ? 'energy-table-header-color' : 'energy-table-header-no-color'">{{c.label}}
        </th>
    </tr>
    <tr style="background-color: rgb(219, 233, 246); --darkreader-inline-bgcolor:#2d2f2f; color: #337ab7; text-decoration: none; border-width: 0px;">
        <th x-ng-repeat="s in table.subcols" x-ng-style="s.style"
            data-ng-class="(($index-($index%table.colNum))/table.colNum)%2==0 ? 'energy-table-header-color':'energy-table-header-no-color'">
            <i class='fa fa-fw fa-bar-chart-o text-primary'
               x-ng-click="ui.setModalData(table, config.series, $index);"
               data-toggle='modal' data-target='#myModal'
               x-ng-if="s.label!=='energyReport.date'"
               style="cursor:url('/resources/css/img/charcolumn.png'), auto;	;"></i><br/>
            {{s.label | i18n}}
        </th>
    </tr>
    </thead>
    <tbody style="cursor:default">
    <tr x-ng-repeat="(seriesIndex, s) in config.series | filter: {display:true}">
        <td x-ng-if="config.visibleColumns.indexOf('energyReport.title')>-1" style="white-space: nowrap">{{s.name}}</td>
        <td x-ng-if="config.visibleColumns.indexOf('energyReport.device')>-1" style="white-space: nowrap">{{s.device}}</td>
        <td x-ng-if="config.visibleColumns.indexOf('energyReport.descr1')>-1" style="white-space: nowrap">{{s.descr1}}</td>
        <td x-ng-if="config.visibleColumns.indexOf('energyReport.descr2')>-1" style="white-space: nowrap">{{s.descr2}}</td>
        <td x-ng-if="config.visibleColumns.indexOf('energyReport.descr3')>-1" style="white-space: nowrap">{{s.descr3}}</td>
        <td x-ng-if="config.visibleColumns.indexOf('energyReport.param')>-1" style="white-space: nowrap">{{s.param}}</td>
        <td style="border-right-width: 3px;" x-ng-if="config.visibleColumns.indexOf('energyReport.paramDesc')>-1">{{s.paramDesc}}</td>
        <td x-ng-repeat="(colIndex, d) in table.data[$index]" data-ng-class="(($index-($index%table.colNum))/table.colNum)%2==0 ? 'energy-table-row':''"
            x-ng-style="d.style" style="overflow: hidden; padding:0px">

<!--            INCRESE-->
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.mode===1 && d.increase" expand-to-parent-bottom=""
                x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                data-toggle='modal' data-target='#myModal'>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:{{100*d.value/d.increseColumnStats.max}}%; border-radius: 0px; background-color:#80a9d1"></div>
                <span style="padding:8px">{{d.label}}</span>
            </div>
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.mode===2 && d.increase" expand-to-parent-bottom=""
                x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                data-toggle='modal' data-target='#myModal'>
                <div style="width:100%; height:100%; background-color:{{d.zone.color}}; border-radius: 0px">
                    <span style="padding:8px">{{d.label}}</span>
                </div>
            </div>
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.mode===3 && d.increase" expand-to-parent-bottom=""
                x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                data-toggle='modal' data-target='#myModal'>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:{{100*d.value/d.max}}%; background-color:{{d.zone.color}}; border-radius: 0px"></div>
                <span style="padding:8px"> {{d.label}}</span>
            </div>

            <!--            POWER-->
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.modePower===1 && d.power" expand-to-parent-bottom=""
                 x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                 data-toggle='modal' data-target='#myModal'>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:{{100*d.value/d.powerColumnStats.max}}%; border-radius: 0px; background-color:#80a9d1"></div>
                <span style="padding:8px">{{d.label}}</span>
            </div>
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.modePower===2 && d.power" expand-to-parent-bottom=""
                 x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                 data-toggle='modal' data-target='#myModal'>
                <div style="width:100%; height:100%; background-color:{{d.zonePower.color}}; border-radius: 0px">
                    <span style="padding:8px">{{d.label}}</span>
                </div>
            </div>
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.modePower===3 && d.power" expand-to-parent-bottom=""
                 x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                 data-toggle='modal' data-target='#myModal'>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:{{100*d.value/d.max}}%; background-color:{{d.zonePower.color}}; border-radius: 0px"></div>
                <span style="padding:8px"> {{d.label}}</span>
            </div>

            <!--            COST-->
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.modeCost===1 && d.cost" expand-to-parent-bottom=""
                 x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                 data-toggle='modal' data-target='#myModal'>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:{{100*d.value/d.costColumnStats.max}}%; border-radius: 0px; background-color:#80a9d1"></div>
                <span style="padding:8px">{{d.label}}</span>
            </div>
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.modeCost===2 && d.cost" expand-to-parent-bottom=""
                 x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                 data-toggle='modal' data-target='#myModal'>
                <div style="width:100%; height:100%; background-color:{{d.zoneCost.color}}; border-radius: 0px">
                    <span style="padding:8px">{{d.label}}</span>
                </div>
            </div>
            <div class="progress" style="z-index: 10; border-radius: 0px; height: 32px; margin: 0px; background-color: transparent; cursor:url('/resources/css/img/charrow.png'), auto;" x-ng-if="config.modeCost===3 && d.cost" expand-to-parent-bottom=""
                 x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                 data-toggle='modal' data-target='#myModal'>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:{{100*d.value/d.max}}%; background-color:{{d.zoneCost.color}}; border-radius: 0px"></div>
                <span style="padding:8px"> {{d.label}}</span>
            </div>

            <div x-ng-if="!d.date && ((config.mode===0 || !d.increase) && (config.modePower===0 || !d.power) && (config.modeCost===0|| !d.cost))"
                 style="z-index: 10; padding:8px; cursor:url('/resources/css/img/charrow.png'), auto;"
                x-ng-click="ui.setModalParam(seriesIndex, s, colIndex, table);"
                data-toggle='modal' data-target='#myModal'>{{d.label}}
            </div>
            <div x-ng-if="d.date" style="z-index: 10; padding:8px; cursor:default">
                {{d.label}}
            </div>
            <!--ta warstwa ma za zadanie tylko rozciagac komurki-->
            <div x-ng-if="!(!d.date && ((config.mode===0 || !d.increase) && (config.modePower===0 || !d.power) && (config.modeCost===0|| !d.cost)))"
                    style="padding:8px; cursor:default; margin: -30px 0px; z-index: -10; opacity:0">
                {{d.label}}
            </div>
        </td>
    </tr>
    </tbody>
</table>

<div class="panel-body" style="bottom: 20px; position: absolute; right: 20px; z-index: 100;">
    <form>
        <span class="alert alert-danger" style="padding: 5px;" x-ng-if="ui.updatingData">{{'ui.loading.data' | i18n}}</span>
        <div class="pull-right">
            <div class="input-group" style="width: 170px;">
                <div class="input-group-btn">
                    <button class="btn btn-primary" x-ng-disabled="!ui.canStep(-config.columns+1) || ui.updatingData" x-ng-click="ui.stateModalParam.reload=true; ui.step(-config.columns+1)">
                        <i class="fa fa-lg fa-caret-left"></i><i class="fa fa-lg fa-caret-left"></i>
                    </button>
                    <button class="btn btn-primary" x-ng-disabled="!ui.canStep(-1) || ui.updatingData" x-ng-click="ui.step(-1)">
                        <i class="fa fa-lg fa-caret-left"></i>
                    </button>
                </div>
                <input type="number" class="form-control" ng-model="config.columns" x-ng-change="ui.step(0)" style="width: 50px;" aria-label="...">
                <div class="input-group-btn">
                    <button class="btn btn-primary" x-ng-disabled="!ui.canStep(1) || ui.updatingData" x-ng-click="ui.step(1)">
                        <i class="fa fa-lg fa-caret-right"></i>
                    </button>
                    <button class="btn btn-primary" x-ng-disabled="!ui.canStep(config.columns-1) || ui.updatingData" x-ng-click="ui.stateModalParam.reload=true; ui.step(config.columns-1)">
                        <i class="fa fa-lg fa-caret-right"></i><i class="fa fa-lg fa-caret-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="pull-right">
            <div class="form-group input-group" style="width: 195px; padding: 0px; margin: 0px; display: inline-block; vertical-align: bottom;" x-ng-disabled="ui.updatingData">
                <div class='input-group date'>
                    <input name="timeFrom" x-for-input="inputName" x-show-error="showErrors"
                           x-show-help="true" type='text' class="form-control" x-ng-model="ui.date"
                           x-ng-change="ui.setStepDate(ui.date)"
                           x-datetimepicker=""/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                {{ui.chartTitle}}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div energy-report-chart-widget="" ng-if="!!ui.chartData.length" data="ui.chartData" unit="ui.chartUnit" small="ui.small"></div>
            </div>
            <div class="modal-footer" ng-if="ui.stateModalParam.showPrevNext">
                <span class="alert alert-danger" style="padding: 5px;" x-ng-if="ui.updatingData">{{'ui.loading.data' | i18n}}</span>
                <div class="pull-right">
                    <div class="input-group" style="width: 170px;">
                        <div class="input-group-btn">
                            <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(-config.columns+1) || ui.updatingData" x-ng-click="ui.stateModalParam.reload=true; ui.step(-config.columns+1)">
                                <i class="fa fa-lg fa-caret-left"></i><i class="fa fa-lg fa-caret-left"></i>
                            </button>
                            <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(-1) || ui.updatingData" x-ng-click="ui.step(-1)">
                                <i class="fa fa-lg fa-caret-left"></i>
                            </button>
                        </div>
                        <input type="number" class="form-control" ng-model="config.columns" x-ng-change="ui.step(0)" style="width: 50px;" aria-label="...">
                        <div class="input-group-btn">
                            <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(1) || ui.updatingData" x-ng-click="ui.step(1)">
                                <i class="fa fa-lg fa-caret-right"></i>
                            </button>
                            <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(config.columns-1) || ui.updatingData" x-ng-click="ui.stateModalParam.reload=true; ui.step(config.columns-1)">
                                <i class="fa fa-lg fa-caret-right"></i><i class="fa fa-lg fa-caret-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="pull-right">
                    <div class="form-group input-group" style="width: 195px; padding: 0px; margin: 0px; display: inline-block; vertical-align: bottom;" x-ng-disabled="ui.updatingData">
                        <div class='input-group date'>
                            <input name="timeFrom" x-for-input="inputName" x-show-error="showErrors"
                                   x-show-help="true" type='text' class="form-control" x-ng-model="ui.date"
                                   x-ng-change="ui.setStepDate(ui.date)"
                                   x-datetimepicker=""/>
                            <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
