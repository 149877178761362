    var module = angular.module('meternet.dashboard.controllers.gauge', [ 'i18n' ]);

function GaugeWidgetEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
        seriesService, TimeDerivativeScales, ColorPickerOpts, maxSeries) {
    $scope.ui = {
        addSeries : function() {
            if (this.canAddSeries()) {
                var series = {
                    paramId : null,
                    average : 1,
                    derivative : 0,
                    unit : "",
                    majorTicks : 5,
                    minorTicks : 3,
                    max : 100,
                    min : 0,
                    precision : 2,
                    scale : 0,
                    zones : [ {
                        "color" : "#5cb85c",
                        from : 0,
                        to : 50
                    }, {
                        "color" : "#f0ad4e",
                        from : 50,
                        to : 75
                    }, {
                        "color" : "#d9534f",
                        from : 75,
                        to : 100
                    } ]
                };
                $scope.config.series.push(series);
            }
        },
        removeSerie : function(index) {
            $scope.config.series.splice(index, 1);
        },
        isSingleSerie : function() {
            if ($scope.config.series.length > 1) {
                return "enabled";
            }
            return "disabled";
        },
        addZone : function(serieIndex) {
            var serie = config.series[serieIndex];
            var zonesLength = serie.zones.length;

            var min = zonesLength > 0 ? serie.zones[zonesLength - 1].to : serie.min;
            serie.zones.push({
                "color" : "#ffffff",
                "from" : min,
                "to" : min + 10
            });
        },
        removeZone : function(serieIndex, index) {
            config.series[serieIndex].zones.splice(index, 1);
        },
        minmaxChange : function(serieIndex){
            var serie = config.series[serieIndex];
            var max=-999999999999999999999;
            var min=999999999999999999999;
            _.forEach(serie.zones, function(zone){
                zone.from<min ? min = zone.from : min = min;
                zone.to>max ? max = zone.to : max= max;
            })
            serie.min = min;
            serie.max = max;
        },
        canAddSeries : function() {
            return $scope.config.series.length < maxSeries;
        },
        boolToStr : function(arg) {
            return arg ? $filter('i18n')("universal.on") : $filter('i18n')("universal.off");
        },
        updateSeriesParam : function(series) {
            if (series.deviceId !== series._device.id) {
                series._param = series._device.params[0];
            }
            if (series._device && series._param) {
                series.deviceId = series._device.id;
                series.paramId = series._param.id;
                series.scale = series._param.scale || 0;
                series.precision = series._param.precision || 0;

                var deviceName = (series._device.label||series._device.name);
                var paramName = (series._param.label||series._param.name);
                series.name = deviceName + '.' + paramName;

                if (!series.derivative) {
                    series.unit=$filter('quantityUnitFilter')(series._param.quantity);
                } else {
                    series.unit = seriesService.getTimeDerivativeUnit($filter('quantityUnitFilter')(series._param.quantity), series.derivative);
                }
            } else {
                series.deviceId = null;
                series.paramId = null;
                series.name = null;
                series.unit = null;
            }
        },
        derivatives : TimeDerivativeScales,
        scales : UnitScales,
        colorPickerOpts : ColorPickerOpts,
        precisions : [ 0, 1, 2, 3, 4, 5, 6 ]
    };

    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
        $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "prepaid"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "alarm"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "control"}));

        $scope.devices = _.sortBy($scope.devices, function(d){
            return d.label||d.name;
        });

        var checkParam = function(param) {
            return this.paramId === param.id;
        };

        var checkDevice = function(device) {
            return this.deviceId === device.id;
        };

        for (var i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            series._device = _.find($scope.devices, checkDevice, series);
            if (series._device) {
                series._param = _.find(series._device.params, checkParam, series);
            }
        }

    });

    $scope.colors = [ '#865BB7','#337ab7','#5bc0de','#f0ad4e','#d9534f','#5cb85c'];
}

function GaugeWidgetCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents,
        seriesService, $q) {
    $scope.ui = {};
    $scope.dataRaw = [];
    $scope.dataRaw[0] = [];

    var updateValue = function(index, data) {
        var maxData = config.series[index].average * 2;
        if (!$scope.dataRaw[index]) {
            $scope.dataRaw[index] = [];
        }
        $scope.dataRaw[index] = $scope.dataRaw[index].concat(data);
        if (maxData < $scope.dataRaw[index].length) {
            $scope.dataRaw[index].splice(0, $scope.dataRaw[index].length - maxData);
        }

        var outValues = seriesService.processData($scope.dataRaw[index], NaN, NaN, config.series[index].derivative,
            config.series[index].average);

        _.forEach(outValues, function(outValue) {
            if (outValue) {
                outValue.index = index;
            }
        });

        if (outValues.length > 0) {
            $scope.$broadcast(DashboardEvents.REDRAW, outValues[outValues.length - 1]);
        }
    };

    var promises = [];
    _.each(config.series, function(serie) {
        var request = {};
        if (serie.paramId) {
            request.count = Math.max(serie.average, 1);

            var promise = dataService.requestParamLastData(serie.paramId);
            promise.paramId = serie.paramId;
            promises.push(promise);
        }
    });
    $q.all(promises).then(function(moments) {

        _.forEach(promises, function(p, i) {
            var d = [];
            if (moments[i].previous){
                d.push(moments[i].previous);
            }      
            d.push(moments[i].current);
            updateValue(i, d);
            var unsubscribeFn = dataService.subscribeForParametersMeasurements(promises[i].paramId, function(moment) {
                var data = [];
                data.push(moment.current);
                updateValue(i, data);
            });
            $scope.$on('$destroy', unsubscribeFn);
        });
    });
}

module.controller('gaugeWidgetEditCtrl', GaugeWidgetEditCtrl);
module.controller('gaugeWidgetCtrl', GaugeWidgetCtrl);