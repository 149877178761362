var module = angular.module('meternet.report.directives.EnergyReportChartWidgetDirective', [
                                                                                        'meternet.services',
                                                                                        'meternet.config.controllers',
                                                                                        'meternet.filters',
                                                                                        'meternet.dashboard.constants',
                                                                                        'meternet.dashboard.directives' ]);

module.directive('energyReportChartWidget', function ($window, $timeout, $location, $interval, $filter, DashboardEvents, unitFilter) {

    function BarGraph(svg, opt) {

        var selectedBarValue =0;
        var options = {
            margin: {
                top: 70,
                right: 0,
                bottom: 0,
                left: 100
            },
            barSize: 20,
            barSpacing: 5,
            tickLabelEveryXTicks: 1,
            unit: 'Wh',
            axis: {
                precision: 0
            },
            hiddenLegend: false,
            unit:{}
        };

        this.options = function (o) {
            if (!arguments.length) {
                return tools.copy(options);
            } else {
                tools.copy(o, options);
                return this;
            }
        };

        this.options(opt);
        var margin = {top: 40, right: 20, bottom: 50, left: 100},
            width = options.width - margin.left - margin.right,
            height = options.height - margin.top - margin.bottom;

        var x = d3.scale.ordinal().rangeRoundBands([0, width], .05);
        var x2 = d3.scale.ordinal().rangeBands([0, width], 0);
        var y = d3.scale.linear()
            .range([height, 0]);

        var xAxis = d3.svg.axis()
            .scale(x)
            .orient("bottom")
            .tickFormat(function(d, i, dd){
                return d;
            });
        var yAxis = d3.svg.axis()
            .scale(y)
            .orient("left")
            .ticks(10)
            .ticks(5)
            .tickSize(-width, 0, 0)
            .tickFormat(function(value){
                if(options.unit){
                    return unitFilter(value, options.unit.precision, options.unit.quantity, options.unit.scale);
                }else{
                    return ""
                }
            });
        var tick = svg.selectAll('.tick line').style("opacity", "0.1");

        var svg = d3.select('#' + options.svgId)
                    .attr("width", '100%')
                    .attr("height", '100%')
                    .attr('viewBox','0 0 '+(width + margin.left + margin.right)+' '+(height + margin.top + margin.bottom))
                    .attr('preserveAspectRatio','xMinYMin')
                    .append("g")
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        svg.append("text")
            .attr("class", "title")
            .attr("x", (width / 2))
            .attr("y", -5 )
            .attr("text-anchor", "middle")
            .style("font-size", "1em")
            .text("Value vs Date Graph");

        this.updateUnitFn = function(unit){
            options.unit = unit;
            this.updateDataFn(this.data);
        }

//        this.updateSmallFn = function(small){
//            options.small = small;
//            this.updateDataFn(this.data);
//        }

        this.updateDataFn = function(data){
            this.data=data;

            svg.selectAll(".line").data([]).exit().remove();
            svg.selectAll('.bar').data([]).exit().remove();
            svg.selectAll('.bartext').data([]).exit().remove();
            svg.selectAll('.difftext').data([]).exit().remove();
            svg.selectAll('.bartooltipRect').data([]).exit().remove();
            svg.selectAll(".bartooltipText1").data([]).exit().remove();
            svg.selectAll(".bartooltipText2").data([]).exit().remove();
            svg.selectAll(".bartooltipText3").data([]).exit().remove();
            svg.selectAll(".bartooltipText4").data([]).exit().remove();

            svg.selectAll(".title").text(options.title);
            var i=1;
            if(data.length>0){
                _.forEach(data, function(d) {
                    d.date = /*d.scheduled ? moment(d.scheduled).format('YYYY-MM') :*/ d.timestamp;
                    d.value = +d.value;
                    /*d.error = d.error;
                    d.valueStart = d.valueStart;
                    d.valueEnd = d.valueEnd;
                    d.dateStart = d.dateStart;
                    d.dateEnd = d.dateEnd;*/
                });

                x.domain(data.map(function(d) { return d.date; }));
                x2.domain(data.map(function(d) { return d.date; }));
//                y.domain([d3.min(data,function(d){return d.value}), d3.max(data, function(d) { return d.value; })]);
                var min = d3.min(data, function(d) {return !d.value>0 ? 1 : d.value + d.value*0.25; })
                var max = d3.max(data, function(d) {return !d.value<0 ? -1 : d.value + d.value*0.25; })
                if(-min * 10 < max) { min = -max/10}
                if(max * 10 < -min) { max = -min/10}
                y.domain([min, max]);


                var oX = svg.selectAll("g.x.axis");
                if(oX[0].length == 0){
                    oX = svg.append("g")
                       .attr("class", "x axis")
                       .attr("transform", "translate(0," + height + ")");
                }
                oX.call(xAxis).selectAll("text")
                    .style("font-size", "0.7em")
                    .style("alignment-baseline", "hanging")
                    .style("text-anchor", "middle")
                    .style("fill", function(d){
                        var dd = _.find(data, function(dd){ return dd.date == d; });
                        return dd.error==0 ? "#000": dd.error==1 ? "#F00":"#AA0"
                    });

                var start = _.findIndex(data, function(d){return new Date(d.timestamp).getTime()})
                var end = _.findLastIndex(data, function(d){return new Date(d.timestamp).getTime() })
                if (start !== -1 && end !== -1) {
                    var tick = (new Date(data[end].timestamp).getTime() - new Date(data[start].timestamp).getTime())/((end-start) * 1000*60*60);
                    var jump = Math.floor((end-start)/30)+1;

                    xAxis.tickFormat(function(d, i, dd){
                        var dd = _.find(data, function(dd){ return dd.date == d; });
                        var i = _.indexOf(data, dd)
                        if(dd === data[0] || dd === data[data.length-1]){
                            return d;
                        }else{
                            if(i%jump ===0){
                                if(tick<20){
                                    return moment(new Date(d)).format("HH:mm");
                                }else if(tick >= 20 && tick<28){
                                    return moment(new Date(d)).format("dd");
                                }else if(tick>480){
                                    return moment(new Date(d)).format("MM");
                                }else{
                                    return moment(new Date(d)).format("MM-d");
                                }
                            }
                        }
                    });
                }

                var oY = svg.selectAll("g.y.axis");
                if(oY[0].length == 0){
                    oY = svg.append("g")
                        .attr("class", "y axis")
                }
                oY.call(yAxis)
//                .append("text")
//                  .attr("y", 0)
//                  .style("text-anchor", "end")
//                  .text("[" + (unitFilter(1, -1, options.unit, options.scale).trim())+ "]");


              var dataBar = svg.selectAll(".bar");
              dataBar = dataBar.data(data).enter().append('rect').attr('class', 'bar')

                dataBar.style("fill", "steelblue")
                  .attr("x", function(d) { return x.rangeBand()>=80 ? x(d.date)-40+x.rangeBand()/2 : x(d.date); })
                  .attr("width", function(d) { return x.rangeBand()<80?x.rangeBand():80})
//                  .attr("y", function(d) { return y(d.value>0?d.value:0); })
//                  .attr("height", function(d) { return height - y(d.value>0?d.value:0); });
                  .attr("y", function(d) { return y(Math.max(0, d.value)); })
                  .attr("height", function(d) { return Math.abs(y(d.value) - y(0)); })
                dataBar.on("mouseover", function(d) {
                    var selectedBarDate = d.date;
                    var selectedBarValue = d.value;
                    svg.selectAll(".bar")
                        .style("opacity", function(d) {
                            return d.date == selectedBarDate ? 1 : 0.5;
                        })
                    svg.selectAll(".bartext")
                        .style("font-size", function(d) {
                            return d.date == selectedBarDate ? "1em" : "";
                        })
                        .style("font-weight", function(d) {
                            return d.date == selectedBarDate ? "800" : "";
                        })
                        .style("fill", function(d) {
                            return !d.error? d.date == selectedBarDate ? "#333" : "#666" : "#f00";
                        })
                    svg.selectAll(".difftext")
                        .text(function(d){
                            if(!d.error){
                                return d.date != selectedBarDate ? unitFilter(d.value - selectedBarValue, options.unit.precision, options.unit.quantity, options.unit.scale) : "";
                            }else{
                                return "Error";
                            }
                        })
                        .style("fill", function(d) {
                            if(!d.error){
                                return d.date - selectedBarValue==0 ? "#333" : d.value - selectedBarValue>0 ? "#833" : "#338";
                            }else{
                                return "#f00";
                            }
                        })
                    svg.selectAll(".line")
                        .attr("x1", 0)
                        .attr("y1", function(d,i) { return d.date == selectedBarDate ? y(d.value): y(-999999999999999); })
                        .attr("x2", width)
                        .attr("y2", function(d,i) { return d.date == selectedBarDate ? y(d.value): y(-999999999999999); });
                });
                dataBar.on("mouseout",function(d) {
                    svg.selectAll(".bar").style("opacity", '1')
                    svg.selectAll(".bartext").style("font-size", '')
                    svg.selectAll(".bartext").style("font-weight", '')
                    svg.selectAll(".bartext").style("fill", function(d){return !d.error?'#000':"#f00"})
                    svg.selectAll(".difftext").text("")
                    svg.selectAll(".line")
                        .attr("x1", 0)
                        .attr("y1", function(d,i) { return y(-999999999999999); })
                        .attr("x2", width)
                        .attr("y2", function(d,i) { return y(-999999999999999); });
                });

				var lines = svg.selectAll(".line");
				lines.data(data).enter().append("line").attr('class', 'line')
					.attr("x1", 0)
					.attr("y1", function(d,i) { return y(-99999999999); })
					.attr("x2", width)
					.attr("y2", function(d,i) { return y(-99999999999); });


				// Text
                var datBarText = svg.selectAll(".bartext");
                datBarText = datBarText.data(data).enter().append('text').attr('class', 'bartext');
                datBarText
                    .attr('y', function(d,i){return x(d.date) + x.rangeBand()/2;})
                    .attr('x',  function(d){return -y(0) +10;})
                    .attr("transform", "rotate(-90)")
                    .style("text-anchor", "start")
                    .style("alignment-baseline", "middle")
                    .style("font-family", "Open Sans,Arial,sans-serif")
                    .style("cursor", "default")
				    .style("fill", function(d){
                        return d.error==0 ? "#000": "#F00"})
                    .text(function(d){
                        if(!d.error){
                            if(options.unit){
                                return unitFilter(d.value, options.unit.precision, options.unit.quantity, options.unit.scale);
                            }else{
                                return ""
                            }
                        }else{
                            return "Error";
                        }
                    })

                datBarText.on("mouseover", function(d) {
                    var selectedBarValue = d.value;
                    var selectedBarDate = d.date;
                    svg.selectAll(".bar")
                        .style("opacity", function(d) {
                            return d.date == selectedBarDate ? 1 : 0.5;
                        })
                    svg.selectAll(".bartext")
                        .style("font-size", function(d) {
                            return d.date == selectedBarDate ? "1em" : "";
                        })
                        .style("font-weight", function(d) {
                            return d.date == selectedBarDate ? "800" : "";
                        })
                        .style("fill", function(d) {
                            return d.error==0 ? d.date == selectedBarDate ? "#333" : "#666": "#F00";
                        })
                    svg.selectAll(".difftext")
                        .text(function(d){
                            if(!d.error){
                                return d.date != selectedBarDate ? unitFilter(d.value - selectedBarValue, options.unit.precision, options.unit.quantity, options.unit.scale) : "";
                            }else{
                                return "Error";
                            }
                        })
                        .style("fill", function(d) {
                            if(!d.error){
                                return d.value - selectedBarValue==0 ? "#333" : d.value - selectedBarValue>0 ? "#833" : "#338";
                            }else{
                                return "#f00";
                            }
                        })

                        if(d.showBuble){
                            svg.selectAll(".bartooltipRect")
                                .attr("y", function(d) { return d.date == selectedBarDate ? y(yAxis.scale().domain()[1]/2)-27 : "99999999px"; })
                            svg.selectAll(".bartooltipText1")
                                .attr("y", function(d) { return d.date == selectedBarDate ? y(yAxis.scale().domain()[1]/2)-10 : "99999999px"; })
                            svg.selectAll(".bartooltipText2")
                                .attr("y", function(d) { return d.date == selectedBarDate ? y(yAxis.scale().domain()[1]/2)-10 : "99999999px"; })
                            svg.selectAll(".bartooltipText3")
                                .attr("y", function(d) { return d.date == selectedBarDate ? y(yAxis.scale().domain()[1]/2)+10 : "99999999px"; })
                            svg.selectAll(".bartooltipText4")
                                .attr("y", function(d) { return d.date == selectedBarDate ? y(yAxis.scale().domain()[1]/2)+10 : "99999999px"; })
                            svg.selectAll(".line")
                                .attr("x1", 0)
                                .attr("y1", function(d,i) { return d.date == selectedBarDate ? y(d.value): y(-999999999); })
                                .attr("x2", width)
                                .attr("y2", function(d,i) { return d.date == selectedBarDate ? y(d.value): y(-999999999); });
                        }
                    });
                datBarText.on("mouseout",function(d) {
                    svg.selectAll(".bar").style("opacity", '1')
                    svg.selectAll(".bartext").style("font-size", '')
                    svg.selectAll(".bartext").style("font-weight", '')
                    svg.selectAll(".bartext").style("fill", function(d){return !d.error?'#000':"#f00"})
                    svg.selectAll(".difftext").text("")
                    svg.selectAll(".bartooltipRect").attr("y", "99999999px")
                    svg.selectAll(".bartooltipText1").attr("y", "99999999px")
                    svg.selectAll(".bartooltipText2").attr("y", "99999999px")
                    svg.selectAll(".bartooltipText3").attr("y", "99999999px")
                    svg.selectAll(".bartooltipText4").attr("y", "99999999px")
                    svg.selectAll(".line")
                        .attr("x1", 0)
                        .attr("y1", function(d,i) { return y(-999999999); })
                        .attr("x2", width)
                        .attr("y2", function(d,i) { return y(-999999999); });
                });


                // Text
                var diffBarText = svg.selectAll(".difftext");
                diffBarText = diffBarText.data(data).enter().append('text').attr('class', 'difftext');
                diffBarText
                    .attr('y', function(d,i){return x(d.date) + x.rangeBand()/2;})
                    .attr('x',  function(d){return -y(yAxis.scale().domain()[1]) -10;})
                    .attr("transform", "rotate(-90)")
                    .style("text-anchor", "end")
                    .style("alignment-baseline", "middle")
                    .style("font-family", "Open Sans,Arial,sans-serif")
                    .style("fill", "#888")
					.style("pointer-events", "none")
					.text("")

                var toooltipRect = svg.selectAll(".bartooltipRect");
                var toooltipRect = toooltipRect.data(data).enter().append('rect').attr('class', 'bartooltipRect');
                    toooltipRect
                        .style("fill", "#333")
                        .style("opacity", '0.7')
                        .attr("x", function(d) { return x(d.date) + x.rangeBand()/2 -110; })
                        .attr("rx", 6)
                        .attr("width", "220px")
                        .attr("y", "99999999px")
                        .attr("ry", 6)
                        .attr("stroke","black")
                        .attr("stroke-width","2")
                        .attr("height", "45px")
                var toooltipText1 = svg.selectAll(".bartooltipText1");
                var toooltipText1 = toooltipText1.data(data).enter().append('text').attr('class', 'bartooltipText1');
                    toooltipText1
                        .attr("x", function(d) { return x(d.date) + x.rangeBand()/2 -100; })
                        .attr("width", "200px")
                        .attr("y", "99999999px")
                        .attr("height", "50px")
                        .style("text-anchor", "start")
                        .style("fill", "#ddd")
                        .text(function(d){
                            return $filter('date')(d.dateStart, "yyyy-MM-dd HH:mm");
                        })
                var toooltipText2 = svg.selectAll(".bartooltipText2");
                var toooltipText2 = toooltipText2.data(data).enter().append('text').attr('class', 'bartooltipText2');
                    toooltipText2
                        .attr("x", function(d) { return x(d.date) + x.rangeBand()/2 +100; })
                        .attr("width", "200px")
                        .attr("y", "99999999px")
                        .attr("height", "50px")
                        .style("text-anchor", "end")
                        .style("fill", "#ddd")
                        .text(function(d){
                            if(options.unit){
                                return unitFilter(d.valueStart, options.unit.precision, options.unit.quantity, options.unit.scale);
                            }else{
                                return ""
                            }
                        })
                var toooltipText3 = svg.selectAll(".bartooltipText3");
                var toooltipText3 = toooltipText3.data(data).enter().append('text').attr('class', 'bartooltipText3');
                    toooltipText3
                        .attr("x", function(d) { return x(d.date) + x.rangeBand()/2 -100; })
                        .attr("width", "200px")
                        .attr("y", "99999999px")
                        .attr("height", "50px")
                        .style("text-anchor", "start")
                        .style("fill", "#ddd")
                        .text(function(d){
                            return $filter('date')(d.dateEnd, "yyyy-MM-dd HH:mm");
                        })
                var toooltipText4 = svg.selectAll(".bartooltipText4");
                var toooltipText4 = toooltipText4.data(data).enter().append('text').attr('class', 'bartooltipText4');
                    toooltipText4
                        .attr("x", function(d) { return x(d.date) + x.rangeBand()/2 +100; })
                        .attr("width", "200px")
                        .attr("y", "99999999px")
                        .attr("height", "50px")
                        .style("text-anchor", "end")
                        .style("fill", "#ddd")
                        .text(function(d){
                            if(options.unit){
                                return unitFilter(d.valueEnd, options.unit.precision, options.unit.quantity, options.unit.scale);
                            }else{
                                return ""
                            }
                        });
            }
        }

//        this.updateDataFn([]);
    }

    return {
        restrict : 'A',
        scope: {data : "=", unit : "=", title : "=", small : "="},
        link : function(scope, elem, attrs) {
            function redraw() {
                if (!scope.redrawPromise) {
                    scope.redrawPromise = $timeout(function () {
                        scope.chart.options({
                            width: elem.parent().innerWidth(),
                            height: elem.parent().innerHeight(),
                            small: scope.small
                        });
                        delete scope.redrawPromise;
                    });
                }
            }

            elem.addClass("shift-bar-graph");
            scope.options={}
            scope.options.svgId = "bar-graph" + shortid.gen()
            scope.options.small = scope.small;
            scope.options.title = scope.title;
            scope.options.width = 500;
            scope.options.height = 0.9*scope.options.width*$window.innerHeight/$window.innerWidth;

            scope.chart = new BarGraph(d3.select(elem[0]).append('svg').attr("id", scope.options.svgId), scope.options);

            scope.$on(DashboardEvents.REDRAW, function (event, data) {
                if (data){
                    scope.data = data;
                }
                redraw();
            });

            scope.$on(DashboardEvents.RESIZE, function (event) {
                redraw();
            });

            scope.$watch('data', function(newValue, oldValue) {
                scope.chart.updateDataFn(newValue)
            });

            scope.$watch('unit', function(newValue, oldValue) {
                scope.chart.updateUnitFn(newValue);
                redraw();
            });
//
//                    scope.$watch('small', function(newValue, oldValue) {
//console.log("testowanie");
//                        scope.chart.updateSmallFn(newValue);
//                        redraw();
//                    });

            scope.$on('$destroy', function () {
//                        scope.chart.destroy();
            });

            redraw();
        }
    }
});
