/* global tools */
/* jshint latedef:nofunc */
var module = angular.module('meternet.data.services', ['meternet.stomp.services']);

var MAX_MISSING_SEARCH_PARAMETERS = 2;

module.service('dataService', function ($q, $rootScope, $location, $timeout, $http, contextPath, stompClient, dataServiceCache) {
    var url = "/data";

    var subscriptions = {};

    var client = stompClient(url);
    var deferred = $q.defer();
    var connectionPromise = deferred.promise;
    $timeout(function () {
        client.connect().then(function () {
            deferred.resolve();
        }, function () {
            deferred.reject();
        });
    }, 0);
    var buildRequestParamDataQuery = function (searchParameters) {
        return '/' + _.random(0, 1000000);
    };

    var dataService = {
        subscribe: function (topic, callback) {
            if (!subscriptions[topic]) {
                subscriptions[topic] = {
                    callbacks: {}
                };
                connectionPromise.then(function () {
                    subscriptions[topic].id = client.subscribe(topic, function (frame) {
                        var parsedData = JSON.parse(frame.body);
                        var i = 0;
                        _.each(subscriptions[topic].callbacks, function (callback) {
                            if (typeof (callback) === 'function') {
                                if (i > 0) {
                                    callback(angular.copy(parsedData));
                                } else {
                                    callback(parsedData);
                                }
                            }
                            i++;

                        });
                        $rootScope.$broadcast(topic, parsedData);
                    });
                });
            }
            var subscriptionId = _.random(0, 10000);
            subscriptions[topic].callbacks[subscriptionId] = callback;
            return subscriptionId;
        },
        unsubscribe: function (topic, subscriptionId) {
            if (!subscriptions[topic]) {
                return;
            }
            delete subscriptions[topic].callbacks[subscriptionId];

            connectionPromise.then(function () {
                if (typeof (subscriptions[topic]) === 'undefined') {
                    return;
                }
                if (Object.keys(subscriptions[topic].callbacks).length === 0) {
                    client.unsubscribe(subscriptions[topic].id);
                    delete subscriptions[topic];
                }
            });

        },
        // --------------------------requestParamData--------------------------
        requestParamData: function (paramId, query) {
            var start = (new Date()).getTime();
            var deferred = $q.defer();
            var now = new Date();

            deferred.promise.cancel = function () {
                deferred.reject();
            };

            var searchParameters = {
                timeFrom: query.timeFrom,
                timeTo: query.timeTo,
                paramId: paramId
            };

            var self = this;
            var max = 0;
            var min = 100000;
            var times = [];
            var previousCacheResult;
            dataServiceCache.load(searchParameters).then(
                function (cacheResult) {
                    if (cacheResult.missingSearchParameters.length === 0) {
                        deferred.resolve(cacheResult.measurements);
                        return;
                    }
                    previousCacheResult = cacheResult;
                    var currentMissingSearchParameters;
                    var responseCounter = 0;

                    connectionPromise.then(function () {
                        var queryParams = buildRequestParamDataQuery(searchParameters);
                        var dest = '/app/h' + queryParams;
                        var topic = '/topic/h' + queryParams;

                        var sendReq = function (searchParameters) {
                            var payload = JSON.stringify(searchParameters);
                            client.send(dest, null, payload);
                        };
                        var sendReqs = function (cacheResult) {
                            responseCounter = 0;
                            currentMissingSearchParameters = cacheResult.missingSearchParameters;
                            _.each(cacheResult.missingSearchParameters, sendReq);
                        };

                        var subscriptionId = self.subscribe(topic, function (response) {

                            // var start = (new Date()).getTime();
                            responseCounter++;

                            var serverTime = new Date(response.serverTime);
                            if (serverTime < searchParameters.timeTo) {
                                searchParameters.timeTo = serverTime;
                            }

                            if (response.serverTime >= response.timeFrom) {
                                var cacheEntry = new CacheEntry(response);
                                dataServiceCache.save(cacheEntry);
                            }

                            if (responseCounter < currentMissingSearchParameters.length) {
                                return;
                            }
                            dataServiceCache.load(searchParameters, previousCacheResult).then(
                                function (updatedCacheResult) {
                                    previousCacheResult = updatedCacheResult;
                                    if (updatedCacheResult.missingSearchParameters.length === 0) {
                                        deferred.resolve(updatedCacheResult.measurements);
                                        self.unsubscribe(topic, subscriptionId);
                                        var total = (new Date()).getTime();

                                        // console.log(total-start +
                                        // ' ms');

                                    } else {
                                        if (deferred.promise.$$state.status === 2) {
                                            self.unsubscribe(topic, subscriptionId);
                                        } else {
                                            sendReqs(updatedCacheResult);
                                            $timeout(function () {
                                                deferred.notify(updatedCacheResult.measurements);
                                            }, 0);
                                        }

                                    }
                                });
                        });
                        $timeout(function () {
                            connectionPromise.then(sendReqs(cacheResult));
                        }, 0);
                    }, function () {
                        deferred.reject();
                    });
                });
            return deferred.promise;

        },
// --------------------------requestParamDataDaily--------------------------
        requestParamDataDaily: function (paramId, query) {
            var deferred = $q.defer();
            var self = this;

            var searchParameters = {
                timeFrom: query.timeFrom,
                timeTo: query.timeTo,
                paramId: paramId
            };

            connectionPromise.then(function () {
                var queryParams = buildRequestParamDataQuery(searchParameters);
                var dest = '/app/d' + queryParams;
                var topic = '/topic/d' + queryParams;

                var subscriptionId = self.subscribe(topic, function (data) {
                    deferred.resolve(data);
                    self.unsubscribe(topic, subscriptionId);
                });
                $timeout(function () {
                    var payload = JSON.stringify(searchParameters);
                    client.send(dest, null, payload);
                }, 0);

            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        },
        requestParamLastData: function (paramId) {
            var deferred = $q.defer();
            var self = this;

            connectionPromise.then(function () {
                var dest = '/app/l/' + paramId;
                var topic = '/topic/l/' + paramId;

                var subscriptionId = self.subscribe(topic, function (data) {
                    deferred.resolve(new Moment(data));
                    self.unsubscribe(topic, subscriptionId);
                });
                $timeout(function () {
                    client.send(dest, null, {});
                }, 0);

            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        },
        // --------------requestUserData------------------------------------
        requestUserData: function () {
            var deferred = $q.defer();
            var self = this;

            connectionPromise.then(function () {

                var dest = '/app/u/d';
                var topic = '/topic/u/d';

                var subscriptionId = self.subscribe(topic, function (data) {
                    deferred.resolve(data);
                    self.unsubscribe(topic, subscriptionId);
                });
                connectionPromise.then(function () {
                    client.send(dest, null, null);
                });
            }, function () {
                deferred.reject();
            });
            return deferred.promise;

        },
        // --------------updateUserData------------------------------------
        updateUserData: function (user) {
            var deferred = $q.defer();
            var self = this;

            connectionPromise.then(function () {
                var dest = '/app/u/n/uid/' + _.random(0, 1000000);
                var topic = '/topic/u/n/uid/' + _.random(0, 1000000);

                var subscriptionId = self.subscribe(topic, function (data) {
                    deferred.resolve(new Moment(data));
                    self.unsubscribe(topic, subscriptionId);
                });
                connectionPromise.then(function () {
                    client.send(dest, null, user);
                });
            }, function () {
                deferred.reject();
            });
            return deferred.promise;

        },
        // --------------requestClock------------------------------------
        requestClock: function () {
            var deferred = $q.defer();
            var self = this;

            connectionPromise.then(function () {

                var dest = '/app/clock';
                var topic = '/topic/clock';

                var subscriptionId = self.subscribe(topic, function (data) {

                    deferred.resolve(data);
                    self.unsubscribe(topic, subscriptionId);
                });
                connectionPromise.then(function () {
                    client.send(dest, null, null);
                });
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        },
        // --------------updateClock------------------------------------
        updateClock: function (newTime) {
            var deferred = $q.defer();
            var self = this;

            connectionPromise.then(function () {
                var dest = '/app/clock/';
                var topic = '/topic/clock/';

                var subscriptionId = self.subscribe(topic, function (data) {

                    deferred.resolve(data);
                    self.unsubscribe(topic, subscriptionId);
                });
                connectionPromise.then(function () {
                    client.send(dest, null, JSON.stringify({
                        time: newTime
                    }));
                });
            }, function () {
                deferred.reject();
            });
            return deferred.promise;

        },
        // --------------requestNetwork------------------------------------
        requestNetwork: function () {
            var deferred = $q.defer();
            var self = this;

            connectionPromise.then(function () {

                var dest = '/app/network';
                var topic = '/topic/network';

                var subscriptionId = self.subscribe(topic, function (data) {
                    deferred.resolve(data);
                    self.unsubscribe(topic, subscriptionId);
                });
                connectionPromise.then(function () {
                    client.send(dest, null, null);
                });
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        },
        // --------------updateNetwork------------------------------------
        updateNetwork: function (network) {
            var deferred = $q.defer();
            var self = this;

            connectionPromise
                .then(
                function () {

                    var dest = '/app/network/address/' + network.address + '/mask/' + network.mask + '/gateway/' + network.gateway;
                    var topic = '/topic/network/address/' + network.address + '/mask/' + network.mask + '/gateway/' + network.gateway;
                    var subscriptionId = self.subscribe(topic, function (data) {

                        deferred.resolve(data);
                        self.unsubscribe(topic, subscriptionId);
                    });
                    connectionPromise.then(function () {
                        client.send(dest, null, null);
                    });
                }, function () {
                    deferred.reject();
                });
            return deferred.promise;

        },
        // --------------serviceStatus------------------------------------
        serviceStatus: function (command) {
            var deferred = $q.defer();
            var url = contextPath + '/serviceStatus/';
            $http({
                method: 'GET',
                url: url,
                params: {
                    'command': command
                }
            }).success(function (data, status, headers) {
                deferred.resolve(data);
            }).error(function () {
                deferred.reject();
            });
            return deferred.promise;

        },
        // --------------updateMeternet------------------------------------
        updateMeternet: function () {
            var deferred = $q.defer();
            var self = this;
            connectionPromise.then(function () {

                var dest = '/app/updatemeternet';
                var topic = '/topic/updatemeternet';

                var subscriptionId = self.subscribe(topic, function (data) {
                    deferred.resolve(data);
                    self.unsubscribe(topic, subscriptionId);
                });
                connectionPromise.then(function () {
                    client.send(dest, null, null);
                });
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        },
        subscribeForParametersMeasurements: function (paramId, callback) {
            if (typeof (paramId) === 'undefined') {
                return function () {
                };
            }
            var paramterMomentTopic = "/topic/pm/" + paramId;
            var self = this;
            var wrappingCallback = function (data) {
                callback(new Moment(data));
            }
            var subscriptionId = this.subscribe(paramterMomentTopic, wrappingCallback);
            return function () {
                self.unsubscribe(paramterMomentTopic, subscriptionId);
            };
        },
        onDataConnectionStatusChange: function ($scope, callback) {
            client.onConnectionStatusChange($scope, callback);
            return client.isConnected;
        },
        getEnergyReportData: function (energyReportId, endTime, count) {
            var deferred = $q.defer();
            deferred.promise.cancel = function () {
                deferred.reject();
            };

            connectionPromise.then(function () {
                var queryParams = 'energy/r/uid/' + _.random(0, 1000000);
                var dest = '/app/' + queryParams;
                var topic = '/topic/' + queryParams;
                client.ajax({
                    dest: dest,
                    topic: topic
                }, {
                    energyReportId: energyReportId,
                    endTime: endTime,
                    count: count
                }).then(function (data) {
                    for (var column in data.data) {
                        var columnData = data.data[column];
                        for (var i in columnData.values) {
                            columnData.values[i] = new Measurement(columnData.values[i]);
                        }
                    }
                    deferred.resolve(data);
                }, function () {
                    deferred.reject();
                });
            });
            return deferred.promise;
        },
        getBarGraphData: function (paramId, dateTo, retain, count) {
            var deferred = $q.defer();
            deferred.promise.cancel = function () {
                deferred.reject();
            };

            connectionPromise.then(function () {
                var queryParams = 'bargraph/r/uid/' + _.random(0, 1000000);
                var dest = '/app/' + queryParams;
                var topic = '/topic/' + queryParams;
                client.ajax({
                    dest: dest,
                    topic: topic
                }, {
                    paramId: paramId,
                    retain: retain || 15,
                    dateTo: dateTo,
                    count: count
                }).then(function (data) {
                    for (var column in data.data) {
                        var columnData = data.data[column];
                        for (var i in columnData.values) {
                            columnData.values[i] = new Measurement(columnData.values[i]);
                        }
                    }
                    deferred.resolve(data);
                }, function () {
                    deferred.reject();
                });
            });
            return deferred.promise;
        }
    };
    return dataService;
});

function Moment(data) {
    this.id = null;
    this.readCount = null;
    this.errorCount = null;
    this.quality = null;
    this.good = null;
    this.current = null;
    this.previous = null;
    this.update(data);
}

Moment.prototype.update = function (data) {
    if (data) {
        this.id = data.id;
        this.readCount = data.readCount || 0;
        this.errorCount = data.errorCount || 0;
        this.quality = data.quality || 0;
        this.good = data.good ? new Measurement(data.good) : undefined;
        this.current = data.current ? new Measurement(data.current) : undefined;
        this.previous = data.previous ? new Measurement(data.previous) : undefined;
    }
};

// Measurement Type definition
function Measurement(data) {
    this.paramId = null;
    this.timestamp = null;
    this.value = NaN;
    this.avg15 = null;
    this.inc15 = null;
    this.min15 = null;
    this.max15 = null;
    this.update(data);
}

Measurement.prototype.update = function (data) {
    if (data) {
        if (data instanceof Array) {
            this.paramId = data[0];
            this.timestamp = new Date(data[1]);
            this.value = data[2] != null ? data[2] : NaN;
            this.retryCount = data[3];
            this.avg15 = data[6];
            this.inc15 = data[7];
            this.min15 = data[8];
            this.max15 = data[9];
        } else {
            this.paramId = data.paramId;
            this.timestamp = new Date(data.timestamp);
            this.value = data.value != null ? data.value : NaN;
            this.retryCount = data.retryCount;
            this.avg15 = data.avg15 != null ? data.avg15 : NaN;
            this.inc15 = data.inc15 != null ? data.inc15 : NaN;
            this.min15 = data.min15 != null ? data.min15 : NaN;
            this.max15 = data.max15 != null ? data.max15 : NaN;
        }
    }
};

Measurement.prototype.toJSON = function () {
    return [
        this.paramId,
        this.timestamp,
        isFinite(this.value) ? this.value : null,
        this.retryCount
    ];
};

var measurementComparator = function (a, b) {
    return a.timestamp.getTime() - b.timestamp.getTime();
};

var keyNames = {};
var time;
var getKeyName = function (date) {
    if (!date) {
        return undefined;
    }
    time = date.getTime();
    if (keyNames.hasOwnProperty(time)) {
        return keyNames[time];
    } else {
        keyNames[time] = moment(date).utc().format("_YYYYMMDD_HH");
        return keyNames[time];
    }
};

function CacheEntry(data) {
    this.update(data);
}
CacheEntry.prototype.update = function (data) {

    this.paramId = data.paramId;
    this.timeFrom = new Date(data.timeFrom);
    this.timeTo = new Date(data.timeTo);
    this.cacheKey = this.paramId + getKeyName(this.timeFrom);
    // this.measurements = new tools.List({compare: measurementComparator });
    this.measurements = [];
    if (data.measurements) {
        for (var i = 0; i < data.measurements.length; ++i) {
            this.measurements.push(new Measurement(data.measurements[i]));
        }
    }
    this.measurements.sort(measurementComparator);
};

function ParamCache(paramId, data) {
    this.paramId = paramId;

    this.update(data);
}
ParamCache.prototype.update = function (data) {
    this.entries = {};
    if (!data) {
        return;
    }
    if (data.creationTime){
        this.creationTime = new Date(data.creationTime);
     } else{
        this.creationTime = new Date();
     }
    var key;
    if (data.entries instanceof Array) {
        for (var i in data.entries) {
            key = getKeyName(data.entries[i].timeFrom);
            this.entries[key] = new CacheEntry(data.entries[i]);
        }
    } else {
        for (key in data.entries) {
            this.entries[key] = new CacheEntry(data.entries[key]);
        }
    }
};

ParamCache.prototype.query = function (searchParameters, previousResult) {
    if (!searchParameters) {
        throw new Error("No searchParameters!");
    }
    if (searchParameters.paramId !== this.paramId) {
        throw new Error(
            "Searching data for paramId=" + searchParameters.paramId + " in cache for paramId" + this.paramId);
    }
    var missingSearchParameters = [];
    var matchedEntries = [];
    var timeFrom = moment(searchParameters.timeFrom).add(-1, 'hours');
    var timeTo = moment(searchParameters.timeTo);
    if (previousResult && previousResult.missingSearchParameters.length > 0) {

        var tt = moment(previousResult.missingSearchParameters[0].timeTo);
        timeTo = timeTo.isBefore(tt) ? timeTo : tt;

    }

    var key, dateFrom = timeTo.clone().startOf('h'), dateTo = timeTo.clone().endOf('h');
    for (var m = timeTo.clone(); !m.isBefore(timeFrom) && missingSearchParameters.length < MAX_MISSING_SEARCH_PARAMETERS; m
        .add(-1, 'h'), dateFrom.add(-1, 'h'), dateTo.add(-1, 'h')) {
        key = getKeyName(dateFrom.toDate());
        if (!this.entries.hasOwnProperty(key)) {
            missingSearchParameters.push({
                timeFrom: dateFrom.clone(),
                timeTo: dateTo.clone(),
                paramId: searchParameters.paramId
            });
        } else {
            var entry = this.entries[key];
            if (dateTo.toDate().getTime() !== entry.timeTo.getTime() && timeTo.isAfter(entry.timeTo)) {
                missingSearchParameters.push({
                    timeFrom: entry.timeTo,
                    timeTo: dateTo.clone(),
                    paramId: searchParameters.paramId
                });
            }
            if (missingSearchParameters.length === 0) {
                matchedEntries.push(this.entries[key]);
            }
        }
    }
    var measurementsArr = [];
    if (previousResult && previousResult.measurements.length > 0) {
        measurementsArr = previousResult.measurements;
    }
    var measurementToPush;
    var measurementFilter = function (m) {
        return m.timestamp < searchParameters.timeTo;
    };
    for (var i = 0; i < matchedEntries.length; i++) {
        if (matchedEntries[i].measurements.length > 0) {
            measurementToPush = matchedEntries[i].measurements;
            if (matchedEntries[i].timeTo > searchParameters.timeTo) {
                measurementToPush = _.filter(measurementToPush, measurementFilter);
            }
            if (measurementToPush.length > 0) {
                measurementsArr.unshift.apply(measurementsArr, measurementToPush);
            }
        }
    }

    return {
        missingSearchParameters: missingSearchParameters,
        measurements: measurementsArr
    };
};

ParamCache.prototype.merge = function (cacheEntry) {
    var key = getKeyName(cacheEntry.timeFrom);

    if (this.entries.hasOwnProperty(key)) {
        var self = this;
        var measurementsToMerge = _.filter(cacheEntry.measurements, function (m) {
            return self.entries[key].timeTo < m.timestamp;
        });
        this.entries[key].measurements.push.apply(this.entries[key].measurements, measurementsToMerge);
        this.entries[key].measurements.sort(measurementComparator);
        this.entries[key].timeTo = cacheEntry.timeTo;
    } else {
        this.entries[key] = cacheEntry;
    }
    return this.entries[key];
};

module.config(function ($indexedDBProvider) {
    var conn = $indexedDBProvider.connection('meternet');
    conn.upgradeDatabase(1, function (event, db, tx) {

        var objStore = db.createObjectStore('cacheEntries', {
            keyPath: 'cacheKey'
        });
        objStore.createIndex('paramId_idx', 'paramId', {
            unique: false
        });
        // objStore.createIndex('age_idx', 'age', {unique: false});
    });
    conn.upgradeDatabase(2, function (event, db, tx) {

        var objStore = db.createObjectStore('cacheInfo', {
            keyPath: 'paramId'
        });
        objStore.createIndex('paramId_idx', 'paramId', {
            unique: true
        });

    });
});

function DataServiceCache($timeout, $q, $rootScope, localStorageService, $indexedDB) {

    var measurementsCache = {};

    var loadFromLocalStorage = function (paramId) {

        var deferred = $q.defer();
        var internalDeferred = $q.defer();
        $indexedDB.openStore('cacheEntries', function (store) {

            var query = store.query().$index("paramId_idx").$eq(paramId);
            store.findWhere(query).then(function (entries) {
                
                
                var paramCache = {
                    entries: entries
                };
                internalDeferred.resolve(new ParamCache(paramId, paramCache));
            }, function (msg) {
                internalDeferred.resolve(new ParamCache(paramId));
            });

        }, "readonly");
       
        internalDeferred.promise.then(function(paramCache){
            $indexedDB.openStore('cacheInfo', function (store) {

                store.findBy('paramId_idx', paramId).then(function (param) {
                    if (param){
                        paramCache.creationTime = new Date(param.creationTime);
                    } else {
                        paramCache.creationTime = new Date();
                        store.upsert({
                            paramId : paramId,
                            creationTime : paramCache.creationTime
                        });
                        deferred.resolve(paramCache);
                    }
                    deferred.resolve(paramCache);
                }, function (msg) {
                                    
                    paramCache.creationTime = new Date();
                    store.upsert({
                        paramId : paramId,
                        creationTime : paramCache.creationTime
                    });
                    deferred.resolve(paramCache);
                });

            }, "readwrite");
        });
        // var paramCache = new ParamCache(paramId,
        // localStorageService.get(localStorageParamKey(paramId)))
        // || new ParamCache(paramId);
        return deferred.promise;
    };
    var entriesToUpsert = [];

    var flushToIndexedDb = function () {
        if (!window.indexedDB) {

        }
        // var start = (new Date).getTime();
        $indexedDB.openStore('cacheEntries', function (store) {
            var arr = entriesToUpsert;
            entriesToUpsert = [];
            if (arr.length > 0) {
                store.upsert(arr);
                /*
                 * .then(function() { console.log('saved '+arr.length+' in
                 * '+((new Date).getTime()- start)+' ms'); });
                 */
            }
        }, "readwrite");
        

    };
    var upsertThrottle = _.throttle(flushToIndexedDb, 2000, {
        leading: false
    });
    // API
    return {
        load: function (searchParameters, previousResult) {

            var deferred = $q.defer();
            if (!measurementsCache[searchParameters.paramId]) {
                measurementsCache[searchParameters.paramId] = loadFromLocalStorage(searchParameters.paramId);
            }
            var promise = measurementsCache[searchParameters.paramId];
            promise.then(function (paramCache) {
                deferred.resolve(paramCache.query(searchParameters, previousResult));
            });

            return deferred.promise;
        },
        save : function(cacheEntry) {
            var paramId = cacheEntry.paramId;
            if (!measurementsCache[paramId]) {
                measurementsCache[paramId] = loadFromLocalStorage(paramId);
            }
            measurementsCache[paramId].then(function(paramCache) {
                var merged = paramCache.merge(cacheEntry);
                entriesToUpsert.push(merged);
                upsertThrottle();
            });
        },

        clear : function(paramId) {
            
            var deferred = $q.defer();

            if (!paramId) {
                $indexedDB.openStore('cacheEntries', function(store) {
                    store.clear();
                    $indexedDB.openStore('cacheInfo', function(cacheInfoStore) {

                        for ( var id in measurementsCache) {
                            measurementsCache[id].then(function(cache) {
                                cache.update({
                                    creationTime : new Date()
                                });
                                cacheInfoStore.upsert({
                                    paramId : cache.paramId,
                                    creationTime : cache.creationTime
                                });
                            });
                        }
                        deferred.resolve();
                    }, "readwrite");
                   
                }, "readwrite");
            } else {
                if (!measurementsCache[paramId]) {
                    measurementsCache[paramId] = loadFromLocalStorage(paramId);
                }
                measurementsCache[paramId].then(function(cache) {
                    $indexedDB.openStore('cacheEntries', function(store) {
                        var deletePromises = [];
                        for ( var key in cache.entries) {
                            deletePromises.push(store['delete'](paramId + key));
                        }
                        $q.all(deletePromises).then(function() {
                            $indexedDB.openStore('cacheInfo', function(cacheInfoStore) {
                            cache.update({
                                creationTime : new Date()
                            });
                            cacheInfoStore.upsert({
                                paramId : cache.paramId,
                                creationTime : cache.creationTime
                            }).then(function(){
                                deferred.resolve();
                            });
                            
                            }, "readwrite");
                        });

                    }, "readwrite");
                });
            }
            return deferred.promise;
        }
    };
}

module.service('dataServiceCache', DataServiceCache);
