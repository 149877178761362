<div class="row" x-errors="item.errors" x-errors-root="item.config">
    <div x-ng-if="item.config.type==='toggle'">
        <div class="col-xs-12">
            <div class="form-group">
                <label class="form-group-label">{{'config.name' | i18n}}</label>
                <div class="col-sm-12 input-group">
                    <input class="form-control"
                           x-ng-change="ui.updateNotifie(item.config)"
                           x-ng-model="item.config.name"
                           x-field-help="config.rule.name.help"
                           x-field-errors="'item.config.name'"/>
                </div>
            </div>
            <label class="form-group-label">{{'config.momentRule.notifiers' | i18n}}</label>
            <div class="form-group" x-ng-if="config.engine.notifiers.length==0">
                <!--<div class="input-group">-->
                <select class="form-control" disabled
                        x-field-help="config.item.config.notifiers.help"
                        x-field-errors="'item.config.positive.actions[0].notifierId'">
                    <option>{{'config.momentRule.notifier.noNotifier' | i18n}}</option>
                </select>
                <!--</div>-->
            </div>
            <div class="form-group" x-ng-if="config.engine.notifiers.length!=0">
                <div class="input-group">
                    <select class="form-control"
                            x-ng-change="ui.updateNotifie(item.config)"
                            x-ng-model="item.config.positive.actions[0].notifierId"
                            x-ng-options="n.id as (n.label||n.name ) for n in config.engine.notifiers"
                            x-field-help="config.item.config.notifiers.help"
                            x-field-errors="'item.config.positive.actions[0].notifierId'"></select>
                </div>
            </div>
            <div class="form-group">
                <label class="form-group-label">{{'config.momentRule.notifier.to' | i18n}}</label>
                <div class="col-sm-12 input-group">
                    <input class="form-control"
                           x-ng-change="ui.updateNotifie(item.config)"
                           x-ng-model="item.config.positive.actions[0].to"
                           x-field-help="config.rule.notifier.to.help"
                           x-field-errors="'item.config.positive.actions[0].to'"/>
                </div>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="col-xs-6">
                <svg width="200" height="100" x-ng-if="item.config.updown">
                    <defs>
                        <marker id="arrow" markerWidth="10" markerHeight="10" refX="4" refY="2" orient="auto-start-reverse"
                                markerUnits="strokeWidth">
                            <path d="M0,0 L0,4 L5,2" fill="#000"/>
                        </marker>
                    </defs>
                    <line x1="0" y1="50" x2="200" y2="50" stroke="#000" stroke-width="2" marker-end="url(#arrow)"/>

                    <text x="50" y="90" fill="red">{{'config.momentRule.notifier.endalarm' | i18n}}</text>
                    <text x="50" y="60" fill="red">{{ui.unitFilterFun(item.config.value-item.config.hysteresis, item.config)}}</text>
                    <line x1="0" y1="75" x2="130" y2="75" stroke="#0f0" stroke-width="2" marker-end="url(#arrow)"/>
                    <line x1="70" y1="25" x2="70" y2="75" stroke="#0f0" stroke-width="2" marker-end="url(#arrow)"/>

                    <text x="110" y="20" fill="red">{{'config.momentRule.notifier.alarm' | i18n}}</text>
                    <text x="110" y="60" fill="red">{{ui.unitFilterFun(item.config.value, item.config)}}</text>
                    <line x1="70" y1="25" x2="200" y2="25" stroke="#f00" stroke-width="2" marker-start="url(#arrow)"/>
                    <line x1="130" y1="75" x2="130" y2="25" stroke="#f00" stroke-width="2" marker-end="url(#arrow)"/>

                </svg>
                <svg width="200" height="100" x-ng-if="!item.config.updown">
                    <defs>
                        <marker id="arrow2" markerWidth="10" markerHeight="10" refX="4" refY="2" orient="auto-start-reverse"
                                markerUnits="strokeWidth">
                            <path d="M0,0 L0,4 L5,2" fill="#000"/>
                        </marker>
                    </defs>
                    <line x1="0" y1="50" x2="200" y2="50" stroke="#000" stroke-width="2" marker-end="url(#arrow2)"/>

                    <text x="50" y="90" fill="red">{{'config.momentRule.notifier.alarm' | i18n}}</text>
                    <text x="50" y="60" fill="red">{{ui.unitFilterFun(item.config.value, item.config)}}</text>
                    <line x1="0" y1="75" x2="130" y2="75" stroke="#f00" stroke-width="2" marker-end="url(#arrow2)"/>
                    <line x1="70" y1="25" x2="70" y2="75" stroke="#f00" stroke-width="2" marker-end="url(#arrow2)"/>

                    <text x="110" y="20" fill="red">{{'config.momentRule.notifier.endalarm' | i18n}}</text>
                    <text x="110" y="60" fill="red">{{ui.unitFilterFun(item.config.value+item.config.hysteresis, item.config)}}</text>
                    <line x1="70" y1="25" x2="200" y2="25" stroke="#0f0" stroke-width="2" marker-start="url(#arrow2)"/>
                    <line x1="130" y1="75" x2="130" y2="25" stroke="#0f0" stroke-width="2" marker-end="url(#arrow2)"/>
				</svg>
			</div>
			<div class="col-xs-6">
				<div class="form-group">
					<label class="form-group-label">{{'config.momentRule.direction' | i18n}}</label>
					<div class="col-sm-12 input-group">
						<select class="form-control"
								x-ng-change="ui.updateExpresion(item.config)"
								x-ng-model="item.config.updown"
								x-field-help="config.rule.direction.help"
								x-ng-options="n.value as n.name for n in hysteresisDir"
								x-field-errors="'item.config.actions[0].notifierId'">
						</select>
					</div>
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.momentRule.alarm.value' | i18n}}</label>
					<div class="col-sm-10 input-group">
						<input class="form-control"
							   placeholder="{{'config.momentRule.alarm.value' | i18n }}"
							   x-ng-model="item.config.value"
							   data-scale-formatter=""
							   data-format-scale="item.config.scale"
							   data-format-precision="item.config.precision"
							   x-required=""
							   x-ng-change="ui.updateExpresion(item.config)"
							   x-field-errors="'item.config.actions[0].message'"/>
						<data-unit-addon data-unit-addon-scale="item.config.scale" data-unit-addon-unit=item.config.unit></data-unit-addon>
					</div>
					<button class="btn btn-primary col-sm-2 pull-right" type="button" uib-popover="{{'config.rule.notifier.value.help' | i18n}}"
							popover-trigger="mouseenter" popover-placement="bottom">?</button>
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.momentRule.alarm.hysteresis' | i18n}}</label>
					<div class="col-sm-10 input-group">
						<input class="form-control"
							   placeholder="{{'config.momentRule.alarm.hysteresis' | i18n }}"
							   x-ng-model="item.config.hysteresis"
							   data-scale-formatter=""
							   data-format-scale="item.config.scale"
							   data-format-precision="item.config.precision"
							   x-required=""
							   x-ng-change="ui.updateExpresion(item.config)"
							   x-field-errors="'item.config.actions[0].message'"/>
						<data-unit-addon data-unit-addon-scale="item.config.scale" data-unit-addon-unit=item.config.unit></data-unit-addon>
					</div>
					<button class="btn btn-primary col-sm-2 pull-right" type="button" uib-popover="{{'config.rule.notifier.hysteresis.help' | i18n}}"
							popover-trigger="mouseenter" popover-placement="bottom">?</button>
				</div>
			</div>
		</div>
		<div class="col-xs-12 list-group-item-danger" style="padding: 10px; margin-top: 10px;">
			<h4>{{'config.momentRule.notifier.positive.title' | i18n}}</h4>
			<div class="form-group" x-ng-if="item.config.notifier!='sms'">
				<label class="form-group-label">{{'config.momentRule.notifier.subject' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<input class="form-control"
						   x-ng-change="ui.updateNotifie(item.config)"
						   x-ng-model="item.config.positive.actions[0].subject"
						   x-field-help="config.rule.notifier.subject.help"
						   x-field-errors="'item.config.positive.actions[' + $index + '].subject'"/>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].subject = item.config.positive.actions[0].subject + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].subject = item.config.positive.actions[0].subject + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>

			<div class="form-group">
				<label class="form-group-label">{{'config.momentRule.notifier.message' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<textarea class="form-control span6" rows="3"
							  x-ng-change="ui.updateNotifie(item.config)"
							  x-ng-model="item.config.positive.actions[0].message"
							  x-field-errors="'item.config.positive.actions[0].message'"/>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].message = item.config.positive.actions[0].message + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].message = item.config.positive.actions[0].message + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>
		</div>
		<div class="col-xs-12 list-group-item-success" style="padding: 10px; margin-top: 10px;">
			<button type="button" class="btn btn-link pull-right" x-ng-click="ui.setNegativeAction()"
					style="background-image: none; outline: 0; -webkit-box-shadow: none; box-shadow: none; text-decoration: none;">
				<i class="fa fa-check-square-o" aria-hidden="true" x-ng-if="item.config.negative.actions[0]"></i>
				<i class="fa fa-square-o" aria-hidden="true" x-ng-if="!item.config.negative.actions[0]"></i>
				{{'config.momentAction.type.notifier' | i18n}}
			</button>
			<h4>
				{{'config.momentRule.notifier.negative.title' | i18n}}
			</h4>
			<div class="form-group" x-ng-if="item.config.notifier!='sms'">
				<label class="form-group-label">{{'config.momentRule.notifier.subject' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<input class="form-control"
						   x-ng-disabled="!item.config.negative.actions[0]"
						   x-ng-change="ui.updateNotifie(item.config)"
						   x-ng-model="item.config.negative.actions[0].subject"
						   x-field-help="config.rule.notifier.subject.help"
						   x-field-errors="'item.config.negative.actions[' + $index + '].subject'"/>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].subject = item.config.negative.actions[0].subject + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].subject = item.config.negative.actions[0].subject + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>
			<div class="form-group">
				<label class="form-group-label">{{'config.momentRule.notifier.message' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<textarea class="form-control span6" rows="3"
							  x-ng-disabled="!item.config.negative.actions[0]"
							  x-ng-change="ui.updateNotifie(item.config)"
							  x-ng-model="item.config.negative.actions[0].message"
							  x-field-errors="'item.config.negative.actions[0].message'"/>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].message = item.config.negative.actions[0].message + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].message = item.config.negative.actions[0].message + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>
		</div>
	</div>
</div>

