var s = window.s;

var module = angular.module('meternet.utils.ui.directives', [ 'angularSpectrumColorpicker' ]);

var colorPickerOpts = {
    showInput : true,
    showPalette : true,
    showAlpha : false,
    preferredFormat : 'hex',
    replacerClassName : 'form-control',
    clickoutFiresChange : true,
//    palette : [
//                ['#865BB7','#337ab7','#5bc0de','#f0ad4e','#d9534f','#5cb85c'],
//                ['#d3c3e4','#b4cee4','#c3e8f3','#f9e1be','#f1c0bf','#c3e5c3'],
//                ['#b296d1','#7daad1','#96d6ea','#f5ca8e','#e6918f','#97d197'],
////                ['#9169bd','#4586bd','#69c5e1','#f1b45e','#dc625f','#6abe6a'],
////                ['#6e4b96','#2a6496','#4b9eb6','#c58e40','#b24441','#4c974c'],
//                ['#4a3264','#1c4364','#32697a','#835f2b','#772e2c','#336533'],
//                ['#251932','#0e2232','#19353d','#423016','#3c1716','#1a331a'],
//                ['#000000','#444444','#666666','#999999','#cccccc','#ffffff']]
//};
    palette : [
                ['#865BB7','#337ab7','#5bc0de','#54b8a6','#5cb85c','#92b733','#f0ad4e','#c19428','#d9534f','#cc1f7b','#c020ca'],
                ['#d3c3e4','#b4cee4','#c3e8f3','#b5ddd6','#c3e5c3','#d1dfab','#f9e1be','#ddcb9f','#f1c0bf','#d8a6c0','#deafe1'],
                ['#b296d1','#7daad1','#96d6ea','#86bdb4','#97d197','#bfd48a','#f5ca8e','#bda569','#e6918f','#d48bb2','#cd7fd2'],
                ['#4a3264','#1c4364','#32697a','#345a53','#336533','#74893e','#835f2b','#6f561c','#772e2c','#891c55','#7b1881'],
                ['#251932','#0e2232','#19353d','#294742','#1a331a','#51651c','#423016','#43340e','#3c1716','#540b32','#3d0940'],
                ['#000000','#444444','#666666','#999999','#cccccc','#ffffff']]
};


module.service('ColorPickerOpts', function($filter) {
    colorPickerOpts.cancelText = $filter('i18n')('ui.cancel');
    colorPickerOpts.chooseText = $filter('i18n')('ui.select');
    return colorPickerOpts;
});

module.run(function($templateCache) {
    $templateCache.put('directive.html', "<div><input class=input-small></div>");
});

module.directive("cronExpression", function($locale, $filter, contextPath, Weekdays, Months) {
    return {
        scope : {
            cronExpression : "=cronExpression",
            options : "=cronExpressionOptions"
        },
        restrict : "A",
        templateUrl : "utils/cron-expression.html",
        link : function(scope, elem, attrs) {
            function zeropad(str, n) {
                if (str != null) {
                    str = "" + str;
                    while (str.length < n) {
                        str = "0" + str;
                    }
                }
                return str;
            }

            function serie(from, to, step, pad) {
                var d = [];
                for (var i = from; i <= to; i += step) {
                    d.push({
                        value : "" + i,
                        label : zeropad(i, pad)
                    });
                }
                return d;
            }

            function days() {
                var d = [];
                for (var i = 1; i <= 31; ++i) {
                    d.push({
                        value : "" + i,
                        label : "" + i
                    });
                }
                d.push({
                    value : "L",
                    label : $filter('i18n')('ui.cron.last')
                });
                return d;
            }

            scope.ui = {
                period : null,
                step : null,
                value : [],
                periods : [ {
                    value : "s",
                    pos : 0,
                    show : "s",
                    steps : serie(1, 120, 1),
                    label : $filter('i18n')('ui.cron.periods.seconds')
                }, {
                    value : "m",
                    pos : 1,
                    show : "ms",
                    steps : serie(1, 120, 1),
                    label : $filter('i18n')('ui.cron.periods.minutes')
                }, {
                    value : "h",
                    pos : 2,
                    show : "hms",
                    steps : serie(1, 48, 1),
                    label : $filter('i18n')('ui.cron.periods.hours')
                }, {
                    value : "d",
                    pos : 3,
                    show : "dhms",
                    steps : serie(1, 60, 1),
                    label : $filter('i18n')('ui.cron.periods.days')
                }, {
                    value : "w",
                    pos : 5,
                    show : "whms",
                    steps : serie(1, 10, 1),
                    label : $filter('i18n')('ui.cron.periods.weeks')
                }, {
                    value : "M",
                    pos : 4,
                    show : "Mdhms",
                    steps : serie(1, 24, 1),
                    label : $filter('i18n')('ui.cron.periods.months')
                } ],
                values : [ serie(0, 59, 1, 2), serie(0, 59, 1, 2), serie(0, 23, 1, 2), days(), Months, Weekdays ],
                isPeriodEnabled : function(period) {
                    return (!scope.options || typeof (scope.options) !== "string" || scope.options
                            .indexOf(period.value) >= 0);
                },
                getPeriods : function() {
                    if (scope.options && typeof (scope.options) === "string") {
                        return _.filter(this.periods, function(p) {
                            return scope.options.indexOf(p.value) >= 0;
                        });
                    } else {
                        return this.periods;
                    }
                },
                show : function(field) {
                    return this.period.show.indexOf(field) >= 0;
                },
                init : function() {
                    this.period = this.getPeriods()[0];
                    this.step = this.period.steps[0];
                    this.value[0] = this.values[0][0];
                    this.value[1] = this.values[1][0];
                    this.value[2] = this.values[2][0];
                    this.value[3] = this.values[3][0];
                    this.value[4] = this.values[4][0];
                    this.value[5] = this.values[5][0];
                },
                parseCronExpression : function() {
                    var expr, i, e, tval, tstep, tperiod, valid = true;

                    if (scope.cronExpression) {
                        expr = scope.cronExpression.split(" ");
                        if (expr.length === 5) {
                            expr[5] = "*";
                        }
                        if (expr.length === 6) {
                            expr[6] = "*";
                        }
                        if (expr.length === 7) {
                            tval = [];
                            tstep = null;
                            tperiod = null;
                            for (i = 0; i < expr.length; ++i) {
                                e = expr[i].split("/");
                                if (e.length > 2) {
                                    valid = false;
                                    break;
                                }
                                tval[i] = _.findWhere(this.values[i], {
                                    value : e[0]
                                });
                                if ((tperiod === null && e[0] === "*" && e.length === 1) || e.length === 2) {
                                    tperiod = _.findWhere(this.periods, {
                                        pos : i
                                    });
                                    if (tperiod == null) {
                                        valid = false;
                                        break;
                                    }
                                    tstep = _.findWhere(tperiod.steps, {
                                        value : e[1] || "1"
                                    });
                                    if (tstep == null) {
                                        valid = false;
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    if (valid) {
                        valid = false;
                        for (i = 0; i < tval.length; ++i) {
                            if (tval[i] != null) {
                                this.value[i] = tval[i];
                                valid = true;
                            }
                        }
                        if (valid) {
                            this.period = tperiod;
                            this.step = tstep;
                        }
                    }
                },
                updateCronExpression : function() {
                    var i, s, p = this.period;
                    var expr = [ "*", "*", "*", "*", "*", "?", "*" ];
                    var show = "smhdMw";

                    if (p.steps.indexOf(this.step) < 0) {
                        this.step = p.steps[0];
                    }

                    for (i = 0; i < expr.length; ++i) {
                        s = show[i];
                        if (p.show.indexOf(s) >= 0) {
                            expr[i] = this.value[i].value;
                        }
                        if (p.pos === i) {
                            if (expr[i] === "0" && this.step.value === "1") {
                                expr[i] = "*";
                            } else {
                                expr[i] += "/" + this.step.value;
                            }
                        }
                    }

                    if (expr[5] !== "?") {
                        expr[3] = "?";
                    }

                    scope.cronExpression = expr.join(" ");
                }
            };

            scope.ui.init();
            scope.ui.parseCronExpression();

            scope.$watch("cronExpression", function(nval, oval) {
                if (nval !== oval) {
                    scope.ui.parseCronExpression();
                }
            });
        }
    };
});

module.directive('inputHelper', function($timeout) {
    return {
        restrict : 'A',
        require : '^form',
        scope : true,
        controller : function($scope, $attrs, $interpolate) {

            $scope.inputName = $interpolate($attrs.inputName)($scope);
            $scope.labelPrefix = $interpolate($attrs.labelPrefix || '')($scope);
            $scope.label = $attrs.labelPrefix + '.' + s.replaceAll(s.underscored(s.strLeft($scope.inputName, '-')),
                '_', '.');
        },
        link : function(scope, el, attrs, formCtrl) {
            var checkValidity = function() {
                if (typeof (formCtrl[scope.inputName]) === 'undefined') {
                    return;
                }
                el.toggleClass('has-error', formCtrl[scope.inputName].$invalid);
                scope.showErrors = formCtrl[scope.inputName].$invalid !== false;
                return this;
            };

            scope.checkValidity = function() {
                $timeout(checkValidity, 0);
            };

            scope.showErros = false;
            // only apply the has-error class after the user leaves the text box
            $timeout(function() {
                var inputEl = el[0].querySelector("[name='" + scope.inputName + "']");
                var inputNgEl = angular.element(inputEl);
                inputNgEl.bind('blur', scope.checkValidity);
            }, 0);

            scope.$on('check-validity', scope.checkValidity);
        }
    };
});

module.directive('inputLabel', function($filter, contextPath) {
    return {
        restrict : 'A',
        templateUrl : 'utils/input-label.html',
        replace : true,
        require : '^form',
        scope : {
            labelBase : "@inputLabel",
            showError : "=",
            showHelp : "=",
            forInput : "@",
            errorLabel : "@",
            helpLabel : "@",
            label : "@label"
        },
        controller : function($scope) {
            var i18nFilter = $filter('i18n');
            $scope.initLabels = function() {
                $scope._label = $scope.label || $scope.labelBase;
                if (typeof ($scope.errorLabel) !== 'undefined') {
                    $scope.errorText = i18nFilter($scope.errorLabel);
                } else if ($scope.labelBase && !$scope.errorLabel && !$scope.inputErrors) {
                    $scope.errorText = i18nFilter($scope.labelBase + '.error');
                }
                if ($scope.labelBase && !$scope.helpLabel) {
                    $scope._helpLabel = $scope.labelBase + '.help';
                } else {
                    $scope._helpLabel = $scope.helpLabel;
                }
            };

            $scope.initLabels();
        },
        link : function(scope, el, attrs, formCtrl) {


            var i18nFilter = $filter('i18n');

            scope.$watch('errorLabel', function(newValue) {
                if (typeof (newValue) !== 'undefined') {
                    scope.errorText = i18nFilter(scope.errorLabel);
                }
            });
            if (typeof (formCtrl[attrs.forInput]) !== 'undefined') {

                scope.inputErrors = formCtrl[attrs.forInput].$error;
                scope.$watch('inputErrors', function() {
                    if (typeof (scope.errorLabel) !== 'undefined') {
                        return;
                    }
                    var base = (scope.labelBase || '') + '.error.';
                    scope.errorText = '';
                    for ( var e in scope.inputErrors) {
                        scope.errorText += '<p>' + i18nFilter(base + e) + '</p>';
                    }
                }, true);
            }
            scope.$on('check-validity', scope.initLabels);

        }
    };
});
