var module = angular.module('meternet.chart.directives.mbsat2Report', [ 'meternet.charts.services',
                                                                        'meternet.filters',
                                                                        'meternet.dashboard.directives']);

module.directive('mbsat2Report', function (paramService) {
    return {
        scope: {
            options: '=',
            seriesIndex: '=',
            device: '=',
            param: '=',
            title: '='
        },
        templateUrl: 'dashboard/mbsat2/mbsat2-report.html',
        controller: function ($scope, $interval, $filter, $q, $timeout, dataService, configService, DashboardEvents, unitFilter) {

            var config = {
                type: "energy-chart",
                reportId: null,
                series : [],
                columns: 4,
                mode: 1,
                visibleColumns: ['energyReport.increase']
            }
            _.forEach($scope.device.params, function(p){
                var serie = {
                    deviceId: $scope.device.id,
                    paramId: p.id
                }
                config.series.push(serie);
            })
            $scope.config = config;
            $scope.dates=[];
            $scope.ui = {
                title: $scope.title,
                chartData : [],
                stateModalParam:{
                    seriesIndex:null,
                    col:null,
                    table:null
                },
                setModalParam : function() {
//console.log("setModalParam", $scope.table.data)
                    if($scope.table.data[$scope.seriesIndex]){
                        var table = $scope.table;
                        var col = $scope.table.data[$scope.seriesIndex].length-1;
                        var rawData = table.data[$scope.seriesIndex];

                        var length = rawData.length;
                        $scope.ui.chartData = [];
                        $scope.ui.chartUnit = rawData[col].unit;
                        $scope.ui.chartTitle = $scope.device + " " + $scope.param + $filter('i18n')(table.subcols[col].label).toUpperCase() ;
                        for(var i=0; i<length; i++){
                            $scope.ui.chartData.push({
                                timestamp:moment(new Date(rawData[i].timestamp)).format("YYYY-MM-DD HH:mm"),
                                value:rawData[i].value,
                                error:0,
                                valueStart: rawData[i].value1,
                                valueEnd: rawData[i].value2,
                                dateStart: rawData[i].timestamp,
                                dateEnd: rawData[i].timestamp2,
                                showBuble: table.subcols[col].label==='energyReport.increase'
                            })
                        }
                    }
//console.log($scope.ui.chartData)
                },
                setModalParamFromState: function(){
                    $scope.ui.setModalParam($scope.ui.stateModalParam.seriesIndex, $scope.ui.stateModalParam.serie, $scope.ui.stateModalParam.col, $scope.table)
                },
                getStepDate : function(inc) {
                    var date = null;
                    if (inc < 0) {
                        date = _.max(_.filter($scope.dates, function(d) {
                            return d.getTime() < $scope.date.getTime();
                        }), function(d) {
                            return d.getTime();
                        });
                    } else {
                        date = _.min(_.filter($scope.dates, function(d) {
                            return d.getTime() > $scope.date.getTime();
                        }), function(d) {
                            return d.getTime();
                        });
                    }
                    if (date === Infinity || date === -Infinity) {
                        date = null;
                    }
                    return date;
                },
                setStepDate : function(date) {
                    if (date) {
                        updateData(date);
                    }
                },
                canStep : function(inc) {
                    return this.getStepDate(inc) != null;
                },
                step : function(inc) {
                    var diff = $scope.dates[0]-$scope.dates[1];
                    var date = $scope.date.getTime()-inc*diff
                    if (date) {
                        updateData(date);
                        $scope.ui.stateModalParam.reload = true;
                    }
                },
                retain : function(r){
                    $scope.config.retain = r;
                    var date = $scope.date.getTime();
                    if (date) {
                        updateData(date);
                        $scope.ui.stateModalParam.reload = true;
                    }
                }
            }

            configService.get().then(function(meternetConfig) {
                var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
                $scope.devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
            });

            configService.get().then(function(meternetConfig){
                var devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices'));
                var report = _.find(devices, function(device){
                   return device.id === config.reportId;
                });
                $scope.report = angular.extend({}, report, config);

                updateData();
            });


            function updateData(date) {
                $scope.ui.updatingData = true;
                if (date == null) {
                    date = new Date();
                }
                $scope.ui.date = date;
//console.log(config.series[0].paramId);
                dataService.getBarGraphData(config.series[0].paramId, new Date(date), $scope.config.retain, $scope.config.columns+1).then(function(resp) {
//console.log(resp.data)
                    var i, d;
                    if (resp.data) {
                        resp.data.reverse();
                    } else {
                        resp.data = [];
                    }

                    $scope.date = new Date(resp.data[0].scheduledTimestamp);
                    var checkDate = function(date) {
                        //TK this - date passed as underscore context
                        return date.getTime() === this.getTime();
                    };
                    for (i = 0; i < resp.data.length; ++i) {
                        d = new Date(resp.data[i].scheduledTimestamp);
                        if (_.find($scope.dates, checkDate, d) === undefined) {
                            $scope.dates.push(d);
                        }
                    }
                    $scope.dates.sort();
                    $scope.data = resp;

                    updateTable(resp);
                    $scope.ui.updatingData = false;
                    if($scope.ui.stateModalParam.reload){
                        $scope.ui.setModalParamFromState()
                    }
                });
            }

            function updateTable(resp) {
                var i, j;

                var table = {
                    cols: [],
                    subcols: [],
                    data: []
                };
                _.forEach(resp.data, function(d){
                    d.values = _.chain(d.values).reverse().value();
                })

                for (i = 1; i < $scope.config.columns+1; ++i) {
                    var colwidth = 70 / $scope.config.columns;
                    var stime1 = (resp.data.length > i) ? new Date(resp.data[i].scheduledTimestamp) : null;
                    var stime2 = (resp.data.length > i - 1) ? new Date(resp.data[i-1].scheduledTimestamp) : null;
                    var sublabels = ["energyReport.increase", "energyReport.power", "energyReport.cost"];
                    var colNum = 0

                    for(var k=0; k<sublabels.length; k++){
                        colNum ++;
                    }
                    table.colNum = colNum;
                    for (var j = 0; j < sublabels.length; j++) {
                        table.subcols.push({
                            label: "testowanie",
                            style: {
                                "width": (colwidth / table.colNum) + "%",
                                "text-align": "center"
                            }
                        });
                    }
                }
                table.cols.reverse();

                var checkParamId = function (v) {
                    return v.paramId === this;
                };
                for (i = 0; i < $scope.config.series.length; ++i) {
                    var series = $scope.config.series[i];
                    var params1 = _.flatten(_.pluck($scope.devices, 'params'), true);
                    var groups = _.filter(_.flatten(_.pluck($scope.devices, 'groups'), true), function(e){return !!e;});
                    var params2 = _.flatten(_.pluck(groups, 'params'), true);
                    var params = _.flatten([params1, params2]);

                    var param = _.find(params, function (param) {
                        return param.id === series.paramId;
                    });

                    if(param){
                        var device = _.find($scope.devices, function (device) {
                            return _.find(device.params, function(p){
                                return p.id == param.id;
                            });
                        });
                        var group = _.find(groups, function (group) {
                            return _.find(group.params, function(p){
                                return p.id == param.id;
                            });
                        });
                        if(group){
                            series.name = series.name ? series.name : group.label ? group.label : group.name;
                            series.device = group.label ? group.label : group.name;
                        }else{
                            series.name = series.name ? series.name : device.label ? device.label : device.name;
                            series.device = device.label ? device.label : device.name;
                        }
                        series.param = param.label ? param.label : param.name;
                        series.precision = param.precision;
                        series.scale = param.scale;
                        series.quantity = param.quantity;

//console.log(resp.data)
                        var d = [];
                        for (j = 0; j < $scope.config.columns; j++) {
                            var s1;
                            var s2;

                            if (resp.data.length > j) {
                                s1 = _.find(resp.data[j].values, checkParamId, series.paramId) || {};
                            }
                            if (resp.data.length > j + 1) {
                                s2 = _.find(resp.data[j + 1].values, checkParamId, series.paramId) || {};
                            }
//console.log(s1, s2)
                            var type = config.type ? config.type : "value";
                            d.push({
                                label: s1.value != null  ? unitFilter(s1[type] , series.precision, $filter('quantityUnitFilter')(series.quantity), series.scale) : "-",
                                value: s1.value != null  ? s1[type] :0,
                                timestamp: s1.timestamp,
                                timestamp2: s2.timestamp,
                                unit: {
                                    quantity: $filter('quantityUnitFilter')(series.quantity),
                                    precision: series.precision,
                                    scale: series.scale
                                },
                            });
                        }
//console.log(d)
                        d.reverse();
                        table.data.push(d);
                    }else{
                        series.display = false;
                    }
                    $scope.table = table;
                    $scope.ui.setModalParam();
                }
            }

            $scope.subscribe = true;

            var updateDataFactory = function(index) {
                var updateData = function(resp, concat) {
                    if(resp.constructor.name !== 'Array'){
                        var i, d;
                        if (resp.data) {
                            resp.data.reverse();
                        } else {
                            resp.data = [];
                        }

                        $scope.date = new Date(resp.data[0].scheduledTimestamp);
                        var checkDate = function(date) {
                            //TK this - date passed as underscore context
                            return date.getTime() === this.getTime();
                        };
                        for (i = 0; i < resp.data.length; i++) {
                            d = new Date(resp.data[i].scheduledTimestamp);
                            if (_.find($scope.dates, checkDate, d) === undefined) {
                                $scope.dates.push(d);
                            }
                        }
                        $scope.dates.sort();
                        $scope.data = resp;
                        updateTable(resp);
                    }else{
                        var newScheduledTimestamp = new Date(new Date($scope.data.data[0].scheduledTimestamp).getTime() + (new Date($scope.data.data[0].scheduledTimestamp).getTime() - new Date($scope.data.data[1].scheduledTimestamp)))

                        if(new Date($scope.data.data[0].scheduledTimestamp) !== newScheduledTimestamp && (Math.abs(new Date().getTime() - newScheduledTimestamp) > 30000)){
                            var newData = {
                                scheduledTimestamp: newScheduledTimestamp,
                                previousScheduledTimestamp: $scope.data.data[0].scheduledTimestamp,
                                values: []
                            };
                            $scope.data.data.unshift(newData);
                            $scope.data.data.pop();
                        }
                        $scope.data.data[0].values[index]=resp[0];
                        var count = 0
                        _.forEach($scope.data.data[0].values, function(v){
                            if(v){
                                count++;
                            }
                        })
                        if(count===3){
                            updateTable($scope.data);
                        }
                    }
                }
                return _.throttle(updateData, 500);
            }


            $scope.unsubscribeFns = {};
            $scope.$on('$destroy', function() {
                _.each($scope.unsubscribeFns, function(fn) {
                   fn();
                });
            });

            $scope.promises = [];
            var initData = function() {
                $scope.$broadcast(DashboardEvents.LOADING_DATA, true);
                _.each($scope.promises, function(p) {
                    p.cancel();
                });
                $scope.promises = [];
                _.each(config.series, function(series, index) {
                    var promise = dataService.getBarGraphData(series.paramId, new Date(), 15, $scope.config.columns)
                    promise.deviceId = series.deviceId;
                    promise.paramId = series.paramId;
                    var updateDataFn = updateDataFactory(index);
                    promise.then(function(measurements) {
                        updateDataFn(measurements, false);
                    }, angular.noop, function(measurements) {
                        updateDataFn(measurements, false);
                    });
                    $scope.promises.push(promise);
                });

                $scope.$on('$destroy', function() {
                    _.each($scope.promises, function(p) {
                        p.cancel();
                    });
                });
                $q.all($scope.promises).then(function(measurements) {
                    if (!$scope.subscribe) {
                        _.each($scope.unsubscribeFns, function(fn) {
                            fn();
                        });
                        $scope.unsubscribeFns = {};
                    } else {
                        _.forEach($scope.promises, function(p, i) {
                            if ($scope.subscribe && typeof ($scope.unsubscribeFns[p.paramId]) === 'undefined') {
                                var unsubscribeFn = dataService.subscribeForParametersMeasurements(p.paramId, function(moment) {
                                   var data = [];
                                   data.push(moment.current);
                                   updateDataFactory(i)(data, true);
                                });
                                $scope.unsubscribeFns[p.paramId] = unsubscribeFn;
                            }

                        });
                    }
                    $timeout(function() {
                        $scope.$broadcast(DashboardEvents.LOADING_DATA, false);
                    }, 0);
                });
            }
            initData();
        }
    }
});
