<div class="row">
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.camping.settings' | i18n}}</div>
        <div class="panel-body">
            <form class="form form-inline">
                <div class="form-group" style="width: 100%;     padding-bottom: 10px;" >
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.camping.title"></label>
                    <input type="text" class="form-control" data-ng-model="config.title" style="width: 100%"/>
                </div>
                <div class="form-group" style="width: 100%;     padding-bottom: 10px;" >
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.camping.header"></label>
                    <textarea class="form-control" data-ng-model="config.header"  x-ng-minlength="1" x-ng-maxlength="1000" style="width: 100%; height:75px;"/>
                </div>
                <div class="form-group" style="padding-bottom: 10px;">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.camping.price"></label>
                    <input class="form-control" data-ng-model="config.price" style="width: 100px;" x-min="0" x-required="" type="number"/>
                </div>
                <div class="form-group" style="padding-bottom: 10px;">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.camping.priceUnit"></label>
                    <input type="text" class="form-control" data-ng-model="config.priceUnit" style="width: 100px;" x-ng-minlength="1" x-ng-maxlength="5" style="width: 100px"/>
                </div>
                <br/>

                <div class="form-group" style="padding-bottom: 10px;">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.scale"></label>
                    <select class="form-control"
                            name="widgetsConfigScale-{{$index}}"
                            style="width: 119px;"
                            x-ng-model="config.scale"
                            x-ng-options="s.value as s.label for s in ui.scales">
                    </select>
                </div>
                <!-- Unit -->
                <div class="form-group" style="padding-bottom: 10px;">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.unit"></label>
                    <div class="input-group" style="width: 100px">
                        <input type="text" class="form-control" name="widgetsConfigUnit-{{$index}}"
                               placeholder="{{'dashboard.widgets.config.unit' | i18n}}"
                               data-ng-model="config.unit" x-ng-minlength="1" x-ng-maxlength="5"/>
                        <div class="input-group-btn">
                            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                            <ul class="dropdown-menu" role="menu">
                                <li><a href="#" x-ng-click="config.unit='Wh'">Wh</a></li>
                                <li><a href="#" x-ng-click="config.unit='m3'">m3</a></li>
                                <li><a href="#" x-ng-click="config.unit='g'">g</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Precision -->
                <div class="form-group" style="padding-bottom: 10px;">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.precision"></label>
                    <select class="form-control" style="width: 120px;" x-ng-model="config.precision"
                            x-ng-options="p for p in ui.precisions">
                    </select>
                </div>
            </form>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="series in config.series">
                <form class="form form-inline" name="seriesConfigForm-{{$index}}">

                    <div class="form-group" style="width: 130px; min-width: 130px;">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>
                        <ui-select ng-model="series._device" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesParam(series)" style="width: 130px; min-width: 130px;" >
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
                                {{$select.selected.label || $select.selected.name}}
                            </ui-select-match>
                            <ui-select-choices repeat="device in devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}" style="width: 300px; min-width: 300px;">
                                <div ng-bind-html="((device.label||device.name)) | highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc"><b>1:</b> {{device.desc}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc2"><b>2:</b> {{device.desc2}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc3"><b>3:</b> {{device.desc3}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.param"></label>
                        <ui-select ng-model="series._param" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesParam(series)" style="width: 130px; min-width: 130px;" >
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
                                {{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]
                            </ui-select-match>
                            <ui-select-choices repeat="param in series._device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}" style="width: 300px; min-width: 300px;">
                                <div ng-bind-html="((param.label||param.name) + ' [' + param.unit + ']'  )| highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.name"></label>
                        <div class="input-group" style="width: 139px">
                            <input type="text" class="form-control"  aria-label="..."
                                   x-ng-model="series.name"
                                   x-ng-change="ui.updateAxisIndices()"
                                   name="widgetsConfigSeriesName-{{$index}}">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="caret"></span></button>
                                <ul class="dropdown-menu dropdown-menu-right" x-ng-if="series._device" style="width: 300px; min-width: 300px;">
                                    <li><a href="#" x-ng-click="series.name = (series._device.label||series._device.name)+'.'+(series._param.label||series._param.name)+$index">{{series._device.label||series._device.name}}.{{series._param.label||series._param.name}}</a></li>
                                    <li role="separator" class="divider"></li>
                                    <li><a href="#" x-ng-click="series.name = (series._param.label||series._param.name)">{{series._param.label||series._param.name}}</a></li>
                                    <li><a href="#" x-ng-click="series.name = ('quantity.'+series._param.quantity|i18n)+' '+$index">{{'quantity.'+series._param.quantity|i18n}} {{$index}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" style="width: 97px;">
                    </div>

                    <div class="form-group">
                        <label class="form-group-label">&nbsp;</label>
                        <button class="btn btn-danger" x-ng-click="ui.removeSerie($index);"
                                data-ng-class="ui.isSingleSerie()"
                                title="{{'ui.delete' | i18n}}">
                            <i class="fa fa-trash-o fa-lg"></i>
                        </button>
                    </div>
                </form>
            </li>
            <li class="list-group-item" x-ng-if="ui.canAddSeries()">
                <div class="pull-right">
                    <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                        {{'ui.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="clearfix"></div>
            </li>
        </ul>
    </div>
</div>
