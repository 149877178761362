var module = angular.module('meternet.chart.directives.campingGauge', ['meternet.charts.services']);
var columns = ["name", "timestampStart", "valueStart", "timestamp", "value", "price", "valueDiff", "cost", "button"];

var getStartPDF = function(data, unitFilter,options){
    var docDefinition = {
        content: [
        { text: moment(new Date()).format("YYYY-MM-DD HH:mm"), alignment: 'right' },
        { text: options.header, style: 'header' },
        ' ',
        ' ',
        { text: options.title, style: 'title' },
        { text: 'Raport początkowy', style: 'subtitle' },
        'Nazwa licznika: ' + data.name,
        'Stawka: ' + data.price,
        {
                style: 'tableExample',
                table: {
                        headerRows: 1,
                        // keepWithHeaderRows: 1,
                        // dontBreakRows: true,
                        widths: ['*', '*'],
                        body: [
                                [{ text: 'Data pomiaru', style: 'tableHeader' }, { text: 'Odczyt', style: 'tableHeader' }],
                                [
                                    moment(data.timestampStart).format("YYYY-MM-DD HH:mm:ss"),
                                    {text: unitFilter(data.valueStart, options.precision, options.unit, options.scale), alignment: 'right' }
                                ]
                        ]
                }
        },
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        {
            columns: [
                {
                    stack: [
                        'podpis wystawiającego',
                        ' ',
                        ' ',
                        '...........................',
                    ],
                    fontSize: 15,
                    alignment: 'center'
                },
                {
                    stack: [
                        'podpis klienta',
                        ' ',
                        ' ',
                        '...........................',
                    ],
                    fontSize: 15,
                    alignment: 'center'
                    }
                ]
            }
        ],
        styles: {
            header: {
                fontSize: 12
            },
            title: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment:'center'
            },
            subtitle: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
                alignment:'center'
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            }
        },
        defaultStyle: {
            // alignment: 'justify'
        }
    };
    pdfMake.createPdf(docDefinition).download(data.name + '.pdf');
}

var getStopPDF = function(data, unitFilter, options){
    var value = ' ';
    var price = data.price;
    var diff = data.valueDiff_str;
    var cost = data.cost;

    var docDefinition = {
        content: [
        { text: moment(new Date()).format("YYYY-MM-DD HH:mm"), alignment: 'right' },
        { text: options.header, style: 'header' },
        ' ',
        ' ',
        { text: options.title, style: 'title' },
        { text: 'Raport końcowy', style: 'subtitle' },
        'Nazwa licznika: ' + data.name,
        'Stawka: ' + data.price,
        {
                style: 'tableExample',
                table: {
                        headerRows: 1,
                        // keepWithHeaderRows: 1,
                        // dontBreakRows: true,
                        widths: ['*', '*'],
                        body: [
                                [{ text: 'Data pomiaru', style: 'tableHeader' }, { text: 'Odczyt', style: 'tableHeader'}],
                                [
                                    moment(data.timestampStart).format("YYYY-MM-DD HH:mm:ss"),
                                    {text: unitFilter(data.valueStart, options.precision, options.unit, options.scale), alignment: 'right' }
                                ],
                                [
                                    moment(data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                                    {text: unitFilter(data.value, options.precision, options.unit, options.scale), alignment: 'right' }
                                ],
                                [
                                    { text: 'Zużycie:', alignment: 'right' },
                                    {text: unitFilter(data.value-data.valueStart, options.precision, options.unit, options.scale), alignment: 'right' }
                                ]
                        ]
                }
        },
        'Rozliczenie: ' + price + ' * ' + diff + ' = ' + cost,
        { text: 'Do zapłaty: ' + cost, alignment: 'right' },
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        {
            columns: [
                {
                    stack: [
                        'podpis wystawiającego',
                        ' ',
                        ' ',
                        '...........................',
                    ],
                    fontSize: 15,
                    alignment: 'center'
                },
                {
                    stack: [
                        'podpis klienta',
                        ' ',
                        ' ',
                        '...........................',
                    ],
                    fontSize: 15,
                    alignment: 'center'
                    }
                ]
            }
        ],
        styles: {
            header: {
                fontSize: 12
            },
            title: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment:'center'
            },
            subtitle: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
                alignment:'center'
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            }
        },
        defaultStyle: {
            // alignment: 'justify'
        }
    };
    pdfMake.createPdf(docDefinition).download(data.name + '.pdf');
}

module.directive('campingGauge', function(DashboardEvents, $timeout, UnitScales, unitFilter, localStorageService, campingService, i18nFilter) {
return {
    scope: {
         options : '=',
          data : '='
    },
    templateUrl: 'dashboard/camping-gauge.html',
    controller: function ($scope) {

        campingService.get().then(function(data) {
                _.forEach($scope.options.series, function(serie){
                    var startSeria =_.find(data, {paramId: serie.paramId});
                    if(startSeria){
                        serie.timestampStart = startSeria.timestamp;
                        serie.valueStart = startSeria.value;
                        serie.valueStart_str = unitFilter(startSeria.value, serie.precision, serie.unit, serie.scale);
                    }
                });
        }, function(reason) {
          alert('Failed: ' + reason);
        });

        $scope.$on(DashboardEvents.REDRAW, function(event, data) {
            if (data) {
                var serie = $scope.options.series[data.index];
                serie.timestamp = data.timestamp;
                serie.value = data.value;
                serie.value_str = unitFilter(data.value, $scope.options.precision, $scope.options.unit, $scope.options.scale);
                if(serie.valueStart){
                    serie.valueDiff = data.value - serie.valueStart;
                    serie.valueDiff_str = unitFilter(serie.valueDiff, $scope.options.precision, $scope.options.unit, $scope.options.scale);
                    serie.cost = unitFilter((serie.valueDiff/Math.pow(10,$scope.options.scale))*($scope.options.price), 2, $scope.options.priceUnit,'')
                }
                serie.price = unitFilter($scope.options.price, 2, "", 0) + " " + $scope.options.priceUnit + "/" + unitFilter(0, 0, $scope.options.unit, $scope.options.scale).substring(2,8);
            }
        });

        $scope.stop = function(d){
            var data = {    name: d.name,
                            paramId: d.paramId,
                            timestampStart: new Date(d.timestampStart).getTime(),
                            valueStart: d.valueStart,
                            timestampStop: d.timestamp.getTime(),
                            valueStop: d.value};
            campingService.saveStop(data).then(function(greeting) {
                getStopPDF(d, unitFilter, $scope.options);
                d.timestampStart = null;
                d.valueStart = null;
                d.valueStart_str = "";
                d.valueDiff = null;
                d.valueDiff_str = "";
                d.cost = "";
            }, function(reason) {
                alert('Failed: ' + reason);
            });
        }

        $scope.start = function(d){
            var data = {    paramId: d.paramId,
                            timestamp: d.timestamp.getTime(),
                            value: d.value};
            campingService.saveStart(data).then(function(greeting) {
                d.timestampStart = d.timestamp
                d.valueStart = d.value;
                d.valueStart_str = unitFilter(d.value, $scope.options.precision, $scope.options.unit, $scope.options.scale);
                d.valueDiff = data.value - d.valueStart;
                d.valueDiff_str = unitFilter(d.valueDiff, $scope.options.precision, $scope.options.unit, $scope.options.scale);
                d.cost = unitFilter((d.valueDiff/Math.pow(10,$scope.options.scale))*($scope.options.price), 2, $scope.options.priceUnit,'')
                getStartPDF(d, unitFilter, $scope.options);
            }, function(reason) {
                alert('Failed: ' + reason);
            });
        }
    }
}
});
